import { UnleashAxios } from '../../lib/unleashAxios';

/**
 * Check if user is master and has a manager account permission.
 * @param {{ isMaster: boolean, id: string}} currentUser
 * @param { string } env
 */
export const isAccountManager = async (currentUser, env) => {
  try {
    const result = await UnleashAxios.create(env).isEnabled(
      'manage_account',
      { userId: `${currentUser && currentUser.id}` }
    );
    return result;
  } catch (error) {
    return false;
  }
};
