import React, { useEffect } from 'react';
import PubSub from 'pubsub-js';

import { createStore } from '../../../store';
import { ViewUI } from './ViewUI';
import { useRedirect } from './hooks/useRedirect';
import { useRouterMiddleware } from './hooks/useRouterMiddleware';
import { ViewModel as useViewModel } from './ViewModel';

export default function ViewController({ router, route }) {
  const store = createStore();
  const env = store?.app?.env;
  const { navigateWhenIsNotNetwork } = useRedirect();
  const { isLoading, setIsLoading, mfcReady, setMfcReady } = useViewModel();
  const { setRouteLeaveHook } = useRouterMiddleware();

  useEffect(() => {
    navigateWhenIsNotNetwork({ router, store });
    setRouteLeaveHook({ router, route });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (mfcReady) {
      const organization = store?.currentOrganization;
      const entity = store?.entity;
      const user = store?.currentUser;
      const host = store?.app?.host;

      PubSub.publish(`${mfcReady}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        user,
        host,
      });
    }
  }, [
    mfcReady,
    store.currentEntity,
    store.currentOrganization,
    store.currentUser,
  ]);

  useEffect(() => {
    PubSub.subscribe('MICROFRONTEND_READY', (_, mcfName) => {
      const organization = store?.currentOrganization;
      const entity = store?.entity;
      const user = store?.currentUser;
      const host = store?.app?.host;

      setMfcReady(mcfName);
      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        user,
        host,
      });
    });
  }, []);

  return <ViewUI isLoading={isLoading} env={env} />;
}
