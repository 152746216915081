import React from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';

import { createUrlWithHash, asset } from '../../../utils';
import config from '../../../../../config/server.config';
import { __ } from '../../../i18n';

import Loading from '../../../components/ui/Loading';
import { Fallback } from '../../../components/kit/Fallback';

export function ViewUI({ env, isLoading }) {
  if (isLoading) {
    return <Loading data-testid="loading-component" width={30} height={30} />;
  }

  return (
    <RemoteMountedComponent
      url={createUrlWithHash(
        `${config.microfrontends.sully[env]}/remoteEntry.js`,
      )}
      name="sully"
      moduleName="./mountMethods"
      rootRoute="/communications"
      fallbackComponent={
        <Fallback
          title={__('Connection error')}
          subtitle={__('Try again later')}
          src={asset('/images/no-connection.png')}
          alt="No connection image"
        />
      }
      loadingComponent={<Loading width={30} height={30} />}
    />
  );
}
