import * as ApolloDataSource from '../../../../Data/DataSource/ApolloDataSource';
import { updateChannelUseCase } from '../UpdateChannel';
import { updateChannelUseCaseMock } from '../__mocks__/channelUseCase.mock';

describe('given updateChannelUseCase use case', () => {
  const mocks = {
    id: 1,
    fullname: 'Financial Channel',
    eid: 'External ID',
    type: 'ADMIN',
    description: 'External ID description',
    invisible: false,
    seeAll: true,
    ntfStartTime: '10:00:00',
    ntfEndTime: '12:00:00',
    addresses: [
      {
        address: '5519990909090',
        type: 'PHONE',
        tags: [
          { id: 1, name: 'Father' },
          { id: 2, name: 'Mother' },
        ],
        invite: false
      }
    ],
    groups: [
      { id: 1, name: 'Group 1', type: 'EXTERNAL' },
      { id: 2, name: 'Group 2', type: 'EXTERNAL' },
    ],
    defaultFeatures: ['MESSAGE'],
    scope: null
  };

  beforeAll(() => {
    jest.spyOn(ApolloDataSource, 'Mutation').mockImplementation(async () => updateChannelUseCaseMock);
  });

  it('should be able to update an channel by id', async () => {
    const result = await updateChannelUseCase(mocks);
    expect(result?.data?.node).not.toBeFalsy();
  });
});
