/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
  * @typedef {{
  *  search: string;
  * }} TVariables
**/

/**
* @param {TVariables} variables
*/
export async function getApplicationsRepository(variables) {
  const query = gql`
    query ApplicationsQuery($search: String) {
      applications(search: $search) {
          id
          name
          redirectUri
      }
    }
  `;

  const { data, errors } = await Query(query, variables);

  return {
    data,
    errors
  };
}
