import axios from 'axios';
import qs from 'qs';
import i18n from './i18n';

export const client_id = 'ZmYyYWM3M2JmYjkxY2IwZWJhNzlhZjcw';
export const client_secret = 'YTA5NDAzMDk5M2I3Yjg5NTcyODk5ZDc3';

/**
 * @param {{
 *  access_token: string,
 *  url: string,
 *  uploadUrl: string
 * }} options
 */
export const createAPI = ({ access_token, url, uploadUrl }) => {
  const api = {};

  api.access_token = access_token;
  api.url = url;
  api.uploadUrl = uploadUrl || url;

  api.client_id = client_id;
  api.client_secret = client_secret;

  const setParams = (params = {}) => ({
    tz_offset: -(new Date().getTimezoneOffset()),
    locale: i18n.currentLocale(),
    ...params,
    access_token: api.access_token
  });

  const get = (endpoint, params = {}) => {
    const finalParams = (params === false) ? null : { params: setParams(params) };
    console.log('GET ' + api.url + '/graphql' + endpoint, finalParams);
    return axios.get(`${api.url}/graphql${endpoint}`, finalParams)
      .then(response => response.data).catch((error) => {
        console.log('get error', error);

        if (error.response) {
          throw error.response.data;
        }
      });
  };
  api.get = get;

  const post = (endpoint, params = {}, header = {}) => {
    const finalParams = (params === false) ? null : { params: setParams(params) };
    let data;

    if (header['Content-Type'] === 'multipart/form-data') {
      data = new FormData();
      Object.keys(finalParams.params).forEach((param) => {
        data.append(param, finalParams.params[param]);
      });
    } else {
      data = finalParams.params;
    }
    console.log('POST ' + endpoint, data);

    const apiUrl = header['Content-Type'] === 'multipart/form-data'
      ? `${api.uploadUrl}/${endpoint}`
      : `${api.url}/${endpoint}`;

    return axios({
      method: 'post',
      url: apiUrl,
      header: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        ...header
      },
      data
    }).then(response => response.data)
      .catch((error) => {
        console.log('post error', error);

        if (error.response) {
          throw error.response.data;
        }
      });
  };

  api.post = post;

  const patch = (type, query, data, pieces = 20) => {
    const blocks = data.reduce((prev, item, i) => {
      const where = Math.floor(i / pieces);
      const isNew = i === 0 || i % pieces === 0;
      if (isNew) {
        prev.push([query(item, i)]);
      } else {
        prev[where].push(query(item, i));
      }
      return prev;
    }, []);

    const requests = blocks.map((block) => {
      const finalParams = setParams();
      finalParams.query = `${type} {
                            ${block.join('\n')}
                            }`;

      return axios({
        method: 'post',
        url: `${api.url}/graphql`,
        header: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: finalParams
      }).then(response => response.data).catch((error) => {
        console.log('post error', error, finalParams);

        if (error.response) {
          throw error.response.data;
        }
      });
    });

    return Promise.all(requests).catch(err => console.log(err));
  };

  api.patch = patch;

  const deleteRequest = (endpoint, params = {}) => { //for some reason can't simply call it delete
    const finalParams = (params === false) ? null : { params: setParams(params) };
    console.log('DELETE ' + endpoint, finalParams);
    return axios({
      method: 'delete',
      url: `${api.url}/graphql${endpoint}`,
      header: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      data: finalParams.params
    }).catch((error) => {
      console.log('delete error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.delete = deleteRequest;

  const put = (endpoint, params = {}) => {
    const finalParams = (params === false) ? null : { params: setParams(params) };
    console.log('PUT ' + endpoint, finalParams);
    return axios({
      method: 'put',
      url: `${api.url}/graphql${endpoint}`,
      header: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      data: finalParams.params
    }).then(response => response.data).catch((error) => {
      console.log('put error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.put = put;

  const upload = (target, params = {}, onUploadProgress) => {
    const finalParams = (params === false) ? null : { params: setParams(params) };
    const data = new FormData();
    Object.keys(finalParams.params).forEach((param) => {
      if (Array.isArray(finalParams.params[param])) {
        finalParams.params[param].forEach((item, i) => {
          data.append(`${params}[${i}]`, item);
        });
      } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(aac)$/i)) {
        data.append(param, new Blob([finalParams.params[param]], { type: 'audio/aac' }),
          finalParams.params[param].name);
      } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(rar)$/i)) {
        data.append(param, new Blob([finalParams.params[param]], { type: 'application/x-rar-compressed' }),
          finalParams.params[param].name);
      } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(mpga)$/i)) {
        data.append(param, new Blob([finalParams.params[param]], { type: 'audio/mpeg' }),
          finalParams.params[param].name);
      } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(pptx)$/i)) {
        data.append(param, new Blob([finalParams.params[param]], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' }),
          finalParams.params[param].name);
      } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(doc)$/i)) {
        data.append(param, new Blob([finalParams.params[param]], { type: 'application/msword' }),
          finalParams.params[param].name);
      } else {
        data.append(param, finalParams.params[param]);
      }
    });

    console.log('UPLOAD', data, finalParams);
    return axios({
      method: 'post',
      url: `${api.uploadUrl}/graphql?target=${target}`,
      header: {
        'Content-Type': 'multipart/form-data'
      },
      data,
      onUploadProgress
    }).then(response => response.data).catch((error) => {
      console.log('post error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.upload = upload;

  return api;
};
