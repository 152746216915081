import axios from 'axios';
import { upload } from '../upload';

jest.mock('axios');

describe('upload function', () => {
  it('should upload a file successfully', async () => {
    axios.post.mockImplementation(() => Promise.resolve({ data: 'success', error: null }));

    const target = 'some-target';
    const params = {
      newParam: 'new-param',
      arrayParams: [
        'param1', 'param2', 'param3'
      ],
      file: {
        name: 'some-file.aac'
      }
    };
    const access_token = 'your-access-token';

    const response = await upload(target, params, access_token);

    expect(response.data).toBe('success');
    expect(response.error).toBeNull();
  });
});
