import { DefaultAutomaticMessages } from '../Presentation/pages/Channel/components/AutomaticMessages/constants/default-automatic-messages';

export function getDefaultAutomaticMessages(newMessages) {
  const defaultMessages = DefaultAutomaticMessages();

  if (newMessages) {
    return defaultMessages.map((message) => {
      const newMessage = newMessages.find(
        (newMessage) => newMessage.type === message.type,
      );

      return newMessage || message;
    });
  }

  return defaultMessages;
}
