import { isNetwork } from '../isNetwork';

describe('isNetwork', () => {
  let organization;

  beforeEach(() => {
    jest.clearAllMocks();

    organization = {
      id: 1,
      network: {
        id: 1,
      },
      unleashStatus: {
        network_communication: true,
      },
    };
  });

  it('should return false when organization is not provided', () => {
    expect(isNetwork()).toBe(false);
  });

  it('should return true when organization is main organization in network', () => {
    expect(isNetwork(organization)).toBe(true);
  });

  it('should return false when organization is not a main organization in network', () => {
    organization.network.id = 2;
    expect(isNetwork(organization)).toBe(false);
  });

  it('should return false when organization has not netowrk unleash', () => {
    organization.unleashStatus.network_communication = false;
    expect(isNetwork(organization)).toBe(false);
  });
});
