import { getChannelListUseCase } from '../getChannelListUseCase';
import * as Repository from '../../../../Data/Repository/Channel/getChannelListRepository';

describe('given the "getChannelListUseCase" function', () => {
  beforeEach(() => {
    jest.spyOn(Repository, 'getChannelListRepository').mockImplementation(() => ({
      data: { nodes: [{ id: 1 }] },
      error: 'custom error',
    }));
  });

  it('should call the "getChannelListRepository" function with the same params', async () => {
    const variables = { id: 1 };

    await getChannelListUseCase(variables);

    expect(Repository.getChannelListRepository).toHaveBeenCalledWith(variables);
  });

  it('should return the "getChannelListRepository" response', async () => {
    const variables = { id: 1 };

    const response = await getChannelListUseCase(variables);

    expect(response).toEqual({ data: { nodes: [{ id: 1 }] }, error: 'custom error' });
  });
});
