import { ViewModel } from '../ViewModel';
import { renderHook } from '@testing-library/react-hooks/dom';
import * as store from '../../../../../../store';
import PubSub from 'pubsub-js';

describe('given Notifications ViewModel', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    jest
      .spyOn(PubSub, 'subscribe')
      .mockImplementation((_, callback) => callback('TEST', 1));
    jest.spyOn(store, 'createStore').mockReturnValue({
      currentUser: {
        entities: [
          {
            isChannel: true,
          },
        ],
        notifications: {
          totalCount: 0,
        },
      },
      app: {
        locale: 'en',
      },
      currentOrganization: {
        featureToggles: {
          web_menu_conversations_notification_bell: true,
        },
      },
    });
  });

  it('should be able to get the shouldSeeConversations as false if organization does not have the feature', () => {
    jest.spyOn(store, 'createStore').mockReturnValue({
      currentUser: undefined,
      currentOrganization: {
        featureToggles: {
          web_menu_conversations_notification_bell: false,
        },
      },
    });

    const { result } = renderHook(() => ViewModel());

    expect(result.current.shouldSeeConversations).toEqual(false);
  });

  it('should be able to get the shouldSeeConversations as true if organization has the feature', () => {
    const { result } = renderHook(() => ViewModel());

    expect(result.current.shouldSeeConversations).toEqual(true);
  });

  it('should be able register notifications pubsubs', () => {
    const { result } = renderHook(() => ViewModel());

    result.current.registerNotificationsPubSub();

    expect(PubSub.subscribe).toHaveBeenCalledTimes(3);
  });
});
