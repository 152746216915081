import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { createChannelRepository } from '../CreateChannel';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the createChannelRepository function', () => {
  const variables = {
    organizationId: '1780',
    fullname: 'Canal de Teste',
    eid: '1123',
    type: 'STAFF',
    description: 'Descrição do Canal',
    invisible: false,
    seeAll: false,
    ntfStartTime: '07:30:00',
    ntfEndTime: '08:00:00',
    addresses: [
      {
        address: '5541988538810',
        type: 'PHONE',
        tags: [],
        invite: true
      },
      {
        address: 'matheus.kindrazki@arcotech.io',
        type: 'EMAIL',
        tags: [],
        invite: true
      }
    ],
    groups: [
      {
        id: 29852,
        name: '3º Ano A',
        type: null
      },
      {
        id: 29853,
        name: '6º Ano A',
        type: null
      },
      {
        id: 29851,
        name: '5º Ano A',
        type: null
      }
    ],
    defaultFeatures: [
      'MESSAGE'
    ],
    updateIfExists: false,
    scope: []
  };
  it('should call Mutation with the correct params', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await createChannelRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      createEntityMutation: {
        ...variables
      }
    });
  });

  it('should call use default values in Mutation function', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await createChannelRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      createEntityMutation: {
        ...variables
      }
    });
  });

  it('should return data and errors', async () => {
    const response = { data: 'data', errors: 'errors' };

    jest.spyOn(apolloDataSource, 'Mutation').mockResolvedValue(response);

    const result = await createChannelRepository({
      createEntityMutation: {
        ...variables
      }
    });

    expect(result).toEqual(response);
  });
});
