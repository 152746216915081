import React from 'react';
import { fireEvent, render } from '../../../../../../newRender';
import EntityNotificationViewUI from '../ViewUI';
import { otherNotificationsMock } from '../components/OtherNotifications/__mocks__/other-notifications.mock';

describe('given the EntityNotificationViewUI component', () => {
  const notificationsMock = otherNotificationsMock;
  const props = {
    notifications: [],
    renderRow: jest.fn(),
    showConversations: true,
    pagination: {
      graphql: false,
      hasNextPage: false,
      loading: false,
    },
    onLoadMore: jest.fn(),
  };

  it('should be able to find conversations tab', () => {
    const { getByText } = render(<EntityNotificationViewUI {...props} />);

    expect(getByText('Conversations')).toBeTruthy();
  });

  it('should be able to find others tab', () => {
    const { getByText } = render(<EntityNotificationViewUI {...props} />);

    expect(getByText('Others')).toBeTruthy();
  });

  it('should be able to find NotificationEmptyState', () => {
    const { getByText } = render(<EntityNotificationViewUI {...props} />);
    fireEvent.click(getByText('Others'));

    expect(getByText('No notifications yet')).toBeTruthy();
  });

  it('should be able to change the tab', () => {
    const { getByText } = render(<EntityNotificationViewUI {...props} />);

    fireEvent.click(getByText('Others'));

    expect(getByText('No notifications yet')).toBeTruthy();
  });

  it('should be able to see the list if notifications are not empty', async () => {
    const { getByText, findByTestId } = render(
      <EntityNotificationViewUI {...props} notifications={notificationsMock} />,
    );
    fireEvent.click(getByText('Others'));
    const list = await findByTestId('list');

    expect(list).toBeTruthy();
  });

  it('should not be able to see the tabs if showConversations is false', () => {
    const { queryByText } = render(
      <EntityNotificationViewUI {...props} showConversations={false} />,
    );

    expect(queryByText('Conversations')).toBeNull();
    expect(queryByText('Others')).toBeNull();
  });
});
