import React from 'react';
import { render } from '../../../../../../newRender';
import { ViewUI } from '../ViewUI';
import { __ } from '../../../../../../i18n';
import * as CanSeeAutomaticMessagesUseCase from '../../../../../Domain/UseCases/AutomaticMessage/CanSeeAutomaticMessagesUseCase';

describe('ViewUI', () => {
  const props = {
    currentEntityId: 1,
    hasMessageApprove: true,
  };

  beforeEach(() => {
    jest
      .spyOn(CanSeeAutomaticMessagesUseCase, 'canSeeAutomaticMessagesUseCase')
      .mockReturnValue(true);
  });

  it('should be defined', () => {
    const { container } = render(<ViewUI {...props} />);
    expect(container).toBeInTheDocument();
  });

  it('should be render the load component', () => {
    const { getByTestId } = render(<ViewUI isLoading />);
    const loading = getByTestId('loading-component');
    expect(loading).toBeInTheDocument();
  });

  it('should be able to find picture input component', async () => {
    const { findByText } = render(<ViewUI {...props} />);
    const picture = await findByText(/Appearance/);
    expect(picture).toBeDefined();
  });

  it('should be able to find responsible input component', async () => {
    const { findByText } = render(<ViewUI {...props} />);
    const responsible = await findByText(`${__('Responsible')} *`);
    expect(responsible).toBeDefined();
  });

  it('should be able to find visibility input component', async () => {
    const { findByText } = render(<ViewUI {...props} />);
    const visibility = await findByText(`${__('Visibility')} *`);
    expect(visibility).toBeDefined();
  });

  it('should be able to find manage permissions input component', () => {
    const { findByText } = render(<ViewUI {...props} />);
    const managePermissions = findByText(__('Manage Permissions'));
    expect(managePermissions).toBeDefined();
  });

  it('should be able to get automatic messages using ref', () => {
    const ref = React.createRef();
    render(<ViewUI {...props} ref={ref} hasMenuConversations />);

    const automaticMessages = ref.current?.getAutomaticMessages();

    expect(automaticMessages.OUT_WORKING_HOURS).toBeTruthy();
  });
});
