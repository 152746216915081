import { UnleashAxios } from "../../../lib/unleashAxios";

export const shouldUseNewAccountForm = async (env) => {
  try {
    const result = await UnleashAxios.create(env).isEnabled(
      'new_create_bank_account_form'
    );
    return result;
  } catch (error) {
    return false;
  }
}
