import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
  width: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3.81rem;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
