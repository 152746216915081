import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Header } from '../../../components/Header';
import { Form } from '../components/Form';
import { Footer } from '../components/Form/components/Footer';

import { __ } from '../../../../../i18n';

import { Container } from './ViewStyles';

function ViewUIComponent(
  {
    currentEntityId,
    isLoading,
    isDisabled,
    onBack,
    hasMessageApprove,
    entity,
    onSubmit,
    canAddPicture,
    defaultValues,
    hasMenuConversations,
  },
  ref,
) {
  const formRef = useRef(null);

  function getAutomaticMessages() {
    return formRef.current?.getAutomaticMessages();
  }

  useImperativeHandle(ref, () => ({
    getAutomaticMessages: getAutomaticMessages,
  }));

  return (
    <Container id="ChannelAdd">
      <Header title={__('Add Channel')} onBack={onBack} />
      <Form
        ref={formRef}
        currentEntityId={currentEntityId}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onBack={onBack}
        hasMessageApprove={hasMessageApprove}
        entity={entity}
        onSubmit={onSubmit}
        canAddPicture={canAddPicture}
        defaultValues={defaultValues}
        hasMenuConversations={hasMenuConversations}
        footer={
          <Footer
            onCancel={onBack}
            cancelText={__('Cancel')}
            submitText={__('Add')}
          />
        }
      />
    </Container>
  );
}

export const ViewUI = forwardRef(ViewUIComponent);
