import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { updateChannelRepository } from '../UpdateChannel';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the updateChannelRepository function', () => {
  const variables = {
    id: '1235111940',
    fullname: 'Canal de Teste',
    addresses: [
      {
        address: 'matheus.kindrazki@arcotech.io',
        type: 'EMAIL',
        tags: [],
        invite: false
      },
      {
        address: '5541988538810',
        type: 'PHONE',
        tags: [],
        invite: false
      }
    ],
    defaultFeatures: [
      'MESSAGE'
    ],
    description: 'Descrição do Canal',
    eid: '1123',
    groups: [
      {
        id: 29852,
        name: '3º Ano A',
        type: null
      },
      {
        id: 29851,
        name: '5º Ano A',
        type: null
      },
      {
        id: 29853,
        name: '6º Ano A',
        type: null
      }
    ],
    invisible: false,
    ntfStartTime: '07:30:00',
    ntfEndTime: '08:00:00',
    scope: [],
    seeAll: false,
    type: 'STAFF',
    updateIfExists: false
  };
  it('should call Mutation with the correct params', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await updateChannelRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      updateEntityMutation: {
        ...variables
      }
    });
  });

  it('should call use default values in Mutation function', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await updateChannelRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      updateEntityMutation: {
        ...variables
      }
    });
  });

  it('should return data and errors', async () => {
    const response = { data: 'data', errors: 'errors' };

    jest.spyOn(apolloDataSource, 'Mutation').mockResolvedValue(response);

    const result = await updateChannelRepository({
      updateEntityMutation: {
        ...variables
      }
    });

    expect(result).toEqual(response);
  });
});
