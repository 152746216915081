/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
  * @typedef {{
*  id?: number;
*  name?: string;
* }} TTag
**/

/**
* @typedef {{
*  id?: number;
*  name?: string;
*  type?: 'EXTERNAL'
* }} TGroup
**/

/**
* @typedef {{
*  address: string;
*  type: 'EMAIL' | 'PHONE';
*  tags?: TTag[];
*  invite?: boolean;
* }} TAddress
**/

/**
* @typedef {{
*  id: number;
*  fullname: string;
*  eid?: string;
*  type: 'ADMIN' | 'STAFF' | 'STUDENT';
*  description?: string;
*  invisible?: boolean;
*  seeAll?: boolean;
*  ntfStartTime?: string;
*  ntfEndTime?: string;
*  addresses?: TAddress[];
*  groups?: TGroup[];
*  defaultFeatures?: ['MESSAGE'];
*  scope?: string[];
* }} TVariables
**/

/**
* @param {TVariables} variables
*/
export async function updateChannelRepository(variables) {
  const mutation = gql`
    mutation updateChannel($updateEntityMutation: UpdateEntityInput!) {
      updateEntity(input: $updateEntityMutation) {
        clientMutationId
        __typename
      }
    }
  `;

  const input = {
    updateEntityMutation: {
      ...variables
    }
  };

  const { data, errors } = await Mutation(mutation, input);

  return {
    data,
    errors
  };
}
