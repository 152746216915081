import { createStore } from '../../../../store';

/**
 * @returns {boolean}
 */
export function canSeeAutomaticMessagesUseCase() {
  const store = createStore();

  const unleash = store.currentOrganization?.unleashStatus;

  return unleash?.web_conversations_automatic_messages ?? false;
}
