import { getChannelUseCase } from '../../../../Domain/UseCases/Channel/GetChannel';
import { updateChannelUseCase } from '../../../../Domain/UseCases/Channel/UpdateChannel';
import { uploadChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/UploadChannelPicture';
import { deleteChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/DeleteChannelPicture';
import { getSelectedUsersUseCase } from '../../../../Domain/UseCases/Channel/GetSelectedUsers';
import { hasMessageApproveUseCase } from '../../../../Domain/UseCases/Channel/HasMessageApprove';
import { validateTimeUseCase } from '../../../../Domain/UseCases/Channel/ValidateTime';
import { getVisibilityTypeUseCase } from '../../../../Domain/UseCases/Channel/GetVisibilityType';
import { isDisabledUseCase } from '../../../../Domain/UseCases/Channel/IsDisabled';
import { convertAddressesToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertAddressesToInput';
import { convertGroupsToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertGroupsToInput';
import { removeIdsFromChannelUseCase } from '../../../../Domain/UseCases/Channel/RemoveIdsFromChannel';
import { VisibilityStatusEnum } from './types/VisibilityStatusEnum.types';
import { parseScopeToForm } from '../../../../../utils';
import { automaticMessagesAreEqual } from '../../../../helpers/automaticMessagesAreEqual';
import { createOrUpdateChannelAutomaticMessagesUseCase } from '../../../../Domain/UseCases/AutomaticMessage/createOrUpdateChannelAutomaticMessagesUseCase';

export function ViewModel() {
  /**
   * @param {import("../../../Data/Repository/Channel/GetChannel").TVariables} variables
   */
  async function getChannel(variables) {
    return await getChannelUseCase({ ...variables });
  }

  /**
   * @param {import("../../../Data/Repository/Channel/UpdateChannel").TVariables} variables
   */
  async function updateChannel(variables) {
    return await updateChannelUseCase({ ...variables });
  }

  /**
   * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
   */
  async function uploadChannelPicture(file, variables) {
    return await uploadChannelPictureUseCase(file, { ...variables });
  }

  /**
   * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
   */
  async function deleteChannelPicture(variables) {
    return await deleteChannelPictureUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/GetSelectedUsers").TUser} variables
   */
  function getSelectedUsers(variables) {
    return getSelectedUsersUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/HasMessageApprove").TVariables} variables
   */
  function hasMessageApprove(variables) {
    return hasMessageApproveUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/ValidateTime").TVariables} variables
   */
  function validateTime(variables) {
    return validateTimeUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/GetVisibilityType").TVariables} variables
   */
  function getVisibilityType(variables) {
    return getVisibilityTypeUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
   */
  function isDisabled(variables) {
    return isDisabledUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
   */
  function convertAddressesToInput(variables) {
    return convertAddressesToInputUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
   */
  function convertGroupsToInput(variables) {
    return convertGroupsToInputUseCase(variables);
  }

  /**
   * @param {number} entityId
   * @param {number[]} removedIds
   */
  async function removeIdsFromChannel(entityId, removedIds) {
    return await removeIdsFromChannelUseCase(entityId, removedIds);
  }

  /**
   * @param {import('./types/ChannelDefaultValues.types').ChannelDefaultValues} data
   */
  function getDefaultValues(data) {
    return {
      fullname: data?.fullname ?? '',
      externalId: data?.eid ?? '',
      description: data?.description ?? '',
      picture: data?.picture ?? null,
      type: data?.confAttendanceEnabled ? 'WITH_STATUS' : 'WITHOUT_STATUS',
      status: data?.disabled ? 'DISABLED' : 'ENABLED',
      hours: {
        open: validateTime({ time: data?.ntfStartTime ?? '' }),
        close: validateTime({ time: data?.ntfEndTime ?? '' }),
      },
      selectedUsers: getSelectedUsers(data?.users?.nodes ?? []),
      visibility: {
        status: data.seeAll
          ? VisibilityStatusEnum.PUBLIC
          : VisibilityStatusEnum.CUSTOM,
        selectedGroups: data?.roles?.nodes ?? [],
      },
      permissions: {
        scope: parseScopeToForm(data?.scope),
        policy: data?.policy ?? [],
      },
    };
  }

  /**
   * @param {Object} params
   * @param {Object.<string, { enabled: boolean, message: string }>} params.automaticMessages
   * @param {any} [params.entity]
   */
  async function createOrUpdateAutomaticMessages({
    automaticMessages,
    entity,
  }) {
    if (!entity || !automaticMessages) return;

    let entityAutomaticMessages = {};

    if (entity?.automaticMessages) {
      for (const message of entity.automaticMessages.nodes) {
        entityAutomaticMessages[message.type] = {
          enabled: message.enabled,
          message: message.message,
        };
      }
    }

    const isAutomaticMessagesTheSame = automaticMessagesAreEqual(
      entityAutomaticMessages,
      automaticMessages,
    );

    if (!isAutomaticMessagesTheSame) {
      await createOrUpdateChannelAutomaticMessagesUseCase({
        automaticMessages,
        entityId: entity.id,
      });
    }
  }

  return {
    getChannel,
    updateChannel,
    getSelectedUsers,
    hasMessageApprove,
    validateTime,
    getVisibilityType,
    isDisabled,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    deleteChannelPicture,
    removeIdsFromChannel,
    getDefaultValues,
    createOrUpdateAutomaticMessages,
  };
}
