import { useState } from 'react';

export function ViewModel() {
  const [isLoading, setIsLoading] = useState(true);
  const [mfcReady, setMfcReady] = useState(null);

  return {
    isLoading,
    setIsLoading,
    mfcReady,
    setMfcReady,
  };
}
