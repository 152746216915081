import axios from 'axios';

import i18n from '../../i18n';
import Config from '../../../../config/server.config';
import { createStore } from '../../store';

const setParams = (params, access_token) => ({
  tz_offset: -(new Date().getTimezoneOffset()),
  locale: i18n.currentLocale(),
  ...params,
  access_token
});

export const upload = (target, params, access_token) => {
  const store = createStore();
  const finalParams = (params === false) ? null : { params: setParams(params, access_token) };

  const NODE_DEV = store.app.env;

  const data = new FormData();
  Object.keys(finalParams.params).forEach((param) => {
    if (Array.isArray(finalParams.params[param])) {
      finalParams.params[param].forEach((item, i) => {
        data.append(`${params}[${i}]`, item);
      });
    } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(aac)$/i)) {
      data.append(
        param,
        new Blob([finalParams.params[param]], { type: 'audio/aac' }),
        finalParams.params[param].name
      );
    } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(rar)$/i)) {
      data.append(
        param,
        new Blob([finalParams.params[param]], { type: 'application/x-rar-compressed' }),
        finalParams.params[param].name
      );
    } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(mpga)$/i)) {
      data.append(
        param,
        new Blob([finalParams.params[param]], { type: 'audio/mpeg' }),
        finalParams.params[param].name
      );
    } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(pptx)$/i)) {
      data.append(
        param,
        new Blob([finalParams.params[param]], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' }),
        finalParams.params[param].name
      );
    } else if (param === 'file' && finalParams.params[param].name && finalParams.params[param].name.toLowerCase().match(/\.(doc)$/i)) {
      data.append(
        param,
        new Blob([finalParams.params[param]], { type: 'application/msword' }),
        finalParams.params[param].name
      );
    } else {
      data.append(param, finalParams.params[param]);
    }
  });

  return axios.post(`${Config.uploadApi[NODE_DEV]}/graphql?target=${target}`, data, {
    header: {
      'Content-Type': 'multipart/form-data'
    },
  });
};
