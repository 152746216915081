import { getEntityDirectMessageReadStatusUseCase } from '../GetEntityDirectMessageReadStatus';
import * as Repository from '../../../../Data/Repository/Channel/GetEntityDirectMessageReadStatus';

describe('given the "getEntityDirectMessageReadStatusUseCase" function', () => {
  beforeEach(() => {
    jest.spyOn(Repository, 'getEntityDirectMessageReadStatusRepository').mockImplementation(() => ({
      data: {
        node: {
          hasUnreadDirectMessages: false,
        }
      },
      error: 'custom error',
    }));
  });

  it('should call the "getEntityDirectMessageReadStatusRepository" function with the same params', async () => {
    const variables = { entityId: 1 };

    await getEntityDirectMessageReadStatusUseCase(variables);

    expect(Repository.getEntityDirectMessageReadStatusRepository).toHaveBeenCalledWith(variables);
  });

  it('should return the "getEntityDirectMessageReadStatusRepository" response', async () => {
    const variables = { entityId: 1 };

    const response = await getEntityDirectMessageReadStatusUseCase(variables);

    expect(response).toEqual({ data: { node: { hasUnreadDirectMessages: false } }, error: 'custom error' });
  });
});
