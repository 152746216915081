import { act, renderHook } from '@testing-library/react-hooks/dom';
import { useConfigureChannels } from '../useConfigureChannels';

describe('given the "useConfigureChannels" hook', () => {
  it('should return the initial state', () => {
    const { result } = renderHook(() => useConfigureChannels());
    expect(result.current.savedChannels).toEqual([]);
  });

  it('should append channels', () => {
    const { result } = renderHook(() => useConfigureChannels());
    expect(result.current.savedChannels).toEqual([]);
    act(() => {
      result.current.appendChannels([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
    });
    expect(result.current.savedChannels).toEqual([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
  });

  it('should not set channel if channel does not exist', () => {
    const { result } = renderHook(() => useConfigureChannels());
    expect(result.current.savedChannels).toEqual([]);
    act(() => {
      result.current.appendChannels([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
    });
    expect(result.current.savedChannels).toEqual([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
    act(() => {
      result.current.setChannel({
        id: 3,
        confAttendanceEnabled: true,
      });
    });
    expect(result.current.savedChannels).toEqual([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
  });

  it('should not append channel if channel already exists', () => {
    const { result } = renderHook(() => useConfigureChannels());
    expect(result.current.savedChannels).toEqual([]);
    act(() => {
      result.current.appendChannels([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
    });
    expect(result.current.savedChannels).toEqual([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
    act(() => {
      result.current.appendChannels([{ id: 1, confAttendanceEnabled: true }]);
    });
    expect(result.current.savedChannels).toEqual([{ id: 1, confAttendanceEnabled: false }, { id: 2, confAttendanceEnabled: false }]);
  });
});
