import { validateTimeUseCase } from '../ValidateTime';

describe('given validateTime use case', () => {
  const variables = {
    time: '10:00:00',
  };

  it('should be able to return empty array when param is not provided', () => {
    const result = validateTimeUseCase(null);

    expect(result).toEqual(null);
  });

  it('should be able to return if hour param is provided', () => {
    const result = validateTimeUseCase(variables);

    expect(result).toEqual('10:00');
  });

  it('should be return null if hour param is wrong', () => {
    variables.time = 'testing worng time';
    const result = validateTimeUseCase(variables);

    expect(result).toEqual('Invalid date');
  });
});
