const path = require('path');

// Main server/app configuration
module.exports = {
  locales: ['en', 'pt', 'ja'],
  authClientId: 'ZmYyYWM3M2JmYjkxY2IwZWJhNzlhZjcw',
  authClientSecret: 'YTA5NDAzMDk5M2I3Yjg5NTcyODk5ZDc3',
  api: {
    local: 'http://localhost:2000',
    development: 'http://localhost:2000',
    stage: 'https://web-stage.classapp.ninja',
    internal: 'https://web-internal.classapp.ninja',
    production: 'https://web.classapp.com.br',
    beta: 'https://web.classapp.com.br',
    ci: 'http://joy.classapp.guru'
  },
  uploadApi: {
    local: 'http://localhost:2000',
    development: 'http://localhost:2000',
    stage: 'https://web-stage-upload.classapp.ninja',
    internal: 'https://web-internal-upload.classapp.ninja',
    production: 'https://web-upload.classapp.com.br',
    beta: 'https://web-upload.classapp.com.br',
    ci: 'http://joy.classapp.guru'
  },
  microfrontends: {
    katara: {
      local: 'http://localhost:3100',
      stage: 'https://katara-stage.classapp.ninja',
      internal: 'https://katara-internal.classapp.ninja',
      production: 'https://katara-production.classapp.com.br',
      beta: 'https://katara-production.classapp.com.br',
      ci: 'https://katara-stage.classapp.ninja'
    },
    sully: {
      local: 'http://localhost:3105',
      stage: 'https://sully-stage.classapp.ninja',
      internal: 'https://sully-internal.classapp.ninja',
      production: 'https://sully-production.classapp.com.br',
      beta: 'https://sully-production.classapp.com.br',
      ci: 'https://sully-stage.classapp.ninja'
    },
    mervyn: {
      local: 'http://localhost:3000',
      stage: 'https://mervyn-stage.classapp.ninja',
      internal: 'https://mervyn-internal.classapp.ninja',
      production: 'https://mervyn-production.classapp.com.br',
      beta: 'https://mervyn-production.classapp.com.br',
      ci: 'https://mervyn-stage.classapp.ninja'
    },
    sokka: {
      local: 'http://localhost:3103',
      stage: 'https://sokka-stage.classapp.ninja',
      internal: 'https://sokka-internal.classapp.ninja',
      production: 'https://sokka-production.classapp.com.br',
      beta: 'https://sokka-production.classapp.com.br',
      ci: 'https://sokka-stage.classapp.ninja'
    },
  },
  appURL: 'classapp://classapp.co',
  ios: {
    app_id: '918086112',
    store: 'itms-apps://itunes.apple.com/app/apple-store/id918086112?mt=8',
    link: {
      br: 'https://itunes.apple.com/br/app/id918086112',
      us: 'https://itunes.apple.com/us/app/id918086112'
    },
    badge: {
      pt: 'https://linkmaker.itunes.apple.com/pt-br/badge-lrg.svg',
      en: 'https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg'
    }
  },
  android: {
    store: 'market://details?id=br.com.classapp.ClassApp',
    link: {
      br: 'https://play.google.com/store/apps/details?id=br.com.classapp.ClassApp',
      us: 'https://play.google.com/store/apps/details?id=br.com.classapp.ClassApp'
    },
    badge: {
      pt: 'https://play.google.com/intl/pt_br/badges/static/images/badges/pt_badge_web_generic.png',
      en: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
    }
  },
  assets: {
    cdn: {
      production: 'https://d91xur4cwmxrf.cloudfront.net',
      beta: 'https://d91xur4cwmxrf.cloudfront.net',
      stage: 'https://d8oqm0iw5voax.cloudfront.net',
      internal: 'https://d1cnt25glgyn9n.cloudfront.net',
      local: 'https://driwojsy9347i.cloudfront.net',
      media: 'https://driwojsy9347i.cloudfront.net'
    },
    banner: {
      production: 'https://d79f2fyhk2s8.cloudfront.net',
      beta: 'https://d79f2fyhk2s8.cloudfront.net',
      stage: 'https://d1r6v0q3um3bfe.cloudfront.net',
      internal: 'http://d3khmqd0fjrq7.cloudfront.net',
      local: 'http://d3khmqd0fjrq7.cloudfront.net',
    },
    bucket: {
      production: 'https://d2d05siytygrxb.cloudfront.net',
      beta: 'https://d2d05siytygrxb.cloudfront.net',
      stage: 'https://d1hfyuqedzintc.cloudfront.net',
      internal: 'https://d3cnci83cg37sc.cloudfront.net',
      local: 'https://d3cnci83cg37sc.cloudfront.net',
    }
  },
  http: {
    /** @type {string | number} */
    port: process.env.PORT || 5400,
    favicon: path.join(__dirname, '..', 'src/client/assets/favicon.ico'),
    robots: 'User-agent: *\nDisallow:',
    static: [
      {
        url: '/public', path: path.join(__dirname, '..', 'public/')
      }
    ]
  },
  webpack: {
    dev_port: 14000
  },
};
