/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  entityId: ID;
 *  automaticMessages: {
 *    WELCOME: {
 *      message: string;
 *      enabled: boolean;
 *    };
 *    FIRST_MESSAGE: {
 *      message: string;
 *      enabled: boolean;
 *    };
 *    OUT_WORKING_HOURS: {
 *      message: string;
 *      enabled: boolean;
 *    };
 *  };
 * }} TVariables
 **/

/**
 * @param {TVariables} variables
 */
export async function createOrUpdateChannelAutomaticMessagesRepository(
  variables,
) {
  const { entityId, automaticMessages } = variables;

  const mutation = gql`
    mutation createOrUpdateChannelAutomaticMessages(
      $input: CreateOrUpdateAutomaticMessageInput!
    ) {
      createOrUpdateAutomaticMessage(input: $input) {
        success
      }
    }
  `;

  const input = {
    entityId,
    automaticMessages,
  };

  const { data, errors } = await Mutation(mutation, { input });

  return {
    data,
    errors,
  };
}
