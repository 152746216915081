import { getSelectedUsersUseCase } from '../GetSelectedUsers';

describe('given getSelectedUsers use case', () => {
  const mocks = [{
    id: 1,
    fullname: 'Mateus Andrade',
    phone: '5519900000002',
    email: 'i+admin@classapp.com.br',
    confirmPhone: true,
    confirmEmail: true,
    tags: {
      nodes: [
        { id: 1, name: 'Tag 1' }
      ]
    }
  }];

  it('should be able to return empty array when param is not provided', () => {
    const result = getSelectedUsersUseCase(null);

    expect(result).toEqual([]);
  });

  it('should be able to return the selected users formatted', () => {
    const result = getSelectedUsersUseCase(mocks);

    expect(result).toEqual([{
      address: '5519900000002',
      original: true,
      tags: [{ id: 1, name: 'Tag 1' }],
      type: 'PHONE',
      user: {
        confirmEmail: true, confirmPhone: true, email: 'i+admin@classapp.com.br', fullname: 'Mateus Andrade', id: 1, phone: '5519900000002', tags: { nodes: [{ id: 1, name: 'Tag 1' }] }
      }
    }, {
      address: 'i+admin@classapp.com.br',
      original: true,
      tags: [{ id: 1, name: 'Tag 1' }],
      type: 'EMAIL',
      user: {
        confirmEmail: true, confirmPhone: true, email: 'i+admin@classapp.com.br', fullname: 'Mateus Andrade', id: 1, phone: '5519900000002', tags: { nodes: [{ id: 1, name: 'Tag 1' }] }
      }
    }]);
  });
});
