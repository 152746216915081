import { omit, uniqBy } from 'lodash';

export function convertAddressesToInputUseCase(params) {
  if (!params) {
    return {
      addresses: [],
      removedIds: []
    };
  }

  const {
    selectedUsers,
    originalUsers = [],
    originalAddresses = []
  } = params;

  let addresses = [];

  const getInviteAdresses = originalUsers.filter(address => !address.user);
  const addedUsers = selectedUsers.filter(({ user }) => !originalUsers.find(original => (original.user && original.user.id) === (user && user.id)));

  const pendingAddresses = originalAddresses.reduce((acc, address) => {
    if (!address.userId) {
      acc.push({
        address: address.address, tags: null, type: address.type, invite: false
      });
    }
    return acc;
  }, []);

  // eslint-disable-next-line array-callback-return
  getInviteAdresses.map((entity) => {
    const tags = entity && entity.tags && entity.tags.nodes ? entity.tags.nodes.map(tag => omit(tag, '__typename')) : [];
    if (entity && entity.type === 'EMAIL') {
      addresses.push({
        address: entity.address, type: 'EMAIL', tags, invite: false
      });
    }
    if (entity && entity.type === 'PHONE') {
      addresses.push({
        address: entity.address, type: 'PHONE', tags, invite: false
      });
    }
  });

  // eslint-disable-next-line array-callback-return
  originalUsers.map(({ user }) => {
    const tags = user && user.tags && user.tags.nodes ? user.tags.nodes.map(tag => omit(tag, '__typename')) : [];
    if (user && user.email) {
      addresses.push({
        address: user.email, type: 'EMAIL', tags, invite: false
      });
    }
    if (user && user.phone) {
      addresses.push({
        address: user.phone, type: 'PHONE', tags, invite: false
      });
    }
  });

  // eslint-disable-next-line array-callback-return
  addedUsers.map(({ user }) => {
    const tags = user && user.tags && user.tags.nodes ? user.tags.nodes.map(tag => omit(tag, '__typename')) : [];
    if (user && user.email) {
      addresses.push({
        address: user.email, type: 'EMAIL', tags, invite: true
      });
    }
    if (user && user.phone) {
      addresses.push({
        address: user.phone, type: 'PHONE', tags, invite: true
      });
    }
  });

  addresses = addresses.concat(pendingAddresses);

  const removeDuplicateAddressess = uniqBy(addresses, 'address');
  const removedUsers = originalUsers.filter(o => o.user).filter(originalUser => !selectedUsers.find(({ user }) => (user && user.id) === (originalUser.user && originalUser.user.id)));
  const removedIds = uniqBy(removedUsers.filter(e => e.user).map(item => item.user.id));

  return {
    addresses: removeDuplicateAddressess,
    removedIds
  };
}
