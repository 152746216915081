import { getApplicationsRepository } from '../../../Data/Repository/Application/GetApplications';
/**
 * @param {import("../../../Data/Repository/Application/GetApplications").TVariables} variables
 */
export async function getApplicationsUseCase(variables) {
  const { data, errors } = await getApplicationsRepository(variables);

  return {
    data,
    errors
  };
}
