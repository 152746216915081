import { useState } from 'react';
import { getEntityDirectMessageReadStatusUseCase } from '../../../../Domain/UseCases/Channel/GetEntityDirectMessageReadStatus';

export function ViewModel() {
  const [mfcReady, setMfcReady] = useState(null);

  /**
   * @param {import('../../../../store').Store} store
   */
  async function handleUpdateChatsReadStatus(store) {
    if (!store.currentEntity) return;

    const { data, error } = await getEntityDirectMessageReadStatusUseCase({
      entityId: store.currentEntity.id,
    });

    if (error) {
      console.error(error);
    } else {
      const { hasUnreadDirectMessages } = data.node;
      store.currentEntity.hasUnreadDirectMessages = hasUnreadDirectMessages;
    }
  }

  return {
    handleUpdateChatsReadStatus,
    mfcReady,
    setMfcReady,
  };
}
