import styled from 'styled-components';

export const Description = styled.p`
font-size: 1rem;
`;

export const DescriptionBold = styled.p`
color: #000;
font-size: 1rem;
font-weight: 500;
`;

export const DescriptionOpacity = styled.p`
color: #616161;
font-size: 1rem;
`;

export const DescriptionOpacityContainer = styled.div`
margin-top: 1.25rem;
margin-bottom: 1.25rem;
`;

export const IFrameContainer = styled.div`
margin-top: 1.25rem;
`;

export const FooterContainer = styled.div`
margin-top: 1.25rem;
display: flex;
`;

export const Link = styled.a`
color: #0069FF;
text-decoration-line: underline;
font-size: 1rem;
`;

export const IFrame = styled.iframe`
`;

export const Container = styled.div`
`;
