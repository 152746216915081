/**
  * @typedef {{
  *  entityType?: 'ADMIN' | 'STAFF' | 'STUDENT';
* }} TVariables
**/

/**
* @param {TVariables} variables
*/
export function isDisabledUseCase(variables) {
  if (!variables) return false;
  return variables?.entityType === 'ADMIN';
}
