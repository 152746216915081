import React from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';

import config from '../../../../../../../config/server.config';
import { createUrlWithHash, asset } from '../../../../../utils';
import Loading from '../../../../../components/ui/Loading';
import { Container } from './ViewStyles';
import { __ } from '../../../../../i18n';
import { Fallback } from '../../../../../components/kit/Fallback';

export default function ViewUI({ isLoading, env }) {
  return (
    <Container>
      {isLoading ? (
        <Loading data-testid="loading-ui" width={30} height={30} />
      ) : (
        <RemoteMountedComponent
          url={createUrlWithHash(
            `${config.microfrontends.katara[env]}/remoteEntry.js`,
          )}
          name="katara"
          moduleName="./mountMethods"
          rootRoute="/chats"
          fallbackComponent={
            <Fallback
              title={__('Connection error')}
              subtitle={__('Try again later')}
              src={asset('/images/no-connection.png')}
              alt="No connection image"
            />
          }
          loadingComponent={<Loading width={30} height={30} />}
        />
      )}
    </Container>
  );
}
