import cookie from 'react-cookie';
import { Upload } from '../PictureUploadDataSource';
import * as uploadService from '../../../services/upload';

describe('PictureUploadDataSource', () => {
  describe('given the Upload function', () => {
    it('should call upload with the correct params', () => {
      jest.spyOn(uploadService, 'upload').mockImplementation(() => ({
        data: { data: { node: { id: 1 } } }
      }));

      jest.spyOn(cookie, 'load').mockImplementation(() => 'access_token');

      // eslint-disable-next-line new-cap
      Upload('mutation', { id: 1 });

      expect(uploadService.upload).toHaveBeenCalledWith('picture', { file: { id: 1 }, query: 'mutation', variables: null }, 'access_token');
    });
  });
});
