import React from 'react';
import { Message } from '../Presentation/pages/Channel/components/AutomaticMessages/Message';
import { Trigger } from '../Presentation/pages/Channel/components/AutomaticMessages/Trigger';
import { automaticMessageTypes } from './automaticMessageTypes';

/**
 * Check if user is master and has a manager account permission.
 * @param {{
 *  triggersRef: any,
 *  messagesRef: any,
 *  messages: any[],
 * }} params
 */
export function formatAutomaticMessages({
  messages,
  messagesRef,
  triggersRef,
}) {
  if (!messages.length) {
    return [];
  }

  return messages?.map((message, index) => {
    return {
      ...message,
      columns: [
        {
          id: message.type,
          icon: null,
          content: (
            <Trigger
              ref={(c) => (triggersRef.current[index] = c)}
              title={automaticMessageTypes()[message.type].title}
              enabled={message.enabled}
              onChange={(checked) => {
                messagesRef.current[index]?.changeDisabled(!checked);
              }}
              description={automaticMessageTypes()[message.type].description}
            />
          ),
        },
        {
          id: message.message,
          icon: null,
          content: (
            <Message
              ref={(c) => (messagesRef.current[index] = c)}
              value={message.message}
              disabled={!message.enabled}
            />
          ),
        },
      ],
    };
  });
}
