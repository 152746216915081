import { __ } from '../../i18n';

export const automaticMessageTypes = () => ({
  WELCOME: {
    title: __('Greeting message'),
    description: __('Fixed message displayed whenever the chat starts.'),
  },
  FIRST_MESSAGE: {
    title: __('After first message'),
    description: __(
      // eslint-disable-next-line quotes
      "Automatic reply sent immediately after the family's first contact in the channel.",
    ),
  },
  OUT_WORKING_HOURS: {
    title: __('Outside service hours'),
    description: __(
      'Automatic reply when the channel is outside opening hours.',
    ),
  },
});
