import { hasMessageApproveUseCase } from '../HasMessageApprove';

describe('given hasMessageApprove use case', () => {
  const variables = {
    unleashStatus: {
      approve_message: true
    },
    plan: 'PREMIUM',
  };

  it('should be able to return empty array when param is not provided', () => {
    const result = hasMessageApproveUseCase(null);

    expect(result).toEqual(false);
  });

  it('should be able to return if it has message to approve when plan is premium', () => {
    const result = hasMessageApproveUseCase(variables);

    expect(result).toEqual(true);
  });

  it('should be able to return if it has message to approve when plan is not premium', () => {
    variables.plan = 'STANDARD';

    const result = hasMessageApproveUseCase(variables);

    expect(result).toEqual(false);
  });
});
