const chunks = {
  components: [
    'TermsAndPrivacy',
    'ui'
  ],
  containers: [
    'Account',
    'Admin',
    'Arrived',
    'Auth',
    'Channel',
    'Charge',
    'Commitment',
    'Dashboard',
    'Entity',
    'Event',
    // 'Form',
    'FormResult',
    'Free',
    // 'Group',
    'Message',
    'Moment',
    'Onboarding',
    'Organization',
    'Reply',
    'Report',
    'ReportResult',
    'Simulation',
    // 'Survey',
    'User'
  ],
  locales: [
    'pt',
    'en',
    'es',
    'zh'
  ],
  lib: [],
  utils: false
};

const srcChunks = Object.keys(chunks).reduce((cacheGroups, chunk) => {
  // Files
  if (!chunks[chunk]) {
    cacheGroups[chunk] = {
      test: new RegExp(`(?<![\\\\/]node_modules.*)[\\\\/]${chunk}\\.`),
      name: chunk,
      chunks: 'all',
    };
    return cacheGroups;
  }

  // Folders
  if (chunks[chunk].length === 0) { // All files from folder
    cacheGroups[chunk] = {
      test: new RegExp(`(?<![\\\\/]node_modules.*)[\\\\/]${chunk}[\\\\/]`),
      name: chunk,
      chunks: 'all',
    };
    return cacheGroups;
  }

  chunks[chunk].forEach((subChunk) => { // Adds each selected file/subfolder from folder
    const name = `${chunk}_${subChunk}`;
    cacheGroups[name] = {
      test: new RegExp(`(?<![\\\\/]node_modules.*)[\\\\/]${chunk}[\\\\/]${subChunk}[\\\\/.]`),
      name,
      chunks: 'all',
    };
  });
  const excluded = chunks[chunk].join('[\\\\/.]|');
  cacheGroups[chunk] = { // Adds all the rest
    test: new RegExp(`(?<![\\\\/]node_modules.*)[\\\\/]${chunk}[\\\\/](?!${excluded})`),
    name: chunk,
    chunks: 'all',
  };
  return cacheGroups;
}, {});

const nodeModulesChunks = {
  node_modules: [
    'apollo-client',
    'awesome-phonenumber',
    'core-js',
    'cropperjs',
    'jszip',
    'lodash',
    'lodash-es',
    'mobx',
    'moment',
    'moment-timezone',
    'pako',
    'quill',
    'react-color',
    'react-dom',
    'react-html5video',
    'react-json-editor-ajrm',
    'react-slick',
    'recharts',
    'semantic-ui-react',
    '@sentry',
    'unorm',
  ],
};

const vendorsChunks = Object.keys(nodeModulesChunks).reduce((cacheGroups, chunk) => {
  nodeModulesChunks[chunk].forEach((subChunk) => { // Adds each selected file/subfolder from folder
    const name = `${chunk}_${subChunk}`;
    cacheGroups[name] = {
      test: new RegExp(`[\\\\/]${chunk}[\\\\/]${subChunk}[\\\\/.]`),
      name,
      chunks: 'all',
    };
  });
  const excluded = nodeModulesChunks[chunk].join('[\\\\/.]|');
  cacheGroups[chunk] = { // Adds all the rest
    test: new RegExp(`(?<![\\\\/]node_modules.*)[\\\\/]${chunk}[\\\\/](?!${excluded})`),
    name: chunk,
    chunks: 'all',
  };
  return cacheGroups;
}, {});

const splitChunks = {
  cacheGroups: {
    ...srcChunks,
    ...vendorsChunks
  },
};

module.exports = splitChunks;
