import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from '@classapp-tech/edna';
import cookie from 'react-cookie';
import moment from 'moment';
import { __ } from '../../../i18n';
import BetaModal from '../../../components/ui/Modal';
import { sendGoogleAnalyticsEvent } from '../../../lib/analytics';
import {
  Description,
  DescriptionBold,
  DescriptionOpacity,
  Link,
  IFrame,
  Container,
  DescriptionOpacityContainer,
  IFrameContainer,
  FooterContainer,
} from './ViewStyles';

export default function ViewUI({ store, onClose, router }) {
  const { app } = store;
  const date = new Date();
  const lastSeenChatNews = cookie.load('lastSeenChatNews') || {};
  lastSeenChatNews[store.currentOrganization.id] = {
    date: moment(date).format('YYYY-MM-DD'),
  };
  cookie.save(
    'lastSeenChatNews',
    lastSeenChatNews,
    {
      path: '/',
      domain: app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
      maxAge: 60 * 60 * 24 * 30
    }
  );

  return (
    <BetaModal
      id="ChatNews"
      size="medium"
      header={__('Chats: a new way of interacting with families 💙')}
      content={(
        <Container>
          <Container>
            <DescriptionBold>
              {__('"Chats" is ClassApp\'s new space for your school to offer even more efficient, quality and organized services.')}
            </DescriptionBold>
          </Container>
          <DescriptionOpacityContainer>
            <DescriptionOpacity>
              {__('The new feature updates the way families get in touch with the school\'s departments and teams.')}
            </DescriptionOpacity>
            <DescriptionOpacity>
              {__('The chat will have the structure of a real-time chat and will bring more data about services to school management.')}
            </DescriptionOpacity>
          </DescriptionOpacityContainer>
          <Container>
            <Link
              href="https://conteudo.classapp.com.br/conversas-cli"
              target="_blank"
              onClick={() => {
                sendGoogleAnalyticsEvent({
                  name: 'config-conversation-modal-learn-more',
                  label: `OrganizationId: ${store.currentOrganization.id}`,
                }, { store });
              }}
            >
              {__('Learn more')}
            </Link>
          </Container>
          <IFrameContainer>
            <IFrame
              id="config-conversation-video"
              style={{ borderRadius: 12 }}
              width="855"
              height="481"
              src="https://www.youtube.com/embed/fMJOBMZaTL4"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            />
          </IFrameContainer>

          <FooterContainer>
            <Icon name="arrow right" style={{ fontSize: '16px' }} />
            <Description>{__('Configure the service channels and provide more management and agility in meeting the demands of the school community.')}</Description>
          </FooterContainer>
        </Container>
    )}
      actions={[

        <Button
          data-testid="config-chats"
          variation="primary"
          onClick={() => {
            router.push({
              pathname: `/organizations/${store.currentOrganization.id}/channels`,
              state: { channelConfig: true }
            });
            sendGoogleAnalyticsEvent({
              name: 'config-conversation-modal',
              label: `OrganizationId: ${store.currentOrganization.id}`,
            }, { store });
            onClose();
          }}
        >
          {__('Configure')}
        </Button>

      ]}
      onClose={() => {
        sendGoogleAnalyticsEvent({
          name: 'config-conversation-modal-close',
          label: `OrganizationId: ${store.currentOrganization.id}`,
        }, { store });
        onClose();
      }}
    />
  );
}
