import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { createOrUpdateChannelAutomaticMessagesRepository } from '../CreateOrUpdateChannelAutomaticMessages';

jest.mock('../../../DataSource/ApolloDataSource', () => ({
  Mutation: jest.fn(),
}));

describe('given the createOrUpdateChannelAutomaticMessagesRepository function', () => {
  const variables = {
    entityId: '123',
    automaticMessages: {
      WELCOME: {
        message: 'Bem-vindo!',
        enabled: true,
      },
      FIRST_MESSAGE: {
        message: 'Primeira mensagem automática',
        enabled: true,
      },
      OUT_WORKING_HOURS: {
        message: 'Estamos fora do horário de atendimento',
        enabled: false,
      },
    },
  };

  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should call Mutation with the correct mutation and input', async () => {
    const mockResponse = { data: { success: true }, errors: null };
    apolloDataSource.Mutation.mockResolvedValueOnce(mockResponse);

    await createOrUpdateChannelAutomaticMessagesRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledTimes(1);

    const [mutationDocument, params] = apolloDataSource.Mutation.mock.calls[0];

    const operationName = mutationDocument.definitions[0].name.value;
    expect(operationName).toBe('createOrUpdateChannelAutomaticMessages');

    expect(params.input).toEqual({
      entityId: '123',
      automaticMessages: {
        WELCOME: { message: 'Bem-vindo!', enabled: true },
        FIRST_MESSAGE: {
          message: 'Primeira mensagem automática',
          enabled: true,
        },
        OUT_WORKING_HOURS: {
          message: 'Estamos fora do horário de atendimento',
          enabled: false,
        },
      },
    });
  });

  it('should return data and errors from the Mutation function', async () => {
    const mockResponse = { data: { success: true }, errors: null };
    apolloDataSource.Mutation.mockResolvedValueOnce(mockResponse);

    const result =
      await createOrUpdateChannelAutomaticMessagesRepository(variables);

    expect(result).toEqual(mockResponse);
  });

  it('should handle errors in the Mutation function', async () => {
    const mockError = { data: null, errors: ['Erro na mutation'] };
    apolloDataSource.Mutation.mockResolvedValueOnce(mockError);

    const result =
      await createOrUpdateChannelAutomaticMessagesRepository(variables);

    expect(result).toEqual(mockError);
  });
});
