import React from 'react';

import { render } from '../../../../../../newRender';
import ViewContoller from '../ViewController';
import * as analytics from '../../../../../../lib/analytics';
import * as ViewModel from '../ViewModel';
import { getOrganizationUnleashMock } from '../../../../../Data/Repository/Unleash/__mocks__/getOrganizationUnleash.mock';

describe('Chats ViewContoller', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    jest
      .spyOn(analytics, 'sendGoogleAnalyticsEvent')
      .mockImplementation(() => {});
    jest.spyOn(ViewModel, 'ViewModel').mockImplementation(() => ({
      redirectToLastPageIfNotHasAccess: jest.fn(),
      getOrganizationUnleashFeature: jest
        .fn()
        .mockResolvedValue(getOrganizationUnleashMock),
      isLoading: false,
    }));
  });

  it('should render screen', () => {
    const { container } = render(<ViewContoller />);

    expect(ViewModel.ViewModel).toHaveBeenCalled();
    expect(container).toBeInTheDocument();
  });

  it('should be able to see the loading by default', () => {
    jest.spyOn(ViewModel, 'ViewModel').mockImplementation(() => ({
      redirectToLastPageIfNotHasAccess: jest.fn(),
      getOrganizationUnleashFeature: jest
        .fn()
        .mockResolvedValue(getOrganizationUnleashMock),
      isLoading: true,
    }));

    const { getByTestId } = render(<ViewContoller />);
    expect(getByTestId('loading-ui')).toBeInTheDocument();
  });
});
