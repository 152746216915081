import { removeIdsFromChannelRepository } from '../../../Data/Repository/Channel/RemoveIdsFromChannel';

/**
* @param {number} entityId
* @param {number[]} removedIds
*/
export async function removeIdsFromChannelUseCase(entityId, removedIds) {
  const { data, errors } = await removeIdsFromChannelRepository(entityId, removedIds);

  return {
    data,
    errors
  };
}
