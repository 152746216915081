import React from 'react';
import { render, screen } from '@testing-library/react';
import ViewUI from '../ViewUI';
import { __ } from '../../../../i18n';
import { PubSub } from 'pubsub-js';

describe('ViewUI', () => {
  beforeEach(() => {
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
  });

  describe('given files screen header', () => {
    it('should render header title', () => {
      render(<ViewUI />);
      const title = screen.getByText(__('Files'));
      expect(title).toBeVisible();
    });

    it('should render help icon', () => {
      render(<ViewUI />);
      const helpIcon = screen.getByRole('link', { name: 'help' });
      expect(helpIcon).toBeVisible();
    });
  });
});
