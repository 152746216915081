import { getChannelListRepository } from '../../../Data/Repository/Channel/getChannelListRepository';

/**
 * @param {import("../../../Data/Repository/Channel/getChannelListRepository").TVariables} variables
 * @returns {import("../../../Data/Repository/Channel/getChannelListRepository").TResponse}
 */
export async function getChannelListUseCase(variables) {
  const { data, error } = await getChannelListRepository(variables);
  return { data, error };
}
