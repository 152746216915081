/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  organizationId: string | number,
 *  search?: string,
 *  limit: number,
 *  offset: number,
 * }} TVariables
 */

/**
 * @typedef {Promise<{
 *  data: {
 *    pageInfo: {
 *      hasNextPage: boolean
 *    },
 *    totalCount: number,
 *    nodes: {
 *      id: number | string,
 *      fullname: string,
 *      type: string,
 *      description: string,
 *      picture: {
 *        uri: string
 *      } | null,
 *      ntfStartTime: string | null,
 *      ntfEndTime: string | null,
 *      confAttendanceEnabled: boolean
 *    },
 *  } | null,
 *  error?: string
 * }>} TResponse
 */

/**
 * @param {TVariables} variables
 * @returns {TResponse}
 */
export async function getChannelListRepository(variables) {
  const query = gql`
    query GetRecicipentsList(
      $organizationId: ID!,
      $limit: Int,
      $offset: Int,
      $search: String,
    ){
      node(id: $organizationId) {
        ... on Organization {
          id: dbId
          entities(limit: $limit, isChannel: true, offset: $offset, search: $search) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
            nodes {
              id: dbId
              fullname
              isChannel
              confAttendanceEnabled
              type
              description
              picture {
                uri
              }
              ntfStartTime
              ntfEndTime
              invisible
              disabled
              seeAll
              selected
            }
          }
        }
      }
    }
  `;

  const { data, error } = await Query(query, variables);

  return { data, error };
}
