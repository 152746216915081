import { getEntityDirectMessageReadStatusRepository } from '../../../Data/Repository/Channel/GetEntityDirectMessageReadStatus';

/**
 * @param {import("../../../Data/Repository/Channel/GetEntityDirectMessageReadStatus").TVariables} variables
 * @returns {Promise<import("../../../Data/Repository/Channel/GetEntityDirectMessageReadStatus").TResponse>}
 */
export async function getEntityDirectMessageReadStatusUseCase(variables) {
  const { data, error } = await getEntityDirectMessageReadStatusRepository(variables);
  return { data, error };
}
