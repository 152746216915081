import React, { useEffect } from 'react';
import ViewUI from './ViewUI';
import * as analytics from '../../../../../lib/analytics';
import { createStore } from '../../../../../store';
import { ViewModel as useViewModel } from './ViewModel';
import PubSub from 'pubsub-js';

import { formatUnleashStatusIntoToggle } from '../../../../../helpers/formatUnleashStatusIntoToggles';

export default function ViewController(props) {
  const {
    redirectToLastPageIfNotHasAccess,
    getOrganizationUnleashFeature,
    isLoading,
  } = useViewModel();
  const store = createStore();
  const env = store?.app?.env;

  useEffect(() => {
    (async () => {
      const response = await getOrganizationUnleashFeature(
        store?.organization?.id ?? store.currentOrganization?.id,
      );

      redirectToLastPageIfNotHasAccess({
        currentOrganization: store?.currentOrganization,
        currentEntity: store?.currentEntity,
        unleash: {
          toggles: formatUnleashStatusIntoToggle(
            response?.data?.node?.unleashStatus,
          ),
        },
        currentUser: store?.currentUser,
        router: props.router,
      });
    })();
  }, []);

  useEffect(() => {
    PubSub.subscribe('MICROFRONTEND_READY', (_, mcfName) => {
      const organization = store?.currentOrganization;
      const entity = store?.currentEntity;
      const user = store?.currentUser;
      const host = store?.app?.host;

      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        user,
        host,
      });
    });

    analytics.sendGoogleAnalyticsEvent(
      {
        name: 'channels_chat_feature',
        category: `open_manager_dashboard_chat_feature_${store?.app?.env}`,
        label: store?.entity?.type,
      },
      { store },
    );
  }, [store.currentEntity, store.currentUser]);

  return <ViewUI isLoading={isLoading} env={env} />;
}
