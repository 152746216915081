import React, { useEffect } from 'react';

import { createStore } from '../../../../../store';

import { ViewModel as useViewModel } from './ViewModel';
import { ViewUI } from './ViewUI';

export default function ViewController(props) {
  const {
    isLoading,
    setIsLoading,
    setHasChannelDashboard,
    hasChannelDashboard,
  } = useViewModel();
  const store = createStore();
  const { unleash } = store;

  useEffect(() => {
    if (unleash && unleash.isReady) {
      const __hasChannelDashboard = unleash.getStatus(
        'menu_conversations_entity_dashboard',
      );
      setHasChannelDashboard(__hasChannelDashboard);
      setIsLoading(false);
    }
  }, [unleash.isReady]);

  return (
    <ViewUI
      {...props}
      isLoading={isLoading}
      hasChannelDashboard={hasChannelDashboard}
    />
  );
}
