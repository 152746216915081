import { createChannelRepository } from '../../../Data/Repository/Channel/CreateChannel';

/**
* @param {import("../../../Data/Repository/Channel/CreateChannel").TVariables} variables
*/
export async function createChannelUseCase(variables) {
  const { data, errors } = await createChannelRepository(variables);
  return {
    data,
    errors
  };
}
