import cookie from 'react-cookie';

export const shouldShowChatNewsModal = (store) => {
  if (!store.currentEntity) return false;
  if (!store.currentOrganization) return false;
  if (store.currentEntity.type !== 'ADMIN') return false;
  const organization = store.currentOrganization;
  const hasMenuConversation = organization.unleashStatus?.web_menu_conversations;

  if (!hasMenuConversation) return false;

  const productFeatures = organization?.productFeatures.nodes.filter(pf => pf.identifier === 'attendanceChannel');
  const hasAttendanceChannel = productFeatures.length > 0;

  if (hasAttendanceChannel) {
    return false;
  }

  const lastSeenChatNews = cookie.load('lastSeenChatNews') || {};
  const lastSeenDate = new Date(lastSeenChatNews[store.currentOrganization.id]?.date);
  const currentDate = new Date();
  const daysSinceLastSeen = Math.floor((currentDate - lastSeenDate) / (24 * 60 * 60 * 1000));
  return daysSinceLastSeen >= 7 || !lastSeenChatNews[store.currentOrganization.id];
};
