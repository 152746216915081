import { observable, extendObservable, toJS } from 'mobx';

// Default store structure
export const defaultStore = {
  app: {
    url: 'http://localhost:2000',
    name: 'ClassApp',
    title: '',
    description: '',
    host: '',
    color: '1979BB',
    locale: 'en',
    env: 'production'
  },
  access_token: null,
  refresh_token: null,
  currentEntity: null,
  currentUser: {
    isMaster: false,
  },
  currentOrganization: {
    id: 1,
    color: '1979BB',
    fullname: 'Teste Escola',
    cover: {
      original: null,
    },
    permissions: {
      hasAdmin: false,
      entityScopes: [],
    }
  },
  admin: {
    organizations: null,
    users: null
  },
  entity: {
    add: null,
    edit: null,
    node: null,
    messages: null,
    medias: null,
    commitments: null,
    recipients: null
  },
  group: {
    add: null,
    edit: null,
    delete: null,
    node: null,
    item: {
      name: null,
      id: null
    },
    entities: null
  },
  organization: {
    node: null,
    entities: null,
    groups: null,
    messages: null,
    reports: null,
    tags: null,
  },
  user: {
    node: null,
    entities: null,
    invitations: null,
    applications: null,
    notifications: null
  },
  message: {
    node: null,
    add: null,
    replies: null,
    surveys: null,
    entities: null
  },
  media: {
    node: null
  },
  application: {
    node: null,
    edit: null
  },
  report: {
    add: null,
    edit: null,
    delete: null
  },
  auth: {
    email: '',
    phone: '',
    type: 'email',
    address: null,
    user: null
  },
  snackbar: {
    active: false,
    message: '',
    success: false
  },
  loadingRequest: false,

  // Controls number of open modals
  modals: [],
  // Previous request, used to minimize request size
  previous: {
    params: {},
    components: []
  },
  // Renders content at start of document
  prepends: [],
  // Renders content at end of document
  appends: [],

  // Page loading for history push
  pageLoading: false,
  isMenuExpanded: true,
  isCoverExpanded: true
};

const observableDefaultStore = observable(defaultStore);

// Export an instance of our store ( function so that we don't re-use same object for every session )
export const createStore = (store = {}) => (
  process.env.IS_CLIENT === true ? extendObservable(observableDefaultStore, store) : toJS(observableDefaultStore)
);
