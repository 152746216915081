/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
  * @typedef {{
  *  entityId: number;
  * }} TVariables
**/

/**
* @param {TVariables} variables
*/
export async function deleteChannelPictureRepository(variables) {
  const mutation = gql`
    mutation deletePicture($deletePictureMutation: DeletePictureInput!) {
      deletePicture(input: $deletePictureMutation) {
        clientMutationId
      }
    }
`;

  const input = {
    deletePictureMutation: {
      ...variables
    }
  };

  const { data, errors } = await Mutation(mutation, input);

  return {
    data,
    errors
  };
}
