import React from 'react';

import Loading from '../../../../../components/ui/Loading';

import { Container, Header, Title, Content } from './ViewStyles';

import { Tabs } from '../../../../../components/kit/Tabs';

import Messages from './Messages/ViewUI';

import { __ } from '../../../../../i18n';
import { ViewUI as AttendanceDashboard } from './Attendances/ViewUI';

export function ViewUI({ hasChannelDashboard, isLoading, ...props }) {
  if (isLoading) {
    return (
      <Container>
        <Header>
          <Title>{__('Dashboard')}</Title>
        </Header>
        <Content>
          <Loading data-testid="loading-container" width={30} height={30} />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>{__('Dashboard')}</Title>
      </Header>
      <Content>
        <Tabs data-testid="tabs-component">
          <Tabs.Tab value="communications-tab" title={__('Communications')}>
            <Messages {...props} />
          </Tabs.Tab>
          {hasChannelDashboard && (
            <Tabs.Tab value="conversations-tab" title={__('Conversations')}>
              <AttendanceDashboard />
            </Tabs.Tab>
          )}
        </Tabs>
      </Content>
    </Container>
  );
}
