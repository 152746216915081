import { renderHook } from '@testing-library/react-hooks/dom';
import { useScreenSize } from '../useScreenSize';

describe('given the useScreenSize hook', () => {
  it('should be able to see the screen size', () => {
    jest
      .spyOn(window, 'addEventListener')
      .mockImplementation((event, callback) => callback(event));
    const { result } = renderHook(() => useScreenSize());

    expect(result.current).toEqual({ width: 1024, height: 768 });
  });
});
