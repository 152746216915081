import * as ApolloDataSource from '../../../../Data/DataSource/ApolloDataSource';
import { getApplicationsUseCase } from '../GetApplications';


describe('given getApplicationsUseCase use case', () => {
    const variables = {
        search: "Duplicate Document Number Prevention"
    };

    const dataMock = {
        applications: [
            {
                id: "123",
                name: "Duplicate Document Number Prevention",
                redirectUri: "https://link.de.ajuda.com"
            }
        ]
    }

    beforeAll(() => {
        jest.spyOn(ApolloDataSource, 'Query').mockImplementation(async () => { return { data: dataMock } });
    });

    it('should be able to get an application', async () => {
        const result = await getApplicationsUseCase(variables);
        expect(result?.data?.applications).not.toBeFalsy();
    });
});
