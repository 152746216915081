import * as UploadDataSource from '../../../../Data/DataSource/PictureUploadDataSource';
import { uploadChannelPictureUseCase } from '../UploadChannelPicture';
import { uploadChannelPictureUseCaseMock } from '../__mocks__/channelUseCase.mock';

describe('given uploadChannelPictureUseCase use case', () => {
  const mocks = {
    entityId: 123
  };

  beforeAll(() => {
    jest.spyOn(UploadDataSource, 'Upload').mockImplementation(async () => uploadChannelPictureUseCaseMock);
  });

  it('should be able to create a channel', async () => {
    const result = await uploadChannelPictureUseCase('file', mocks);
    expect(result?.data?.clientMutationId).not.toBeFalsy();
  });
});
