/**
 * @typedef {{
 *  PUBLIC: 'public',
 *  CUSTOM: 'custom',
 * }} VisibilityStatus
 */

/**
 * @readonly
 * @enum {VisibilityStatus}
 * @type {VisibilityStatus}
 */
export const VisibilityStatusEnum = {
  PUBLIC: 'public',
  CUSTOM: 'custom',
};
