import React from 'react';
import { render, fireEvent } from '../../../../../../newRender';
import ViewController from '../ViewController';

import { __ } from '../../../../../../i18n';

describe('ViewController', () => {
  it('should be defined', () => {
    const { container } = render(<ViewController />);
    expect(container).toBeInTheDocument();
  });

  it('should be able to go back when click in the router icon', () => {
    const props = {
      router: {
        goBack: jest.fn()
      }
    };

    const { getByTestId } = render(<ViewController {...props} />);
    const onBack = getByTestId('onBack');
    fireEvent.click(onBack);

    expect(props.router.goBack).toHaveBeenCalled();
  });

  it('should be able to find name input component', async () => {
    const { findByText } = render(<ViewController />);
    const channelName = await findByText(`${__('Channel`s name')} *`);
    expect(channelName).toBeInTheDocument();
  });

  it('should be able to find external id input component', async () => {
    const { findByText } = render(<ViewController />);
    const externalId = await findByText(__('External ID'));
    expect(externalId).toBeInTheDocument();
  });

  it('should be able to find description input component', async () => {
    const { findByText } = render(<ViewController />);
    const description = await findByText(__('Description'));
    expect(description).toBeInTheDocument();
  });

  it('should be able to find picture input component', async () => {
    const { findByText } = render(<ViewController />);
    const picture = await findByText(__('Add picture'));
    expect(picture).toBeInTheDocument();
  });

  it('should be able to find hours input component', async () => {
    const { findByTestId } = render(<ViewController />);
    const openTime = await findByTestId('open-time-dropdown');
    const closeTime = await findByTestId('open-time-dropdown');
    expect(openTime).toBeInTheDocument();
    expect(closeTime).toBeInTheDocument();
  });

  it('should be able to find responsible input component', async () => {
    const { findByText } = render(<ViewController />);
    const responsible = await findByText(/Responsible/);
    expect(responsible).toBeDefined();
  });

  it('should be able to find visibility input component', async () => {
    const { findByText } = render(<ViewController />);
    const visibility = await findByText(/Visibility/);
    expect(visibility).toBeDefined();
  });

  it('should be not able to find manage permissions input component', async () => {
    const router = {
      params: {
        entity_id: 1
      }
    };

    const { queryByText } = render(<ViewController router={router} />);
    const managePermissions = await queryByText(__('Manage Permissions'));
    expect(managePermissions).toBeFalsy();
  });
});
