/**
  * @typedef {{
  *  seeAll?: boolean;
* }} TVariables
**/

/**
* @param {TVariables} variables
*/
export function getVisibilityTypeUseCase(variables) {
  return variables?.seeAll ? 'public' : 'custom';
}
