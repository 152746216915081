import cookie from 'react-cookie';
import { Query, Mutation } from '../ApolloDataSource';
import * as apollo from '../../../services/apollo';

describe('ApolloDataSource', () => {
  beforeEach(() => {
    jest.spyOn(cookie, 'load').mockImplementation(() => 'access_token');
  });

  describe('given the Query function', () => {
    it('should call getApolloClient with the correct params', () => {
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        query: jest.fn()
      }));
      const query = Query;
      query('query', { id: 1 });

      expect(apollo.getApolloClient).toHaveBeenCalledWith(null, {
        Authorization: 'Bearer access_token'
      });
    });

    it('should call getApolloClient.query with the correct params', () => {
      const queryMock = jest.fn();
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        query: queryMock
      }));
      const query = Query;
      query('query', { id: 1 });

      expect(queryMock).toHaveBeenCalledWith({
        query: 'query',
        variables: { id: 1 }
      });
    });

    it('should catch errors and return a promise with the error', async () => {
      const queryMock = jest.fn(() => ({ error: 'custom error', data: null, loading: false }));
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        query: queryMock
      }));
      const query = Query;
      const result = await query('query', { id: 1 });

      expect(queryMock).toHaveBeenCalledWith({
        query: 'query',
        variables: { id: 1 }
      });
      expect(result).toEqual({ error: 'custom error', data: null, loading: false });
    });
  });

  describe('given the Mutation function', () => {
    it('should call getApolloClient with the correct params', () => {
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        mutate: jest.fn()
      }));
      const mutation = Mutation;
      mutation('mutation', { id: 1 });

      expect(apollo.getApolloClient).toHaveBeenCalledWith(null, {
        Authorization: 'Bearer access_token'
      });
    });

    it('should call getApolloClient.mutate with the correct params', async () => {
      const mutateMock = jest.fn();
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        mutate: mutateMock
      }));
      const mutation = Mutation;
      await mutation('mutation', { id: 1 });

      expect(mutateMock).toHaveBeenCalledWith({
        mutation: 'mutation',
        variables: { id: 1 }
      });
    });

    it('should return the result', async () => {
      const mutateMock = jest.fn(() => ({ error: 'custom error', data: null, loading: false }));
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        mutate: mutateMock
      }));
      // eslint-disable-next-line new-cap
      const result = await Mutation('mutation', { id: 1 });

      expect(result).toEqual({ error: 'custom error', data: null, loading: false });
    });

    it('should catch errors and return a promise with the error', async () => {
      const mutateMock = jest.fn(() => ({ error: 'custom error', data: null, loading: false }));
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => ({
        mutate: mutateMock
      }));
      const mutation = Mutation;
      const result = await mutation('mutation', { id: 1 });

      expect(result).toEqual({ error: 'custom error', data: null, loading: false });
    });

    it('should catch when mutate has a error', async () => {
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => {
        throw new Error('Algo deu errado!');
      });

      const mutation = Mutation;
      const result = await mutation('mutation', { id: 1 });

      expect(result).toEqual({ error: 'Algo deu errado!', data: null, loading: false });
    });

    it('should catch when query has a error', async () => {
      jest.spyOn(apollo, 'getApolloClient').mockImplementation(() => {
        throw new Error('Algo deu errado!');
      });

      const query = Query;
      const result = await query('query', { id: 1 });

      expect(result).toEqual({ error: 'Algo deu errado!', data: null, loading: false });
    });
  });
});
