import cookie from 'react-cookie';
import { upload } from '../../services/upload';
/**
 * @typedef {{
 *  result?: any,
 *  error?: any
 * }} ReturnValue
 */

/**
 * @param {import("graphql").DocumentNode} query
 * @param {object} variables
 */
export function Upload(query, file, variables = null) {
  const access_token = cookie.load('access_token');
  return upload('picture', {
    file,
    query,
    variables
  }, access_token);
}
