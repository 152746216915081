import { isDisabledUseCase } from '../IsDisabled';

describe('given isDisabled use case', () => {
  const variables = {
    entityType: 'ADMIN',
  };

  it('should be able to return empty array when param is not provided', () => {
    const result = isDisabledUseCase(null);

    expect(result).toEqual(false);
  });

  it('should be able to return true if entity type is admin', () => {
    const result = isDisabledUseCase(variables);

    expect(result).toEqual(true);
  });

  it('should be able to return false if entity type is staff', () => {
    variables.entityType = 'STAFF';
    const result = isDisabledUseCase(variables);

    expect(result).toEqual(false);
  });

  it('should be able to return false if entity type is student', () => {
    variables.entityType = 'STUDENT';
    const result = isDisabledUseCase(variables);

    expect(result).toEqual(false);
  });
});
