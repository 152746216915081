import { useState } from 'react';
import { hasConversationProductFeature } from '../../../../../helpers/hasConversationProductFeature';
import { getOrganizationUnleashUseCase } from '../../../../Domain/UseCases/Unleash/GetOrganizationUnleashUseCase';

export function ViewModel() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasOrganizationDashboard, setHasOrganizationDashboard] =
    useState(true);

  async function getOrganizationUnleashFeature(orgId) {
    try {
      return await getOrganizationUnleashUseCase(orgId);
    } finally {
      setIsLoading(false);
    }
  }

  function hasConversationFeature(hasConversationsUnleash, organization) {
    return (
      hasConversationsUnleash && hasConversationProductFeature(organization)
    );
  }

  return {
    isLoading,
    hasOrganizationDashboard,
    setIsLoading,
    setHasOrganizationDashboard,
    hasConversationFeature,
    getOrganizationUnleashFeature,
  };
}
