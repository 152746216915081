/* eslint-disable new-cap */
import { Upload } from '../../DataSource/PictureUploadDataSource';

/**
  * @typedef {{
  *  entityId: number;
  *  file: Blob;
  * }} TVariables
**/

/**
* @param {TVariables} variables
*/
export async function uploadChannelPictureRepository(file, variables) {
  const { entityId } = variables;

  const mutation = `
    mutation createPicture {
      createPicture(input: { entityId: ${entityId} }) {
        clientMutationId
        picture {
          uri
        }
      }
    }
  `;

  const { data, errors } = await Upload(mutation, file);

  return {
    data,
    errors
  };
}
