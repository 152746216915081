import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { getChannelRepository } from '../GetChannel';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the getChannelRepository function', () => {
  it('should call Query with the correct params', async () => {
    const QueryMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => QueryMock);

    const variables = {
      entityId: 1
    };

    await getChannelRepository(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(undefined, variables);
  });

  it('should call use default values in Query function', async () => {
    const QueryMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => QueryMock);
    const variables = {
      entityId: 1,
    };
    await getChannelRepository(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(undefined, {
      ...variables
    });
  });

  it('should return data and errors', async () => {
    const response = { data: 'data', errors: 'errors' };

    jest.spyOn(apolloDataSource, 'Query').mockResolvedValue(response);
    const variables = {
      entityId: 1,
    };
    const result = await getChannelRepository(variables);

    expect(result).toEqual(response);
  });
});
