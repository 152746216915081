import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 24px;
  }
`;

export const UlContainer = styled.ul`
  margin-left: 64px;
  p {
    margin: 0;
  }
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  margin-bottom: 40px;

  .loading-ui{
    width: 86px;
    margin: 0;
      .loader-ui {
        border-top: 3px solid blue;
      }
  }
`;
