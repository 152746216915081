import { uploadChannelPictureRepository } from '../../../Data/Repository/Channel/UploadChannelPicture';

/**
* @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
*/
export async function uploadChannelPictureUseCase(file, variables) {
  const { data, errors } = await uploadChannelPictureRepository(file, variables);

  return {
    data,
    errors
  };
}
