import gql from 'graphql-tag';
import { enableProductFeatureRepository } from '../enableProductFeatureRepository';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('mutation'));

describe('given the "enableProductFeatureRepository" function', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => ({
      data: {
        enableProductFeatureLowTouch: {
          enableSuccess: true,
        }
      },
    }));
  });

  const mutation = [`
    mutation updateProductFeature($input: enableProductFeatureLowTouchInput!) {
      enableProductFeatureLowTouch(input: $input) {
          enableSuccess
      }
    }
  `];

  const variables = {
    organizationId: 10,
  };

  it('should call the "Mutation" function with the correct params', async () => {
    const formatedVariables = {
      input: {
        ...variables,
        identifier: 'attendanceChannel',
      },
    };

    await enableProductFeatureRepository(variables);

    expect(DataSource.Mutation).toHaveBeenCalledWith('mutation', formatedVariables);
  });

  it('should return Mutation response', async () => {
    const response = await enableProductFeatureRepository(variables);

    expect(response).toEqual({ data: { enableProductFeatureLowTouch: { enableSuccess: true } } });
  });

  it('should call the "gql" function with the correct mutation', async () => {
    await enableProductFeatureRepository(variables);

    expect(gql).toHaveBeenCalledWith(mutation);
  });
});
