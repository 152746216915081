import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createStore } from '../../store';

import Config from '../../../../config/server.config';
import i18n from '../../i18n';

const createLink = (headers, clientId = Config.authClientId) => {
  const store = createStore();
  const tzOffsetAndTimezone = typeof window !== 'undefined'
    ? `&tz_offset=${-(new Date().getTimezoneOffset())}&timezone=${i18n.currentLocale()}`
    : '';

  const NODE_DEV = store.app.env;

  return createHttpLink({
    uri: `${Config.api[NODE_DEV]}/graphql?client_id=${clientId}${tzOffsetAndTimezone}`,
    headers
  });
};

// Crie um cache em memória para armazenar os dados
const cache = new InMemoryCache();

const getApolloClient = (initialState, headers, clientId) => new ApolloClient({
  link: createLink(headers, clientId),
  cache: cache.restore(initialState || {})
});

export {
  getApolloClient
};
