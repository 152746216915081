/**
 * Check if the automatic messages are equal
 * @param {Object} oldMessages - The old automatic messages
 * @param {Object} newMessages - The new automatic messages
 * @returns {boolean} - If the automatic messages are equal
 */
export function automaticMessagesAreEqual(oldMessages, newMessages) {
  const keys = ['WELCOME', 'FIRST_MESSAGE', 'OUT_WORKING_HOURS'];

  return keys.every((key) => {
    const oldMessage = oldMessages[key] || {};
    const newMessage = newMessages[key] || {};

    return (
      oldMessage.message === newMessage.message &&
      oldMessage.enabled === newMessage.enabled
    );
  });
}
