import React from 'react';
import PubSub from 'pubsub-js';
import { render } from '../../../../newRender';
import * as store from '../../../../store';

import ViewController from '../ViewController';

import * as useRedirect from '../hooks/useRedirect';
import * as useRouterMiddleware from '../hooks/useRouterMiddleware';

import * as viewUI from '../ViewUI';
import * as useViewModel from '../ViewModel';

/// import { __ } from '../../../../../../i18n';

describe('ViewController', () => {
  const props = {
    navigateWhenIsNotNetwork: jest.fn(),
    setRouteLeaveHook: jest.fn(),
  };

  beforeEach(() => {
    jest.clearAllMocks();

    jest.spyOn(useRedirect, 'useRedirect').mockReturnValue({
      navigateWhenIsNotNetwork: props.navigateWhenIsNotNetwork,
    });

    jest.spyOn(useRouterMiddleware, 'useRouterMiddleware').mockReturnValue({
      setRouteLeaveHook: props.setRouteLeaveHook,
    });

    jest.spyOn(useViewModel, 'ViewModel').mockReturnValue({
      isLoading: false,
      setIsLoading: jest.fn(),
      mfcReady: 'KATARA',
      setMfcReady: jest.fn(),
    });

    jest.spyOn(viewUI, 'ViewUI').mockImplementation(() => <div>ViewUI</div>);

    jest.spyOn(store, 'createStore').mockReturnValue({
      currentOrganization: {
        network: {
          id: '1',
        },
      },
      currentEntity: {
        id: '1',
      },
    });

    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
  });

  it('should be defined', () => {
    const { container } = render(<ViewController />);
    expect(container).toBeInTheDocument();
  });

  it('should call navigateWhenIsNotNetwork when useEffect is called', () => {
    render(<ViewController />);

    expect(props.navigateWhenIsNotNetwork).toHaveBeenCalledTimes(1);
  });

  it('should call useGoBackTrigger when useEffect is called', () => {
    render(<ViewController />);

    expect(props.setRouteLeaveHook).toHaveBeenCalledTimes(1);
  });
});
