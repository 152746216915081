import { act, renderHook } from '@testing-library/react-hooks/dom';
import { useRouterMiddleware } from '../useRouterMiddleware';

describe('given useRouterMiddleware hook', () => {
  let routerMock;
  let routeMock;

  beforeEach(() => {
    routerMock = {
      setRouteLeaveHook: jest.fn(),
    };
    routeMock = {};
    window.confirm = jest.fn();
  });

  it('should set a route leave hook', () => {
    const { result } = renderHook(() => useRouterMiddleware());

    act(() => {
      result.current.setRouteLeaveHook({
        router: routerMock,
        route: routeMock,
      });
    });

    expect(routerMock.setRouteLeaveHook).toHaveBeenCalledWith(
      routeMock,
      expect.any(Function),
    );
  });

  it('should call window.confirm with the correct message', () => {
    const { result } = renderHook(() => useRouterMiddleware());

    act(() => {
      result.current.setRouteLeaveHook({
        router: routerMock,
        route: routeMock,
      });
    });

    const hookFunction = routerMock.setRouteLeaveHook.mock.calls[0][1];

    window.confirm.mockReturnValueOnce(true);
    hookFunction();

    expect(window.confirm).toHaveBeenCalledWith(
      'Your message was not sent! Are you sure you want to leave?',
    );
  });

  it('should return true when window.confirm returns true', () => {
    const { result } = renderHook(() => useRouterMiddleware());

    act(() => {
      result.current.setRouteLeaveHook({
        router: routerMock,
        route: routeMock,
      });
    });

    const hookFunction = routerMock.setRouteLeaveHook.mock.calls[0][1];

    window.confirm.mockReturnValueOnce(true);
    const callReturn = hookFunction();

    expect(callReturn).toBe(true);
  });

  it('should return false when window.confirm returns false', () => {
    const { result } = renderHook(() => useRouterMiddleware());

    act(() => {
      result.current.setRouteLeaveHook({
        router: routerMock,
        route: routeMock,
      });
    });

    const hookFunction = routerMock.setRouteLeaveHook.mock.calls[0][1];

    window.confirm.mockReturnValueOnce(false);
    const callReturn = hookFunction();

    expect(callReturn).toBe(false);
  });
});
