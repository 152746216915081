import { deleteChannelPictureRepository } from '../../../Data/Repository/Channel/DeleteChannelPicture';

/**
* @param {import("../../../Data/Repository/Channel/DeleteChannelPicture").TVariables} variables
*/
export async function deleteChannelPictureUseCase(variables) {
  const { data, errors } = await deleteChannelPictureRepository(variables);

  return {
    data,
    errors
  };
}
