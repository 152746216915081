import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { removeIdsFromChannelRepository } from '../RemoveIdsFromChannel';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the removeIdsFromChannelRepository function', () => {
  it('should call Mutation the right amount of times', async () => {
    const entityId = 1;
    const removedIds = [
      1, 2, 3, 4
    ];

    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await removeIdsFromChannelRepository(entityId, removedIds);

    expect(apolloDataSource.Mutation).toHaveBeenCalledTimes(4);
  });
});
