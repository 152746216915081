import { hasConversationProductFeature } from './hasConversationProductFeature';

export function organizationShouldSeeConversation({
  currentOrganization,
  unleash,
  currentEntity,
  isMaster,
}) {
  if (
    !currentEntity ||
    !currentOrganization ||
    currentOrganization?.plan !== 'PREMIUM'
  ) {
    return false;
  }

  const hasUnleashToggle = unleash?.toggles?.some(
    (toggle) => toggle.name === 'web_menu_conversations',
  );

  const hasOrganizationUnleashToggle = unleash?.toggles?.some(
    (toggle) => toggle.name === 'menu_conversations_organization_direct_list',
  );

  const permissiosFilled =
    currentEntity?.organization?.permissions?.entityScopes?.filter(
      (permission) => !!permission,
    );

  const hasReadMessagesPermission = permissiosFilled?.some(
    (permission) => permission === 'READ_MESSAGE',
  );

  const entityIsWorker = currentEntity.type === 'ADMIN' || isMaster;

  if (!hasReadMessagesPermission && !entityIsWorker) {
    return false;
  }

  return (
    hasUnleashToggle &&
    hasOrganizationUnleashToggle &&
    hasConversationProductFeature(currentOrganization)
  );
}
