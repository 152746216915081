/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { Route, IndexRoute } from 'react-router';
import cookie from 'react-cookie';
import i18n from './i18n';
import * as utils from './utils';
import { defaultStore } from './store';

/**
 * Asynchronously load a file
 * @param main {String} - Main component
 * @returns {Function}
 */
function requireAsync(main) {
  return (nextState, next) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    next(null, require(`./containers/${main}`).default);
  };
}

function appPagesRequireAsync(main) {
  return (nextState, next) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    next(null, require(`./app/${main}`).default);
  };
}

function modulesPagesRequireAsync(main) {
  return (nextState, next) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    next(null, require(`./app/modules/${main}`).default);
  };
}

function requireAppModule(moduleName) {
  return (nextState, next) => {
    next(
      null,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`./app/Presentation/pages/${moduleName}/ViewController`).default,
    );
  };
}

/**
 * Require authorization to enter
 * @param nextState
 * @param replace {Function}
 * @returns {Function}
 */
const requireAuth = (store) => (nextState, replace) => {
  if (store.access_token) return;

  replace({
    pathname: '/auth',
    state: {
      nextPathname: encodeURIComponent(
        nextState.location.pathname + nextState.location.search,
      ),
    },
  });
};

/**
 * Set params from store
 * @returns {Function}
 */
const setParams = (store) => () => {
  i18n.locale = store.app.locale;
};

/**
 * Remove access_token from cookies and api and clean cookies
 * @param nextState
 * @param replace {Function}
 * @returns {Function}
 */
const logout = (store, client) => (nextState, replace) => {
  // Remove cookies
  const cookies = [
    'access_token',
    'refresh_token',
    'entity',
    'organization',
    'user',
    'csat_new_conversation_experience',
  ];

  cookies.forEach((c) => {
    const ck = cookie.load(c);

    if (ck) {
      cookie.remove(c, {
        path: '/',
        domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
      });
    }
  });

  // Clean store
  for (const i in store) {
    if (Object.prototype.hasOwnProperty.call(store, i)) {
      store[i] = ['app', 'popups', 'unleash'].includes(i)
        ? store[i]
        : defaultStore[i];
    }
  }

  client.resetStore();

  replace({
    pathname: '/auth',
    state: { nextPathname: nextState.location.pathname },
  });
};

/**
 * Check if its an old site link to redirect
 * @param nextState
 * @param replace {Function}
 * @returns {Function}
 */
const checkParams = () => (nextState, replace) => {
  if (!nextState.location.search) return;

  replace({
    pathname: '/auth',
    state: { nextPathname: nextState.location.pathname },
  });
};

/**
 * Redirect to notifications when access_token is set
 * @param nextState
 * @param replace {Function}
 * @returns {Function}
 */
const redirectAuth = (store, redirect) => (nextState, replace) => {
  if (!store.access_token) return;
  const { location } = nextState;

  if (location.query.next && location.query.next.startsWith('http')) {
    utils.redirectUrl(location.query.next);
  }

  replace({
    pathname: redirect || location.query.next || '/',
    state: {},
  });
};

/**
 * Routes are defined here. They are loaded asynchronously.
 * Paths are relative to the "containers" directory.
 * @param {Object}
 * @returns {Object}
 */
export default function createRoutes(store, client) {
  return (
    <Route getComponent={requireAsync('App')} onEnter={setParams(store)}>
      <Route getComponent={requireAsync('NotLoggedIn')} path="">
        <Route
          getComponent={requireAsync('ClassApp/TermsAndPrivacy')}
          path="terms"
        />
        <Route
          getComponent={requireAsync('ClassApp/TermsAndPrivacy')}
          path="privacy"
        />
        <Route
          getComponent={requireAsync('ClassApp/ArrivedTermsAndPrivacy')}
          path="terms/arrived"
        />
        <Route getComponent={requireAsync('ClassApp/About')} path="about" />
      </Route>

      <Route getComponent={requireAsync('Auth/Node')} path="auth">
        <IndexRoute
          getComponent={requireAsync('Auth/Prospect')}
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Auth/Login')}
          path="login"
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Auth/CodeChecker')}
          path="code"
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Auth/SignUp')}
          path="signup"
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Auth/CreatePassword')}
          path="createPassword"
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Auth/PasswordRecover')}
          path="passwordRecover"
        />
        <Route
          getComponent={requireAsync('Auth/InboundingSelector')}
          path="inboundingSelector"
          onEnter={redirectAuth(store)}
        />
        <Route path="logout" onEnter={logout(store, client)} />
      </Route>

      <Route getComponent={requireAsync('Simulation/Node')} path="simulation">
        <IndexRoute
          getComponent={requireAsync('Simulation/Prospect')}
          onEnter={redirectAuth(store)}
        />
        <Route
          getComponent={requireAsync('Simulation/CodeChecker')}
          path="code"
          onEnter={redirectAuth(store)}
        />
        <Route getComponent={requireAsync('Simulation/Finish')} path="finish" />
        <Route getComponent={requireAsync('Simulation/Terms')} path="terms" />
      </Route>

      <Route getComponent={requireAsync('Free/Node')} path="trial">
        <IndexRoute getComponent={requireAsync('Free/Free')} />
        <Route getComponent={requireAsync('Free/User')} path="user" />
        <Route getComponent={requireAsync('Free/Code')} path="code" />
        <Route getComponent={requireAsync('Free/Entities')} path="entities" />
        <Route
          getComponent={requireAsync('Free/Organization')}
          path="organization"
        />
        <Route getComponent={requireAsync('Free/Finish')} path="finish" />
        <Route getComponent={requireAsync('Free/Terms')} path="terms" />
      </Route>

      <Route
        getComponent={requireAsync('Auth/ThirdParty')}
        onEnter={requireAuth(store)}
        path="oauth/authorize"
      />

      <Route
        getComponent={requireAsync('CleanHome')}
        onEnter={requireAuth(store)}
      >
        {/* <Route getComponent={requireAsync('Prospect/ChooseOrganization')} path="prospect/chooseOrganization" /> */}
        <Route
          getComponent={requireAsync('Onboarding/Node')}
          path="start/:onboarding_id"
        >
          <Route
            getComponent={requireAsync('Onboarding/Groups')}
            path="groups"
          />
          <Route
            getComponent={requireAsync('Onboarding/Channels')}
            path="channels"
          />
          <Route
            getComponent={requireAsync('Onboarding/Invites')}
            path="invites"
          />
          <Route getComponent={requireAsync('Onboarding/Tips')} path="tips" />
        </Route>
      </Route>

      <Route getComponent={requireAsync('Home')} onEnter={requireAuth(store)}>
        <Route getComponent={requireAsync('User/Home')} path="/" />

        <Route getComponent={requireAsync('Admin/Node')} path="admin">
          <IndexRoute getComponent={requireAsync('Admin/Organizations')} />
          <Route
            getComponent={requireAsync('Admin/Organizations')}
            path="organizations"
          >
            <Route getComponent={requireAsync('Organization/Add')} path="add" />
          </Route>
          <Route getComponent={requireAsync('Admin/Clients')} path="clients" />
          <Route getComponent={requireAsync('Admin/Trial')} path="trial" />

          <Route
            getComponent={requireAsync('Admin/IntegrationsOld')}
            path="integrations_old"
          />

          <Route
            getComponent={requireAsync('Admin/Integrations')}
            path="integrations/organizations"
          >
            <IndexRoute getComponent={requireAsync('Admin/Integration/Node')} />
            <Route
              getComponent={requireAsync('Admin/Integration/Add')}
              path="add"
            />

            <Route
              getComponent={requireAsync('Admin/Integration/Organization/Node')}
              path=":organization_id"
            >
              <Route
                getComponent={requireAsync('Admin/Integration/Add')}
                path="add"
              />
              <Route
                getComponent={requireAsync('Admin/Module/Add')}
                path="module/add"
              />
              <Route
                getComponent={requireAsync('Admin/Contract/Add')}
                path="contract/add"
              />
              <Route
                getComponent={requireAsync('Admin/Contract/Edit')}
                path="contract/:contract_id/edit"
              />
              <Route
                getComponent={requireAsync('Admin/Module/Edit')}
                path="module/:module_id/edit"
              />
            </Route>
          </Route>

          <Route getComponent={requireAsync('Admin/Users')} path="users" />
          <Route
            getComponent={requireAsync('Admin/Settings')}
            path="settings"
          />
          <Route
            getComponent={requireAsync('Admin/Message')}
            path="message/add"
          />
        </Route>

        <Route
          getComponent={requireAsync('User/Applications')}
          path="applications"
        >
          <Route getComponent={requireAsync('Application/Add')} path="add" />
          <Route
            getComponent={requireAsync('Application/Node')}
            path=":application_id"
          >
            <Route
              getComponent={requireAsync('Application/Edit')}
              path="edit"
            />
            <Route
              getComponent={requireAsync('Application/Delete')}
              path="delete"
            />
          </Route>
        </Route>

        <Route getComponent={requireAsync('User/Node')} path="user">
          <IndexRoute getComponent={requireAsync('User/Account/Edit')} />
          <Route
            getComponent={requireAsync('User/Account/Edit')}
            path="account"
          />
          <Route
            getComponent={requireAsync('User/Password/Edit')}
            path="password"
          />
          <Route
            getComponent={requireAsync('User/Notifications/Edit')}
            path="notifications"
          />
          <Route getComponent={requireAsync('User/Payments')} path="payments">
            <Route
              getComponent={requireAsync('Card/Delete')}
              path=":card_id/delete"
            />
          </Route>
        </Route>

        <Route getComponent={requireAsync('Entity/Node')} path="e/:entity_id">
          <IndexRoute getComponent={requireAsync('Entity/Messages')} />
        </Route>
        <Route
          getComponent={requireAsync('Entity/Node')}
          path="entities/:entity_id"
        >
          <IndexRoute getComponent={requireAsync('Entity/Messages')} />

          {/* Sully Communication Routes */}
          <Route
            getComponent={modulesPagesRequireAsync(
              'Communications/ViewController',
            )}
            path="communications"
          >
            <Route
              getComponent={modulesPagesRequireAsync(
                'Communications/ViewController',
              )}
              path="*"
            />
          </Route>

          <Route getComponent={requireAsync('Entity/Messages')} path="messages">
            <Route getComponent={requireAsync('Message/Add')} path="add" />
            <Route
              getComponent={requireAsync('Message/Edit')}
              path=":message_id/edit"
            />
            <Route
              getComponent={requireAsync('Message/Node')}
              path=":message_id"
            >
              <Route
                getComponent={requireAsync('Message/Entities')}
                path="entities"
              />
              <Route
                getComponent={requireAsync('Message/Replies')}
                path="replies/:parent_id"
              />
              <Route getComponent={requireAsync('Reply/List')} path="replies" />
              <Route
                getComponent={requireAsync('Message/Delete')}
                path="delete"
              />
              <Route
                getComponent={requireAsync('Reply/Delete')}
                path="deleteReply"
              />
            </Route>
          </Route>
          <Route getComponent={requireAsync('Entity/Medias')} path="medias" />
          <Route
            getComponent={requireAsync('Entity/Calendar')}
            path="commitments"
          >
            <Route getComponent={requireAsync('Event/Add')} path="add" />
            <Route
              getComponent={requireAsync('Event/Edit')}
              path=":event_id/edit"
            />
            <Route
              getComponent={requireAsync('Event/Delete')}
              path=":event_id/delete"
            />
            <Route getComponent={requireAsync('Event/Node')} path=":event_id" />
          </Route>
          <Route
            getComponent={requireAsync('Entity/Accesses')}
            path="accesses"
          />
          <Route getComponent={requireAsync('Entity/Accounts')} path="accounts">
            <Route
              getComponent={requireAsync('Address/Delete')}
              path="addresses/:code/delete/:address_id"
            />
            <Route
              getComponent={requireAsync('Address/Delete')}
              path="addresses/delete/:address_id"
            />
            <Route
              getComponent={requireAsync('UserEntity/Delete')}
              path="users/:user_id/delete"
            />
          </Route>
          <Route getComponent={requireAsync('Entity/Edit')} path="edit" />
          <Route
            getComponent={requireAsync('Channel/Edit')}
            path="editchannel"
          />
          <Route
            getComponent={requireAsync('Entity/Picture')}
            path="edit/picture"
          />

          <Route
            getComponent={modulesPagesRequireAsync('Chats/Entity/List/ViewUI')}
            path="chats"
          >
            <Route
              getComponent={modulesPagesRequireAsync(
                'Chats/Entity/List/ViewUI',
              )}
              path="*"
            />
          </Route>
          <Route
            getComponent={modulesPagesRequireAsync(
              'Chats/Entity/Dashboard/ViewController',
            )}
            path="dashboard"
          />

          <Route
            getComponent={appPagesRequireAsync('Presentation/Chats/ViewUI')}
            path="chats"
          />
          {/* <Route
            getComponent={modulesPagesRequireAsync(
              'Dashboard/Entity/ViewController',
            )}
            path="dashboard"
          /> */}
          <Route
            getComponent={modulesPagesRequireAsync(
              'Chats/Entity/Dashboard/ViewController',
            )}
            path="dashboard"
          >
            <Route
              getComponent={modulesPagesRequireAsync(
                'Chats/Entity/Dashboard/ViewController',
              )}
              path="*"
            />
          </Route>
          <Route getComponent={requireAsync('Entity/Moments')} path="moments">
            <Route getComponent={requireAsync('Moment/Add')} path="add" />
            <Route
              getComponent={requireAsync('Moment/Edit')}
              path=":moment_id/edit"
            />
            <Route
              getComponent={requireAsync('Moment/Delete')}
              path=":moment_id/delete"
            />
          </Route>
          <Route
            getComponent={requireAsync('Moment/Node')}
            path="moments/:moment_id"
          />
        </Route>

        <Route
          getComponent={requireAsync('Organization/Node')}
          path="organizations/:organization_id"
        >
          <IndexRoute getComponent={requireAsync('Organization/Entities')} />
          <Route
            getComponent={appPagesRequireAsync('Presentation/Files/ViewUI')}
            path="files"
          />
          {/* <Route
            getComponent={requireAsync('Organization/Dashboard')}
            path="dashboard"
          /> */}
          <Route
            getComponent={modulesPagesRequireAsync(
              'Chats/Organization/Dashboard/ViewController',
            )}
            path="dashboard"
          >
            <Route
              getComponent={modulesPagesRequireAsync(
                'Chats/Organization/Dashboard/ViewController',
              )}
              path="*"
            />
          </Route>
          <Route
            getComponent={requireAsync('Organization/Entities')}
            path="entities"
          >
            <Route getComponent={requireAsync('Entity/Add')} path="add">
              <Route
                getComponent={requireAsync('Entity/Picture')}
                path="picture"
              />
            </Route>
            <Route getComponent={requireAsync('Entity/Export')} path="export" />
            <Route
              getComponent={requireAsync('Entity/Import')}
              path="import/student"
              type="student"
            />
            <Route
              getComponent={requireAsync('Entity/Import')}
              path="import/staff"
              type="staff"
            />
            <Route
              getComponent={requireAsync('Entity/Edit')}
              path=":entity_id/edit"
            />
            <Route
              getComponent={requireAsync('Entity/Delete')}
              path=":entity_id/delete"
            />
          </Route>
          <Route
            getComponent={requireAsync('Organization/ChannelsSelect')}
            path="channels"
          />
          <Route
            getComponent={requireAppModule('Channel/Add')}
            path="channels/add"
          />
          <Route
            getComponent={requireAppModule('Channel/Edit')}
            path="channels/:entity_id/edit"
          />

          <Route
            getComponent={requireAsync('Organization/Groups')}
            path="groups"
          >
            <Route getComponent={requireAsync('Group/Add')} path="add" />
            <Route
              getComponent={requireAsync('Group/Edit')}
              path=":group_id/edit"
            />
            <Route
              getComponent={requireAsync('Group/Delete')}
              path=":group_id/delete"
            />
            <Route
              getComponent={requireAsync('Role/Add')}
              path=":group_id/roles/add"
            />
          </Route>
          <Route
            getComponent={modulesPagesRequireAsync(
              'Chats/Organization/List/ViewController',
            )}
            path="chats"
          >
            <Route
              getComponent={modulesPagesRequireAsync(
                'Chats/Organization/List/ViewController',
              )}
              path="*"
            />
          </Route>
          <Route
            getComponent={requireAsync('Group/Node')}
            path="groups/:group_id"
          />
          <Route
            getComponent={requireAsync('Organization/Messages')}
            path="messages"
          >
            <Route getComponent={requireAsync('Message/Add')} path="add" />
            <Route
              getComponent={requireAsync('Message/Node')}
              path=":message_id"
            >
              <Route
                getComponent={requireAsync('Message/Entities')}
                path="entities"
              />
              <Route
                getComponent={requireAsync('Message/Replies')}
                path="replies/:parent_id"
              />
              <Route getComponent={requireAsync('Reply/List')} path="replies" />
              <Route
                getComponent={requireAsync('Message/Delete')}
                path="delete"
              />
              <Route
                getComponent={requireAsync('Reply/Delete')}
                path="deleteReply"
              />
            </Route>
          </Route>
          <Route
            getComponent={requireAsync('Organization/Reports')}
            path="reports"
          >
            <Route getComponent={requireAsync('Report/Add')} path="add" />
          </Route>
          <Route
            getComponent={requireAsync('Organization/Enrollment')}
            path="enrollment"
          />
          <Route getComponent={requireAsync('Organization/Charges')}>
            <Route
              getComponent={requireAsync('Charge/Payments')}
              path="payments"
            />
            <Route
              getComponent={requireAsync('Charge/Charges')}
              path="charges"
            />
            <Route
              getComponent={requireAsync('Charge/Accounts')}
              path="accounts"
            >
              <Route
                getComponent={requireAsync('Account/Statement')}
                path=":account_id/statement"
              />
            </Route>
          </Route>
          <Route
            getComponent={requireAsync('Organization/PaymentTerms')}
            path="paymentTerms"
          />
          <Route
            getComponent={requireAsync('Report/Node')}
            path="reports/:report_id"
          >
            <Route getComponent={requireAsync('Report/Edit')} path="edit" />
            <Route getComponent={requireAsync('Report/Delete')} path="delete" />
            <Route
              getComponent={requireAsync('ReportField/Delete')}
              path="fields/:field_id/delete"
            />
          </Route>
          <Route getComponent={requireAsync('Organization/Forms')} path="forms">
            <Route getComponent={requireAsync('Form/Add')} path="add" />
          </Route>
          <Route getComponent={requireAsync('Form/Node')} path="forms/:form_id">
            <Route getComponent={requireAsync('Form/Edit')} path="edit" />
            <Route getComponent={requireAsync('Form/Delete')} path="delete" />
            <Route
              getComponent={requireAsync('FormField/Delete')}
              path="fields/:field_id/delete"
            />
          </Route>
          <Route
            getComponent={requireAsync('Integration/Node')}
            path="integrations"
          />
          <Route
            getComponent={requireAsync('Integration/Edit')}
            path="integrations/:integration_id/edit"
          />
          <Route
            getComponent={requireAsync('Organization/Moments')}
            path="moments"
          >
            <Route
              getComponent={requireAsync('Moment/Delete')}
              path=":moment_id/delete"
            />
          </Route>
          <Route getComponent={requireAsync('Organization/Edit')} path="edit" />
          <Route
            getComponent={requireAsync('Organization/Dashboard')}
            path="dashboard"
          />
          <Route
            getComponent={requireAsync('Organization/Cover')}
            path="edit/cover"
          />
          <Route
            getComponent={requireAsync('Organization/Logo')}
            path="edit/logo"
          />
          <Route getComponent={requireAsync('Tag/Add')} path="edit/tags/add" />
          <Route
            getComponent={requireAsync('Tag/Edit')}
            path="edit/tags/:tag_id"
          />
          <Route
            getComponent={requireAsync('Tag/Delete')}
            path="edit/tags/:tag_id/delete"
          />
          <Route
            getComponent={requireAsync('Organization/Invitations')}
            path="invitations"
          />

          {/* External Access */}
          <Route
            getComponent={requireAsync('Organization/Accesses')}
            path="accesses"
          >
            <Route getComponent={requireAsync('Access/Add')} path="add" />
            <Route
              getComponent={requireAsync('Access/Edit')}
              path=":access_id/edit"
            />
            <Route
              getComponent={requireAsync('Access/Delete')}
              path=":access_id/delete"
            />
            {/* <Route getComponent={requireAsync('Role/Add')} path=":access_id/roles/add"/> */}
          </Route>
          <Route
            getComponent={requireAsync('Access/Node')}
            path="accesses/:access_id"
          />
          {/* <Route getComponent={requireAsync('Organization/Recurrence')} path="recurrence" /> */}
          <Route getComponent={requireAsync('Organization/Arrived')}>
            <Route
              getComponent={requireAsync('Arrived/Intro')}
              path="arrived/intro"
            />
            <Route
              getComponent={requireAsync('Arrived/History')}
              path="arrived/history"
            />
            <Route
              getComponent={requireAsync('Arrived/Settings')}
              path="arrived/settings"
            />
            <Route
              getComponent={requireAsync('Arrived/Dashboard')}
              path="arrived/data"
            />
          </Route>
        </Route>

        <Route getComponent={requireAsync('Dashboard/Node')} path="dashboard">
          <Route
            getComponent={requireAsync('Dashboard/General')}
            path=":organization_id"
          >
            <Route
              getComponent={requireAsync('Dashboard/Adhesion')}
              path="adhesion"
            />
            <Route
              getComponent={requireAsync('Dashboard/Interactions')}
              path="interactions"
            />
            <Route getComponent={requireAsync('Dashboard/Sent')} path="sent" />
            <Route
              getComponent={requireAsync('Dashboard/Received')}
              path="received"
            />
            <Route
              getComponent={requireAsync('Dashboard/Answers')}
              path="answers"
            />
          </Route>
        </Route>

        {store.access_token && (
          <Route
            getComponent={requireAsync('Organization/Node')}
            path=":username"
          >
            <IndexRoute getComponent={requireAsync('Organization/Entities')} />
          </Route>
        )}
      </Route>

      <Route getComponent={requireAsync('NotLoggedIn')} path="">
        <Route
          getComponent={requireAsync('User/Password/Recover')}
          path="user/password/recover/:code"
        />
        <Route
          getComponent={requireAsync('User/Confirm')}
          path="user/confirm/:code"
        />
        <Route
          getComponent={requireAsync('Message/Public')}
          path="messages/:message_id"
        />

        <Route getComponent={requireAsync('Onboarding/Node')} path="start">
          {/* <Route getComponent={requireAsync('Onboarding/Profile')} path="profile" /> */}
          <Route getComponent={requireAsync('Onboarding/User')} path="user" />
          <Route
            getComponent={requireAsync('Onboarding/Organization')}
            path="organization"
          />
        </Route>

        <Route
          getComponent={requireAsync('Organization/Public')}
          onEnter={checkParams()}
          path=":username"
        />
        <Route
          getComponent={requireAsync('Organization/Public')}
          onEnter={checkParams()}
          path=":username/notfound"
        />

        <Route getComponent={requireAsync('NotFound')} path="*" />
      </Route>
    </Route>
  );
}
