import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';
import * as getChannelListUseCase from '../../../../../Domain/UseCases/Channel/getChannelListUseCase';
import * as massUpdateEntityUseCase from '../../../../../Domain/UseCases/Channel/massUpdateEntityUseCase';
import * as enableProductFeatureUseCase from '../../../../../Domain/UseCases/Channel/enableProductFeatureUseCase';

describe('ViewModel', () => {
  const props = {
    currentEntityId: 10,
  };

  beforeEach(() => {
    jest.clearAllMocks();
  });

  describe('given the function "getChannelList"', async () => {
    it('should call "getChannelListUseCase"', async () => {
      jest.spyOn(getChannelListUseCase, 'getChannelListUseCase').mockResolvedValue({ data: {}, error: null });
      const variables = {
        entityId: '1',
        search: '',
        limit: 10,
        offset: 0,
      };

      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        await result.current.getChannelList(variables);
      });
      expect(getChannelListUseCase.getChannelListUseCase).toHaveBeenCalledWith(variables);
    });

    it('should return "getChannelListUseCase" response', async () => {
      jest.spyOn(getChannelListUseCase, 'getChannelListUseCase').mockResolvedValue({ data: {}, error: null });
      const variables = {
        entityId: '1',
        search: '',
        limit: 10,
        offset: 0,
      };

      let response;
      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        response = await result.current.getChannelList(variables);
      });
      expect(response).toEqual({ data: {}, error: null });
    });
  });

  describe('given the function "enableProductFeature"', async () => {
    it('should call "enableProductFeatureUseCase"', async () => {
      jest.spyOn(enableProductFeatureUseCase, 'enableProductFeatureUseCase').mockResolvedValue({ data: {}, error: null });
      const variables = { organizationId: 10 };

      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        await result.current.enableProductFeature(variables);
      });
      expect(enableProductFeatureUseCase.enableProductFeatureUseCase).toHaveBeenCalledWith(variables);
    });

    it('should return "enableProductFeatureUseCase" response', async () => {
      jest.spyOn(enableProductFeatureUseCase, 'enableProductFeatureUseCase').mockResolvedValue({ data: {}, error: null });
      const variables = { organizationId: 10 };

      let response;
      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        response = await result.current.enableProductFeature(variables);
      });
      expect(response).toEqual({ data: {}, error: null });
    });
  });

  describe('given the function "updateChannels"', async () => {
    it('should call "massUpdateEntityUseCase"', async () => {
      jest.spyOn(massUpdateEntityUseCase, 'massUpdateEntityUseCase').mockResolvedValue({ data: {}, error: null });

      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        await result.current.updateChannels();
      });
      expect(massUpdateEntityUseCase.massUpdateEntityUseCase).toHaveBeenCalled();
    });

    it('should return "massUpdateEntityUseCase" response', async () => {
      jest.spyOn(massUpdateEntityUseCase, 'massUpdateEntityUseCase').mockResolvedValue({ data: {}, error: null });

      let response;
      const { result } = renderHook(() => useViewModel(props));
      await act(async () => {
        response = await result.current.updateChannels();
      });
      expect(response).toEqual({ data: {}, error: null });
    });
  });

  it('should return initial state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.alertIsExpanded).toBe(true);
    expect(result.current.simpleChatNumber).toBe(0);
    expect(result.current.attendanceStatusNumber).toBe(0);
    expect(result.current.buttonLoading).toBe(false);
    expect(result.current.tableLoading).toBe(false);
    expect(result.current.channels).toEqual([]);
    expect(result.current.search).toBe('');
    expect(result.current.disableLoadMore).toBe(false);
  });

  it('should return "alertIsExpanded" state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.alertIsExpanded).toBe(true);
    act(() => {
      result.current.setAlertIsExpanded(false);
    });
    expect(result.current.alertIsExpanded).toBe(false);
  });

  it('should return "buttonLoading" state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.buttonLoading).toBe(false);
    act(() => {
      result.current.setButtonLoading(true);
    });
    expect(result.current.buttonLoading).toBe(true);
  });

  it('should return "tableLoading" state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.tableLoading).toBe(false);
    act(() => {
      result.current.setTableLoading(true);
    });
    expect(result.current.tableLoading).toBe(true);
  });

  it('should return "channels" state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.channels).toEqual([]);
    act(() => {
      result.current.setChannels([{ id: 1 }]);
    });
    expect(result.current.channels).toEqual([{ id: 1 }]);
  });

  it('should return "search" state', () => {
    const { result } = renderHook(() => useViewModel(props));
    expect(result.current.search).toBe('');
    act(() => {
      result.current.setSearch('search');
    });
    expect(result.current.search).toBe('search');
  });
});
