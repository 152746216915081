import React from 'react';
import { screen, render } from '@testing-library/react';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@classapp-tech/edna';
import { themes } from '@classapp-tech/edna-styles';
import ViewUI from '../ViewUI';
import { __ } from '../../../../i18n';
import { createStore } from '../../../../../../test/helpers/store';

const store = createStore();
const props = {
  store,
  api: {}
};

describe('Chats news ViewUI', () => {
  describe('render', () => {
    it('should render header title', () => {
      render(

        <Provider store={store}>
          <ThemeProvider theme={themes.classapp.classappLight}>
            <ViewUI {...props} onClose={() => {}} />
          </ThemeProvider>
        </Provider>
      );
      const title = screen.getByText(__('Chats: a new way of interacting with families 💙'));
      expect(title).toBeVisible();
    });
    it('should render descriptions', () => {
      render(

        <Provider store={store}>
          <ThemeProvider theme={themes.classapp.classappLight}>
            <ViewUI {...props} onClose={() => {}} />
          </ThemeProvider>
        </Provider>
      );
      const desc1 = screen.getByText(__('The new feature updates the way families get in touch with the school\'s departments and teams.'));
      const desc2 = screen.getByText(__('The chat will have the structure of a real-time chat and will bring more data about services to school management.'));
      expect(desc1).toBeVisible();
      expect(desc2).toBeVisible();
    });
    it('should render link', () => {
      render(

        <Provider store={store}>
          <ThemeProvider theme={themes.classapp.classappLight}>
            <ViewUI {...props} onClose={() => {}} />
          </ThemeProvider>
        </Provider>
      );
      const link = screen.getByText(__('Learn more'));
      expect(link).toBeVisible();
    });
    it('should render footer description', () => {
      render(

        <Provider store={store}>
          <ThemeProvider theme={themes.classapp.classappLight}>
            <ViewUI {...props} onClose={() => {}} />
          </ThemeProvider>
        </Provider>
      );
      const desc = screen.getByText(__('Configure the service channels and provide more management and agility in meeting the demands of the school community.'));
      expect(desc).toBeVisible();
    });
  });
});
