import { createOrUpdateChannelAutomaticMessagesRepository } from '../../../Data/Repository/AutomaticMessage/CreateOrUpdateChannelAutomaticMessages';
import { canSeeAutomaticMessagesUseCase } from '../../UseCases/AutomaticMessage/CanSeeAutomaticMessagesUseCase';

/**
 * @param {import("../../../Data/Repository/AutomaticMessage/CreateOrUpdateChannelAutomaticMessages").TVariables} variables
 */
export async function createOrUpdateChannelAutomaticMessagesUseCase(variables) {
  const canSeeFeature = await canSeeAutomaticMessagesUseCase();
  if (!canSeeFeature) {
    return { data: null, errors: ['Feature not enabled'] };
  }

  const { data, errors } =
    await createOrUpdateChannelAutomaticMessagesRepository(variables);

  return {
    data,
    errors,
  };
}
