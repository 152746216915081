import { getVisibilityTypeUseCase } from '../GetVisibilityType';

describe('given getVisibilityType use case', () => {
  const variables = {
    seeAll: true
  };

  it('should be able to return empty array when param is not provided', () => {
    const result = getVisibilityTypeUseCase(null);

    expect(result).toEqual('custom');
  });

  it('should be able to return the selected users formatted', () => {
    const result = getVisibilityTypeUseCase(variables);

    expect(result).toEqual('public');
  });
});
