import { enableProductFeatureUseCase } from '../enableProductFeatureUseCase';
import * as Repository from '../../../../Data/Repository/Channel/enableProductFeatureRepository';

describe('given the "enableProductFeatureUseCase" function', () => {
  beforeEach(() => {
    jest.spyOn(Repository, 'enableProductFeatureRepository').mockImplementation(() => ({
      data: {
        enableProductFeatureLowTouch: {
          enableSuccess: true,
        }
      },
      error: 'custom error',
    }));
  });

  it('should call the "enableProductFeatureRepository" function with the same params', async () => {
    const variables = { id: 1 };

    await enableProductFeatureUseCase(variables);

    expect(Repository.enableProductFeatureRepository).toHaveBeenCalledWith(variables);
  });

  it('should return the "enableProductFeatureRepository" response', async () => {
    const variables = { id: 1 };

    const response = await enableProductFeatureUseCase(variables);

    expect(response).toEqual({ data: { enableProductFeatureLowTouch: { enableSuccess: true } }, error: 'custom error' });
  });
});
