import gql from 'graphql-tag';
import { getApplicationsRepository } from '../GetApplications';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('query'));

describe('given the "getApplicationsRepository" function', () => {
    beforeEach(() => {
        jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
            data: dataMock,
        }));
    });
    const dataMock = {
        applications: [
            {
                id: "123",
                name: "Duplicate Document Number Prevention",
                redirectUri: "https://link.de.ajuda.com"
            }
        ]
    }
    const variables = {
        search: "Duplicate Document Number Prevention"
    };

    it('should call the "Query" function with the correct params', async () => {
        await getApplicationsRepository(variables);

        expect(DataSource.Query).toHaveBeenCalledWith('query', variables);
    });

    it('should return Query response', async () => {
        const response = await getApplicationsRepository(variables);

        expect(response).toEqual({ data: dataMock });
    });

    it('should call the "gql" function', async () => {
        await getApplicationsRepository(variables);

        expect(gql).toHaveBeenCalled();
    });
});
