import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';
import * as getOrganizationUnleashUseCase from '../../../../../Domain/UseCases/Unleash/GetOrganizationUnleashUseCase';

jest.mock('react-cookie', () => ({
  load: jest.fn(),
}));

describe('should test organization dashboard view model', () => {
  jest
    .spyOn(getOrganizationUnleashUseCase, 'getOrganizationUnleashUseCase')
    .mockImplementation(() => ({
      data: {
        node: {
          unleashStatus: {
            menu_conversations_organization_dashboard: true,
            web_menu_conversations: true,
            web_conversations_automatic_messages: true,
          },
        },
      },
    }));

  it('should return false when setIsLoading is called', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.isLoading).toBe(true);

    await act(async () => {
      await result.current.setIsLoading(false);
    });

    expect(result.current.isLoading).toBe(false);
  });

  it('should return false when setHasOrganizationDashboard is called', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.hasOrganizationDashboard).toBe(true);

    await act(async () => {
      await result.current.setHasOrganizationDashboard(false);
    });

    expect(result.current.hasOrganizationDashboard).toBe(false);
  });

  it('should return false when not has conversation feature', async () => {
    const { result } = renderHook(() => useViewModel());
    const organizationStore = {
      productFeatures: {
        nodes: [
          {
            identifier: 'anotherFeature',
          },
        ],
      },
    };

    await act(async () => {
      const __hasConversationFeature = result.current.hasConversationFeature(
        false,
        organizationStore,
      );

      expect(__hasConversationFeature).toBe(false);
    });
  });

  it('should return true when has conversation feature', async () => {
    const { result } = renderHook(() => useViewModel());
    const organizationStore = {
      productFeatures: {
        nodes: [
          {
            identifier: 'attendanceChannel',
          },
        ],
      },
    };

    await act(async () => {
      const __hasConversationFeature = result.current.hasConversationFeature(
        true,
        organizationStore,
      );

      expect(__hasConversationFeature).toBe(true);
    });
  });

  it('should call organization unleash features', async () => {
    const { result } = renderHook(() => useViewModel());
    await act(async () => {
      result.current.getOrganizationUnleashFeature(1);
    });

    expect(
      getOrganizationUnleashUseCase.getOrganizationUnleashUseCase,
    ).toBeCalledWith(1);
    expect(result.current.isLoading).toBe(false);
  });
});
