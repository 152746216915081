import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';

jest.mock('react-cookie', () => ({
  load: jest.fn(),
}));

describe('should test organization dashboard view model', () => {
  it('should return false when setIsLoading is called', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.isLoading).toBe(true);

    await act(async () => {
      await result.current.setIsLoading(false);
    });

    expect(result.current.isLoading).toBe(false);
  });

  it('should return false when setHasChannelDashboard is called', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.hasChannelDashboard).toBe(true);

    await act(async () => {
      await result.current.setHasChannelDashboard(false);
    });

    expect(result.current.hasChannelDashboard).toBe(false);
  });
});
