import { isNetwork } from '../../../helpers/isNetwork';
/**
 * @param {{
 *  router: unknown,
 *  store: {
 *    unleash: {
 *      isReady: boolean,
 *      getStatus: (status: string) => boolean
 *    }
 *    currentOrganization: {
 *      network: {
 *        id: string;
 *      }
 *    }
 *    currentEntity: {
 *      id: string;
 *    }
 *  }
 * }} props
 */
export function useRedirect() {
  function navigateWhenIsNotNetwork({ router, store }) {
    const hasNetworkFeatureAndUnleash = isNetwork(store?.currentOrganization);

    const entityId = store?.currentEntity?.id;

    if (!hasNetworkFeatureAndUnleash) {
      router.push(`/entities/${entityId}/messages`);
    }
  }

  return {
    navigateWhenIsNotNetwork,
  };
}
