import * as AutomaticMessagesTableMock from '../../Presentation/pages/Channel/components/AutomaticMessages/constants/default-automatic-messages';
import { getDefaultAutomaticMessages } from '../getDefaultAutomaticMessages';

describe('given getDefaultAutomaticMessages helper', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    jest
      .spyOn(AutomaticMessagesTableMock, 'DefaultAutomaticMessages')
      .mockReturnValue([
        { type: 'type1', message: 'default1' },
        { type: 'type2', message: 'default2' },
      ]);
  });

  it('should be able to return default automatic messages', () => {
    const result = getDefaultAutomaticMessages();

    expect(result).toEqual([
      { type: 'type1', message: 'default1' },
      { type: 'type2', message: 'default2' },
    ]);
  });

  it('should be able to return the nonexistent automatic messages with new messages if new messsages does not have it', () => {
    const newMessages = [{ type: 'type2', message: 'custom message' }];
    const result = getDefaultAutomaticMessages(newMessages);

    expect(result).toEqual([
      { type: 'type1', message: 'default1' },
      { type: 'type2', message: 'custom message' },
    ]);
  });
});
