import React from 'react';
import PubSub from 'pubsub-js';

import { render, screen } from '../../../../../../newRender';
import ViewUI from '../ViewUI';
import { __ } from '../../../../../../i18n';
import * as useViewModel from '../ViewModel';
import * as analytics from '../../../../../../lib/analytics';
import * as store from '../../../../../../store';

const handleUpdateChatsReadStatus = jest.fn();

describe('Chats ViewUI', () => {
  let props = {
    router: {
      push: jest.fn(),
    },
  };

  beforeEach(() => {
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
    jest
      .spyOn(analytics, 'sendGoogleAnalyticsEvent')
      .mockImplementation(() => {});
    jest
      .spyOn(store, 'createStore')
      .mockImplementation(() => ({ entity: { type: 'student' } }));

    jest.spyOn(useViewModel, 'ViewModel').mockReturnValue({
      handleUpdateChatsReadStatus,
      mfcReady: 'KATARA',
      setMfcReady: jest.fn(),
    });
  });

  it('should render header title', () => {
    render(<ViewUI {...props} />);
    const title = screen.getByText(__('Chats'));
    expect(title).toBeVisible();
  });

  it('should subscribe to UPDATE_HOST_DIRECT_MESSAGE_READ_STATUS', () => {
    render(<ViewUI {...props} />);
    expect(PubSub.subscribe).toHaveBeenCalledWith(
      'UPDATE_HOST_DIRECT_MESSAGE_READ_STATUS',
      expect.any(Function),
    );
  });

  it('should call handleUpdateChatsReadStatus', () => {
    render(<ViewUI {...props} />);
    expect(handleUpdateChatsReadStatus).toHaveBeenCalled();
  });

  it('should call analytics.sendGoogleAnalyticsEvent', () => {
    render(<ViewUI {...props} />);
    expect(analytics.sendGoogleAnalyticsEvent).toHaveBeenCalled();
  });

  it('should subscribe to SEND_FORWARD_MESSAGE_TO_PERSON', () => {
    render(<ViewUI {...props} />);
    expect(PubSub.subscribe).toHaveBeenCalledWith(
      'SEND_FORWARD_MESSAGE_TO_PERSON',
      expect.any(Function),
    );
  });

  it('should be able to publish event if currentEntity changes', () => {
    jest.spyOn(PubSub, 'publish').mockImplementation(() => {});
    const { rerender } = render(<ViewUI {...props} />);

    jest
      .spyOn(store, 'createStore')
      .mockImplementation(() => ({ entity: { type: 'student', id: 2 } }));

    rerender(<ViewUI {...props} />);

    expect(PubSub.publish).toHaveBeenCalledWith(
      'undefined_MICROFRONTEND_DATA',
      {
        entity: { type: 'student' },
        host: undefined,
        organization: undefined,
        user: undefined,
      },
    );
  });
});
