import { massUpdateEntityUseCase } from '../massUpdateEntityUseCase';
import * as Repository from '../../../../Data/Repository/Channel/massUpdateEntityRepository';

describe('given the "massUpdateEntityUseCase" function', () => {
  beforeEach(() => {
    jest.spyOn(Repository, 'massUpdateEntityRepository').mockImplementation(() => ({
      data: { updateSuccess: true },
      error: 'custom error',
    }));
  });

  it('should call the "massUpdateEntityRepository" function with the same params', async () => {
    const variables = { id: 1 };

    await massUpdateEntityUseCase(variables);

    expect(Repository.massUpdateEntityRepository).toHaveBeenCalledWith(variables);
  });

  it('should return the "massUpdateEntityRepository" response', async () => {
    const variables = { id: 1 };

    const response = await massUpdateEntityUseCase(variables);

    expect(response).toEqual({ data: { updateSuccess: true }, error: 'custom error' });
  });
});
