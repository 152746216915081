import moment from 'moment';

/**
  * @typedef {{
  *  time?: string;
* }} TVariables
**/

/**
* @param {TVariables} variables
*/
export function validateTimeUseCase(variables) {
  if (!variables) return null;

  const { time } = variables;

  if (time && time.trim() !== '') {
    return moment(time, 'HH:mm:00').format('HH:mm');
  }

  return null;
}
