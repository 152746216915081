import { massUpdateEntityRepository } from '../../../Data/Repository/Channel/massUpdateEntityRepository';

/**
 * @param {import("../../../Data/Repository/Channel/massUpdateEntityRepository").TVariables} variables
 * @returns {import("../../../Data/Repository/Channel/massUpdateEntityRepository").TResponse}
 */
export async function massUpdateEntityUseCase(variables) {
  const { data, error } = await massUpdateEntityRepository(variables);
  return { data, error };
}
