/**
 * @param {{
 *    id: number,
 *    unleashStatus: {
 *      custom_media_button: boolean
 *      custom_media: boolean
 *    }
 * }} organization
 */
export function hasCustomFileButton(organization) {
  if (!organization) return false;

  const hasCustomFileFeature = organization?.unleashStatus?.custom_media;
  const hasCustomFileButton = organization?.unleashStatus?.custom_media_button;

  return hasCustomFileFeature && hasCustomFileButton;
}
