import React from 'react';
import PubSub from 'pubsub-js';

import { render } from '../../../../../../newRender';
import { ViewUI } from '../ViewUI';
import * as store from '../../../../../../store';

describe('Dashboard ViewUI', () => {
  let props;

  beforeEach(() => {
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
    jest.spyOn(store, 'createStore').mockImplementation(() => ({
      entity: {
        type: 'student',
      },
    }));

    props = {
      params: {
        entity_id: '123',
      },
      location: {
        query: {
          limit: 30,
        },
      },
      hasOrganizationDashboard: true,
      isLoading: false,
    };
  });

  it('should render loading component', () => {
    props.isLoading = true;
    const { getByTestId } = render(<ViewUI {...props} />);

    const loading = getByTestId('loading-container');

    expect(loading).toBeInTheDocument();
  });

  it('should render tab list when loading is false', () => {
    const { getByTestId } = render(<ViewUI {...props} />);

    const tabs = getByTestId('tabs-component');

    expect(tabs).toBeInTheDocument();
  });

  it('should not render conversation tab when hasOrganizationDashboard is false', () => {
    props.hasOrganizationDashboard = false;
    const { queryByText } = render(<ViewUI {...props} />);

    const tabs = queryByText('conversations-tab');

    expect(tabs).toBeNull();
  });

  it('should render conversation tab when hasOrganizationDashboard is true', () => {
    const { getByTestId } = render(<ViewUI {...props} />);

    const tabs = getByTestId('conversations-tab');

    expect(tabs).toBeInTheDocument();
  });
});
