import React from 'react';
import PubSub from 'pubsub-js';

import { render } from '../../../../newRender';
import { ViewUI } from '../ViewUI';
import * as store from '../../../../store';

describe('Communications -> ViewUI', () => {
  let props;

  beforeEach(() => {
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
    jest.spyOn(store, 'createStore').mockImplementation(() => ({
      entity: {
        type: 'student',
      },
    }));

    props = {
      env: 'production',
      isLoading: false,
    };
  });

  it('should render loading', () => {
    props.isLoading = true;
    const { getByTestId } = render(<ViewUI {...props} />);

    expect(getByTestId('loading-component')).toBeVisible();
  });

  it('should render screen', () => {
    const { container } = render(<ViewUI {...props} />);

    expect(container).not.toBeEmpty();
  });
});
