import { createOrganizationPaymentsReport } from '../../../Data/Repository/ChargePaymentRepository/mutations/createOrganizationPaymentsReport';
import { __ } from '../../../i18n';

export function createOrganizationPaymentsReportUseCase() {
  return {
    /**
     * @param {any} client
     * @param {(active: boolean, message: string, success: boolean, dismissAfter: number) => void} setSnackbar
     * @param {{
     *  email: string,
     *  filters: {
     *   organizationId: string | number,
     *   locale?: string,
     *   tzOffset: number,
     *   initialDate: string,
     *   finalDate: string,
     *   methods?: [string],
     *   status?: [string],
     *   text?: string,
     *   search?: string
     *  }
     * }} input
     */
    async execute(client, setSnackbar, input) {
      try {
        /**
         * @type {{
         *  data: {
         *   createOrganizationPaymentsReport: {
         *    classpayReport: {
         *     success: boolean
         *    }
         *   }
         *  }
         * }}
         */
        const createPaymentReportMutation = await client.mutate({
          mutation: createOrganizationPaymentsReport,
          variables: {
            createOrganizationPaymentsReportMutation: {
              ...input
            }
          }
        });

        const { success } = createPaymentReportMutation.data.createOrganizationPaymentsReport.classpayReport;
        setSnackbar(true, success ? __('Export successful, wait a few minutes and check your email!') : __('There was an error while exporting. Try again later!'), success, 3000);
      } catch (e) {
        console.error('Error creating payment export request: ', e);
        setSnackbar(true, __('There was an error while exporting. Try again later!'), false, 3000);
      }
    }
  };
}
