import { enableProductFeatureRepository } from '../../../Data/Repository/Channel/enableProductFeatureRepository';

/**
 * @param {import("../../../Data/Repository/Channel/enableProductFeatureRepository").TVariables} variables
 * @returns {import("../../../Data/Repository/Channel/enableProductFeatureRepository").TResponse}
 */
export async function enableProductFeatureUseCase(variables) {
  const { data, error } = await enableProductFeatureRepository(variables);
  return { data, error };
}
