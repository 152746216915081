import * as ApolloDataSource from '../../../../Data/DataSource/ApolloDataSource';
import { getChannelUseCase } from '../GetChannel';
import { getChannelUseCaseMock } from '../__mocks__/channelUseCase.mock';

describe('given getChannelUseCase use case', () => {
  const mocks = {
    entityId: 1,
  };

  beforeAll(() => {
    jest.spyOn(ApolloDataSource, 'Query').mockImplementation(async () => getChannelUseCaseMock);
  });

  it('should be able to get an channel node', async () => {
    const result = await getChannelUseCase(mocks);
    expect(result?.data?.id).not.toBeFalsy();
  });
});
