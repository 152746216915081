import React, { useEffect } from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';
import PubSub from 'pubsub-js';

import config from '../../../../../../../config/server.config';
import { createUrlWithHash, asset } from '../../../../../utils';
import Loading from '../../../../../components/ui/Loading';
import { Container, Header, Title, Content } from './ViewStyles';
import { __ } from '../../../../../i18n';
import { ViewModel as useViewModel } from './ViewModel';
import * as analytics from '../../../../../lib/analytics';
import { createStore } from '../../../../../store';
import { clearAllSubscriptions } from '../../../../helpers/clearAllSubscriptions';

import { Fallback } from '../../../../../components/kit/Fallback';

export default function ViewUI(props) {
  const { handleUpdateChatsReadStatus, mfcReady, setMfcReady } = useViewModel();
  const store = createStore();
  const env = store?.app?.env;

  const { router } = props;

  useEffect(() => {
    PubSub.subscribe('STORE_UPDATED', (_, data) => {
      if (mfcReady) {
        const organization = data?.currentOrganization;
        const entity = data?.entity;
        const user = data?.currentUser;
        const currentEntity = data?.currentEntity;
        const host = data?.app?.host;

        PubSub.publish(`${mfcReady}_MICROFRONTEND_DATA`, {
          organization,
          entity,
          user,
          currentEntity,
          host,
        });
      }
    });

    return () => {
      PubSub.unsubscribe('STORE_UPDATED');
    };
  }, [mfcReady]);

  useEffect(() => {
    PubSub.subscribeOnce('MICROFRONTEND_READY', (_, mcfName) => {
      const organization = store?.currentOrganization;
      const entity = store?.entity;
      const currentEntity = store?.currentEntity;
      const user = store?.currentUser;
      const host = store?.app?.host;

      setMfcReady(mcfName);
      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        currentEntity,
        user,
        host,
      });
    });

    PubSub.subscribeOnce('UPDATE_HOST_DIRECT_MESSAGE_READ_STATUS', () => {
      handleUpdateChatsReadStatus(store);
    });

    PubSub.subscribeOnce('SEND_FORWARD_MESSAGE_TO_PERSON', () => {
      router.push(`/entities/${store?.currentEntity?.id}/messages/add`);
    });

    return () => {
      PubSub.publish('DISCONNECT_FLASH');
      clearAllSubscriptions();
      PubSub.publish('REMOVE_SEARCH_ENTITY');
    };
  }, []);

  analytics.sendGoogleAnalyticsEvent(
    {
      name: 'channels_chat_feature',
      category: `open_chat_feature_${store?.app?.env}`,
      label: store?.entity?.type,
    },
    { store },
  );

  return (
    <Container>
      <Header>
        <Title>{__('Chats')}</Title>
      </Header>
      <Content>
        <RemoteMountedComponent
          url={createUrlWithHash(
            `${config.microfrontends.katara[env]}/remoteEntry.js`,
          )}
          name="katara"
          moduleName="./mountMethods"
          rootRoute="/chats"
          fallbackComponent={
            <Fallback
              title={__('Connection error')}
              subtitle={__('Try again later')}
              src={asset('/images/no-connection.png')}
              alt="No connection image"
            />
          }
          loadingComponent={<Loading width={30} height={30} />}
        />
      </Content>
    </Container>
  );
}
