import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { deleteChannelPictureRepository } from '../DeleteChannelPicture';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the deleteChannelPictureRepository function', () => {
  const variables = {
    entityId: '1780'
  };
  it('should call Mutation with the correct params', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await deleteChannelPictureRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      deletePictureMutation: {
        ...variables
      }
    });
  });

  it('should call use default values in Mutation function', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Mutation').mockImplementation(() => mutationMock);

    await deleteChannelPictureRepository(variables);

    expect(apolloDataSource.Mutation).toHaveBeenCalledWith(undefined, {
      deletePictureMutation: {
        ...variables
      }
    });
  });

  it('should return data and errors', async () => {
    const response = { data: 'data', errors: 'errors' };

    jest.spyOn(apolloDataSource, 'Mutation').mockResolvedValue(response);

    const result = await deleteChannelPictureRepository({
      deletePictureMutation: {
        ...variables
      }
    });

    expect(result).toEqual(response);
  });
});
