import { useState } from 'react';
import { uniqBy } from 'lodash';

/**
 * @typedef {{
 *  id: number | string;
 *  confAttendanceEnabled: boolean;
 * }} Channel
 */

export function useConfigureChannels() {
  const [savedChannels, setSavedChannels] = useState([]);

  /**
   * @param {Channel[]} newChannels
   */
  function appendChannels(newChannels) {
    setSavedChannels((prevChannels) => uniqBy([...prevChannels, ...newChannels], 'id'));
  }

  /**
   * @param {Channel} input
   */
  function setChannel(id, value) {
    setSavedChannels((prev) => {
      const newData = prev.map((channel) => {
        if (channel.id === id) {
          return {
            ...channel,
            confAttendanceEnabled: value === 'WITH_SERVICE_STATUS',
          };
        }
        return channel;
      });
      return newData;
    });
  }

  return {
    savedChannels,
    setSavedChannels,
    appendChannels,
    setChannel,
  };
}
