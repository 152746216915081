import cookie from 'react-cookie';
import { shouldShowChatNewsModal } from '../ViewModel';

jest.mock('react-cookie', () => ({
  load: jest.fn(),
}));

describe('shouldShowChatNewsModal', () => {
  it('should return false if currentEntity or currentOrganization are not defined', () => {
    const store = {};
    expect(shouldShowChatNewsModal(store)).toBe(false);
  });

  it('should return false if currentEntity.type is not ADMIN', () => {
    const store = {
      currentEntity: { type: 'USER' },
      currentOrganization: {},
    };
    expect(shouldShowChatNewsModal(store)).toBe(false);
  });

  it('should return false if unleashStatus.web_menu_conversations is not defined', () => {
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {},
    };
    expect(shouldShowChatNewsModal(store)).toBe(false);
  });

  it('should return true if productFeatures does not contain attendanceChannel', () => {
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {
        unleashStatus: { web_menu_conversations: true },
        productFeatures: { nodes: [] },
      },
    };
    expect(shouldShowChatNewsModal(store)).toBe(true);
  });
  it('should return false if productFeatures contain attendanceChannel', () => {
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {
        unleashStatus: { web_menu_conversations: true },
        productFeatures: { nodes: [{ identifier: 'attendanceChannel', isEnabled: true }] },
      },
    };
    expect(shouldShowChatNewsModal(store)).toBe(false);
  });

  it('should return true if lastSeenChatNews cookie is not set and product feature is false', () => {
    cookie.load.mockReturnValue(null);
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {
        unleashStatus: { web_menu_conversations: true },
        productFeatures: { nodes: [] },
      },
    };
    expect(shouldShowChatNewsModal(store)).toBe(true);
  });

  it('should return true if lastSeenChatNews cookie is older than 7 days', () => {
    const date = new Date();
    date.setDate(date.getDate() - 8);
    cookie.load.mockReturnValue(date.toString());
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {
        unleashStatus: { web_menu_conversations: true },
        productFeatures: { nodes: [] },
      },
    };
    expect(shouldShowChatNewsModal(store)).toBe(true);
  });

  it('should return false if lastSeenChatNews cookie is less than 7 days old', () => {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    cookie.load.mockReturnValue(date.toString());
    const store = {
      currentEntity: { type: 'ADMIN' },
      currentOrganization: {
        unleashStatus: { web_menu_conversations: true },
        productFeatures: { nodes: [{ identifier: 'attendanceChannel', isEnabled: true }] },
      },
    };
    expect(shouldShowChatNewsModal(store)).toBe(false);
  });
});
