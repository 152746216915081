/* eslint-disable prettier/prettier */
/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';
import { canSeeAutomaticMessagesUseCase } from '../../../Domain/UseCases/AutomaticMessage/CanSeeAutomaticMessagesUseCase'; 

/**
 * @typedef {{
 *   entityId: number;
 * }} TVariables
 **/

/**
 * @param {TVariables} variables
 */
export async function getChannelRepository({ entityId }) {
  const includeAutomaticMessages = canSeeAutomaticMessagesUseCase();

  const query = gql`
    query ChannelEditQuery($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          id: dbId
          fullname
          eid
          seeAll
          description
          disabled
          type
          ntfStartTime
          ntfEndTime
          confAttendanceEnabled
          policy
          scope
          picture {
            uri
            id: dbId
            key
          }
          users {
            nodes {
              id: dbId
              fullname
              phone
              email
              confirmPhone
              confirmEmail
              tags {
                nodes {
                  id: dbId
                  name
                }
              }
            }
          }
          addresses {
            nodes {
              address
              type
              userId
            }
          }
          roles(limit: 400) {
            nodes {
              id: dbId
              name
            }
          }
          ${
  includeAutomaticMessages
    ? `
          automaticMessages {
            nodes {
              message
              type
              enabled
            }
          }
          `
    : ''
}
        }
      }
    }
  `;
  const { data, errors } = await Query(query, {
    entityId,
  });

  return {
    data,
    errors,
  };
}
