import * as apolloDataSource from '../../../DataSource/ApolloDataSource';
import { getOrganizationUnleashRepository } from '../GetOrganizationUnleashRepository';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the getOrganizationUnleashRepository function', () => {
  const variables = {
    id: '1123',
  };
  it('should call Query with the correct params', async () => {
    const queryMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => queryMock);

    await getOrganizationUnleashRepository(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(undefined, {
      ...variables,
    });
  });

  it('should call use default values in Mutation function', async () => {
    const queryMock = jest.fn();
    jest.spyOn(apolloDataSource, 'Query').mockImplementation(() => queryMock);

    await getOrganizationUnleashRepository(variables);

    expect(apolloDataSource.Query).toHaveBeenCalledWith(undefined, {
      ...variables,
    });
  });

  it('should return data and errors', async () => {
    const response = { data: 'data', errors: 'errors' };

    jest.spyOn(apolloDataSource, 'Query').mockResolvedValue(response);

    const result = await getOrganizationUnleashRepository({
      ...variables,
    });

    expect(result).toEqual(response);
  });
});
