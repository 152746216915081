import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@graphql(gql`mutation deleteUserEntity($deleteUserEntityMutation: DeleteUserEntityInput!) {
  deleteUserEntity(input: $deleteUserEntityMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['EntityAccountsQuery']
  }
})
@inject('store') @observer
export default class UserEntityDelete extends Controller {
  request = () => {
    this.setState({ loading: true });

    const { store, router, params } = this.props;

    return this.props.mutate({
      variables: {
        deleteUserEntityMutation: {
          userId: params.user_id,
          entityId: params.entity_id
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('User unlinked successfully'), success: true };
      router.goBack();

      this.setState({ loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Controller
        id="UserEntityDelete"
        modal={{ size: 'small' }}
        delete
        userEntity
        object={__('user')}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...this.props}
      />
    );
  }
}
