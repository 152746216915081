import React from 'react';
import { render } from '../../../../../../newRender';
import { ViewUI } from '../ViewUI';

import * as store from '../../../../../../store';
describe('ViewUI', () => {
  const props = {
    router: {
      push: jest.fn(),
    },
    store: {
      currentOrganization: {
        id: 1,
      },
    },
  };

  it('should be defined', () => {
    spyOn(store, 'createStore').and.returnValue({
      currentOrganization: {
        id: 1,
      },
    });
    const { container } = render(<ViewUI {...props} />);
    expect(container).toBeInTheDocument();
  });
});

/* describe('ViewUI', () => {
  const store = createStore();

  beforeAll(() => {
    store.currentOrganization = {
      ...store.currentOrganization,
      id: 1,
    };
  });

  it('should render', () => {
    const { getByText } = render(<ViewUI />);
    expect(getByText('Configure your Channels')).toBeInTheDocument();
  });
});
 */
