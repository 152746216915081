import React, { useState } from 'react';
import { uniqueId, uniqBy } from 'lodash';
import { getChannelListUseCase } from '../../../../Domain/UseCases/Channel/getChannelListUseCase';
import { massUpdateEntityUseCase } from '../../../../Domain/UseCases/Channel/massUpdateEntityUseCase';
import { useConfigureChannels } from './hooks/useConfigureChannels';
import { RadioList } from '../components/RadioList';
import { enableProductFeatureUseCase } from '../../../../Domain/UseCases/Channel/enableProductFeatureUseCase';
import Avatar from '../../../../../components/Avatar';
import { __ } from '../../../../../i18n';

export function ViewModel() {
  const {
    savedChannels, setSavedChannels, appendChannels, setChannel
  } = useConfigureChannels();

  const statusOptions = {
    SIMPLE_CHAT: {
      value: 'SIMPLE_CONVERSATION',
      text: __('Simple conversation')
    },
    ATTENDANCE_STATUS: {
      value: 'WITH_SERVICE_STATUS',
      text: __('With service status'),
    }
  };

  const [alertIsExpanded, setAlertIsExpanded] = useState(true);
  const [simpleChatNumber, setSimpleChatNumber] = useState(0);
  const [attendanceStatusNumber, setAttendanceStatusNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [channels, setChannels] = useState([]);
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const limit = 5;

  /**
   * @param {import('../../../../Data/Repository/Channel/getChannelListRepository').TVariables} variables
   * @returns {import('../../../../Data/Repository/Channel/getChannelListRepository').TResponse}
   */
  async function getChannelList(variables) {
    return getChannelListUseCase(variables);
  }

  /**
   * @param {import('../../../../Data/Repository/Channel/massUpdateEntityRepository').TVariables} variables
   * @returns {import('../../../../Data/Repository/Channel/massUpdateEntityRepository').TResponse}
   */
  async function updateChannels() {
    return massUpdateEntityUseCase(savedChannels);
  }

  /**
   * @param {import('../../../../Data/Repository/Channel/enableProductFeatureRepository').TVariables} variables
   * @returns {import('../../../../Data/Repository/Channel/enableProductFeatureRepository').TResponse}
   */
  async function enableProductFeature(variables) {
    return enableProductFeatureUseCase(variables);
  }

  const formatData = (data) => {
    const filteredRecipients = data.node.entities.nodes.map((recipient) => ({
      id: recipient.id,
      content: recipient.fullname,
      picture: recipient.picture,
      confAttendanceEnabled: recipient.confAttendanceEnabled,
    }));

    const filteredForSearchResult = filteredRecipients.map((item) => {
      const foundLoadedChannel = savedChannels.find(channel => channel.id === item.id);
      if (foundLoadedChannel) {
        item.confAttendanceEnabled = foundLoadedChannel.confAttendanceEnabled;
      }
      return item;
    });

    const formattedData = filteredForSearchResult.map(item => ({
      id: uniqueId(),
      columns: [
        {
          id: uniqueId(),
          icon: <Avatar avatar src={item.picture?.uri} alt={item.content} />,
          content: item.content,
        },
        {
          id: uniqueId(),
          content: (
            <RadioList
              selectedFilter={item.confAttendanceEnabled ? statusOptions.ATTENDANCE_STATUS.value : statusOptions.SIMPLE_CHAT.value}
              options={[
                {
                  id: item.id,
                  label: {
                    text: statusOptions.SIMPLE_CHAT.text,
                  },
                  value: statusOptions.SIMPLE_CHAT.value,
                },
                {
                  id: item.id,
                  label: {
                    text: statusOptions.ATTENDANCE_STATUS.text,
                  },
                  value: statusOptions.ATTENDANCE_STATUS.value,
                },

              ]}
              onSelect={(option, id) => setChannel(id, option)}
            />
          ),
        }
      ]
    }));

    const formattedSavedChannels = filteredForSearchResult.map(item => ({
      id: item.id,
      confAttendanceEnabled: item.confAttendanceEnabled,
    }));

    return { formattedData, formattedSavedChannels };
  };

  const getChannels = async ({ organizationId }) => {
    setTableLoading(true);

    try {
      const { data, error } = await getChannelList({
        organizationId,
        limit,
      });

      if (error) throw new Error(error);

      const { formattedData, formattedSavedChannels } = formatData(data);

      setSavedChannels((prev) => uniqBy([...prev, ...formattedSavedChannels], 'id'));
      setOffset(data.node.entities.nodes.length);
      setDisableLoadMore(!data.node.entities.pageInfo.hasNextPage);
      setTotalCount(data.node.entities.totalCount);
      setChannels(formattedData);
    } catch (error) {
      console.error('Erro while getting channels: ', error);
    } finally {
      setTableLoading(false);
    }
  };

  const loadSearched = async ({ organizationId }) => {
    setTableLoading(true);

    try {
      const { data, error } = await getChannelList({
        organizationId,
        search,
      });

      if (error) throw new Error(error);

      const { formattedData, formattedSavedChannels } = formatData(data);

      setSavedChannels((prev) => uniqBy([...prev, ...formattedSavedChannels], 'id'));
      setDisableLoadMore(!data.node.entities.pageInfo.hasNextPage);
      setChannels(formattedData);
    } catch (error) {
      console.error('Error while searching for channel: ', error);
    } finally {
      setTableLoading(false);
    }
  };

  const loadMoreChannels = async ({ organizationId }) => {
    setLoadMoreLoading(true);

    try {
      const { data, error } = await getChannelList({
        organizationId,
        limit,
        offset,
      });

      if (error) throw new Error(error);

      const { formattedData, formattedSavedChannels } = formatData(data);

      appendChannels(formattedSavedChannels);
      setOffset((prev) => prev + data.node.entities.nodes.length);
      setDisableLoadMore(!data.node.entities.pageInfo.hasNextPage);
      setChannels((prev) => [...prev, ...formattedData]);
    } catch (error) {
      console.error('Error while loading more channels: ', error);
    } finally {
      setLoadMoreLoading(false);
    }
  };

  return {
    loadSearched,
    getChannels,
    getChannelList,
    updateChannels,
    alertIsExpanded,
    setAlertIsExpanded,
    simpleChatNumber,
    attendanceStatusNumber,
    buttonLoading,
    setButtonLoading,
    channels,
    setChannels,
    tableLoading,
    setTableLoading,
    search,
    setSimpleChatNumber,
    setSearch,
    loadMoreChannels,
    setAttendanceStatusNumber,
    savedChannels,
    loadMoreLoading,
    enableProductFeature,
    disableLoadMore,
    totalCount
  };
}
