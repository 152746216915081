export const getChannelUseCaseMock = {
  data: {
    node: {
      id: 1,
      fullname: 'Secretaria',
      eid: null,
      seeAll: true,
      description: null,
      disabled: false,
      type: 'STAFF',
      ntfStartTime: null,
      ntfEndTime: null,
      policy: null,
      scope: null,
      picture: null,
      users: {
        nodes: [
          {
            id: 83,
            fullname: 'Staff Without Arrived 2',
            phone: null,
            email: 'i+staffwithoutarrived2@classapp.com.br',
            confirmPhone: null,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          },
          {
            id: 7,
            fullname: 'Vinicius Silva',
            phone: null,
            email: 'vinicius.silva@classapp.com.br',
            confirmPhone: false,
            confirmEmail: false,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection'
            },
            __typename: 'User'
          }
        ],
        __typename: 'EntityUsersConnection'
      },
      addresses: {
        nodes: [
          {
            address: '12675922218',
            type: 'PHONE',
            userId: null,
            __typename: 'Address'
          },
          {
            address: '5519900000007',
            type: 'PHONE',
            userId: null,
            __typename: 'Address'
          },
          {
            address: 'vinicius.silva@classapp.com.br',
            type: 'EMAIL',
            userId: 7,
            __typename: 'Address'
          }
        ],
        __typename: 'EntityAddressesConnection'
      },
      roles: {
        nodes: [
          {
            id: 45,
            name: 'Staffs access and intern',
            __typename: 'Group'
          }
        ],
        __typename: 'EntityRolesConnection'
      },
      __typename: 'Entity'
    }
  }
};

export const createChannelUseCaseMock = {
  data: {
    node: {
      id: 398,
      fullname: 'Secretaria',
      eid: null,
      seeAll: true,
      description: null,
      disabled: false,
      type: 'STAFF',
      ntfStartTime: null,
      ntfEndTime: null,
      policy: null,
      scope: null,
      picture: null
    }
  }
};

export const updateChannelUseCaseMock = {
  data: {
    node: {
      id: 398,
      fullname: 'Secretaria',
      eid: null,
      seeAll: true,
      description: null,
      disabled: false,
      type: 'STAFF',
      ntfStartTime: null,
      ntfEndTime: null,
      policy: null,
      scope: null,
      picture: null
    }
  }
};

export const uploadChannelPictureUseCaseMock = {
  data: {
    clientMutationId: 'd678sad86dsaddsd7s5dsads65as'
  }
};
