/**
  * @typedef {{
  *  id?: number;
  *  name?: string;
  * }} TTag
**/

/**
  * @typedef {{
    *  id?: number;
    *  fullname?: string;
    *  phone?: string;
    *  email?: string;
    *  confirmPhone?: boolean;
    *  confirmEmail?: boolean;
    *  tags?: TTag[];
  * }} TUser
**/

/**
* @param {TUser[]} users
*/

export function getSelectedUsersUseCase(users) {
  let originalUsers = [];
  const originalUsersConstructor = users || [];
  originalUsersConstructor.map(user => ['phone', 'email'].map(address => originalUsers.push({
    address: user[address], tags: user.tags && user.tags.nodes && user.tags.nodes.map(t => ({ id: t.id, name: t.name })), type: address.toUpperCase(), user
  })));

  originalUsers = originalUsers.filter(item => item.address);
  originalUsers = originalUsers.map(i => ({ ...i, original: true }));

  return originalUsers;
}
