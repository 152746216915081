import * as ApolloDataSource from '../../../../Data/DataSource/ApolloDataSource';
import { deleteChannelPictureUseCase } from '../DeleteChannelPicture';
import { uploadChannelPictureUseCaseMock } from '../__mocks__/channelUseCase.mock';

describe('given deleteChannelPictureUseCase use case', () => {
  const mocks = {
    entityId: 1
  };

  beforeAll(() => {
    jest.spyOn(ApolloDataSource, 'Mutation').mockImplementation(async () => uploadChannelPictureUseCaseMock);
  });

  it('should be able to delete channel picture by id', async () => {
    const result = await deleteChannelPictureUseCase(mocks);
    expect(result?.data?.clientMutationId).not.toBeFalsy();
  });
});
