/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';
/**
  * @typedef {{
  *   organizationId: number | string;
  * }} TVariables
  **/

/**
 * @typedef {Promise<{
 *  data: {
 *    enableProductFeatureLowTouch: {
 *      enableSuccess: true;
 *    }
 *   } | null,
 *  error?: string
 * }>} TResponse
 */

/**
 * @param {TVariables[]} variables
 * @returns {TResponse}
 */
export async function enableProductFeatureRepository(variables) {
  const mutation = gql`
    mutation updateProductFeature($input: enableProductFeatureLowTouchInput!) {
      enableProductFeatureLowTouch(input: $input) {
          enableSuccess
      }
    }
  `;

  const input = {
    input: {
      ...variables,
      identifier: 'attendanceChannel',
    },
  };

  const { data, error } = await Mutation(mutation, input);
  return { data, error };
}
