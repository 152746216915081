import { convertGroupsToInputUseCase } from '../ConvertGroupsToInput';

describe('given convertGroupsToInputUseCase use case', () => {
  const mocks = {
    selectedGroups: [
      {
        id: 1,
        name: 'Group A',
      },
      {
        id: 2,
        name: 'Group B',
      },
      {
        id: 3,
        name: 'Group C',
      }
    ]
  };

  it('should be able to return empty result when param is not provided', () => {
    const result = convertGroupsToInputUseCase(null);

    expect(result).toEqual([]);
  });

  it('should be able to return the selected users formatted', () => {
    const result = convertGroupsToInputUseCase(mocks.selectedGroups);

    expect(result).toEqual([{ id: 1, name: 'Group A', type: null }, { id: 2, name: 'Group B', type: null }, { id: 3, name: 'Group C', type: null }]);
  });
});
