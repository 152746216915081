import { hasCommunicationStatusFeedback } from '../hasCommunicationStatusFeedback';

describe('hasCommunicationStatusFeedback', () => {
  it('should return false when the organization does not have communication_status_feedback', () => {
    const organization = {
      unleashStatus: {
        communication_status_feedback: false,
      },
    };
    expect(hasCommunicationStatusFeedback(organization)).toBe(false);
  });

  it('should return true when organization is a network with communication status feedback', () => {
    const organization = {
      id: '1',
      network: {
        id: '1',
      },
      unleashStatus: {
        network_communication: true,
        communication_status_feedback: true,
      },
    };

    const result = hasCommunicationStatusFeedback(organization);
    expect(result).toBe(true);
  });
});
