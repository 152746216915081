import imageToBase64 from 'image-to-base64/browser';
import { b64toBlob, asset } from '../../../../../utils';
import { convertImageToBlobUseCase } from '../ConvertImageToBlob';

// Mock das funções externas
jest.mock('image-to-base64/browser');
jest.mock('../../../../../utils');

describe('convertImageToBlobUseCase', () => {
  it('should return base64 image when iconName is provided', async () => {
    const mockBase64 = 'base64String';
    imageToBase64.mockResolvedValue(mockBase64);

    const mockAssetPath = '/icons/icon.png';
    asset.mockReturnValue(mockAssetPath);

    const mockBlob = new Blob();
    b64toBlob.mockReturnValue(mockBlob);

    const iconName = 'icon';
    const result = await convertImageToBlobUseCase(iconName);

    expect(imageToBase64).toHaveBeenCalledWith(mockAssetPath);
    expect(b64toBlob).toHaveBeenCalledWith(mockBase64, 'image/png');

    expect(result).toBe(mockBlob);
  });

  it('should return null when iconName is not provided', async () => {
    const result = await convertImageToBlobUseCase();
    expect(result).toBeNull();
  });
});
