import React from 'react';
import PubSub from 'pubsub-js';

import { render } from '../../../../../../../newRender';
import { ViewUI } from '../ViewUI';
import * as store from '../../../../../../../store';

describe('Dashboard Attendance ViewUI', () => {
  beforeEach(() => {
    jest.spyOn(PubSub, 'subscribe').mockImplementation((event, callback) => {
      callback();
    });
    jest.spyOn(store, 'createStore').mockImplementation(() => ({
      entity: {
        type: 'student',
      },
    }));
  });

  it('should render screen', () => {
    const { container } = render(<ViewUI />);

    expect(container).not.toBeEmpty();
  });
});
