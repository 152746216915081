import { hasCustomFileButton } from '../hasCustomFileButton';

describe('hasCustomFileButton', () => {
  let organization;

  beforeEach(() => {
    jest.clearAllMocks();

    organization = {
      id: 1,
      unleashStatus: {
        custom_media_button: true,
        custom_media: true,
      },
    };
  });

  it('should return false when organization is not provided', () => {
    expect(hasCustomFileButton()).toBe(false);
  });

  it('should return true when organization has custom media feature and button', () => {
    expect(hasCustomFileButton(organization)).toBe(true);
  });

  it('should return false when organization has feature and not has button', () => {
    organization.unleashStatus.custom_media_button = false;
    expect(hasCustomFileButton(organization)).toBe(false);
  });

  it('should return false when organization has not feature and has button', () => {
    organization.unleashStatus.custom_media = false;
    expect(hasCustomFileButton(organization)).toBe(false);
  });
});
