/**
 * @param {{
 *    id: number,
 *    network: {
 *      id: number
 *    },
 *    unleashStatus: {
 *      network_communication: boolean
 *    }
 * }} organization
 */
export function isNetworkChild(organization) {
  if (!organization) return false;

  const isChildOrganization =
    !!organization?.network?.id &&
    Number(organization?.id) !== Number(organization?.network?.id);

  const hasNetworkUnleash = organization?.unleashStatus?.network_communication;

  return isChildOrganization && hasNetworkUnleash;
}
