/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import userEvent from '@testing-library/user-event';
import { render } from '@testing-library/react';
import { Provider } from 'mobx-react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ThemeProvider } from '@classapp-tech/edna';
import { themes } from '@classapp-tech/edna-styles';
import fetch from 'unfetch';

import { createStore } from './store';

import { client_id } from './api';
import possibleTypes from './possibleTypes.json';

const store = createStore();

const cache = new InMemoryCache({ possibleTypes });
const currentTimezone = -(new Date().getTimezoneOffset());

// Apollo http link
const httpLink = createHttpLink({
  uri: `${store.app.url}/graphql?client_id=${client_id}&tz_offset=${currentTimezone}&locale=${store.app.locale}`,
  fetch,
});

// Apollo format request link
const middlewareLink = setContext(() => ({
  headers: {
    authorization: store.access_token ? `Bearer ${store.access_token}` : null
  }
}));

// Apollo error link
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.error(graphQLErrors[0]);
  }
});

const link = ApolloLink.from([
  errorLink,
  middlewareLink,
  httpLink,
]);

export const client = new ApolloClient({
  link,
  cache
});

function AllTheProviders({ children }) {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={themes.classapp.classappLight}>
          {children}
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
}

const customRender = (
  ui,
  options,
) => render(ui, { wrapper: AllTheProviders, ...options });

export * from '@testing-library/react';
export { userEvent };
export { customRender as render };
