/**
 * Format the unleash status into a toggle object
 * @param {Record<string,boolean>} unleashStatus
 * @returns {Array<{
 *  name: string,
 *  enabled: boolean
 * }>}
 */
export function formatUnleashStatusIntoToggle(unleashStatus) {
  if (!unleashStatus || Object.keys(unleashStatus).length === 0) {
    return [];
  }

  const removeFalsyValues = Object.entries(unleashStatus).filter(
    ([, enabled]) => enabled,
  );

  return removeFalsyValues.map(([name, enabled]) => ({
    name,
    enabled,
  }));
}
