import { __ } from '../../../../i18n';

export const useRouterMiddleware = () => {
  function setRouteLeaveHook({ router, route }) {
    router.setRouteLeaveHook(route, () => {
      return window.confirm(
        __('Your message was not sent! Are you sure you want to leave?'),
      );
    });
  }

  return {
    setRouteLeaveHook,
  };
};
