import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';

import Modal from '../../components/Modal';

import TagForm from '../Tag/Form';

@inject('store')
@graphql(gql`query UserEntityTagAddQuery ($id: ID!) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        tags (limit: 40) {
          nodes {
            id: dbId
            name
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.store.currentOrganization.id
    }
  })
})
@graphql(gql`mutation createUserEntityTag($createUserEntityTagMutation: CreateUserEntityTagInput!) {
  createUserEntityTag(input: $createUserEntityTagMutation) {
    clientMutationId
  }
}`, {
  name: 'createUserEntityTag',
  options: {
    refetchQueries: ['EntityAccountsQuery']
  }
})
@graphql(gql`mutation createTag($createTagMutation: CreateTagInput!) {
  createTag(input: $createTagMutation) {
    clientMutationId
    tag {
      id:dbId
    }
  }
}`, {
  name: 'createTag',
  options: {
    refetchQueries: ['UserEntityTagAddQuery']
  }
})
@observer
export default class UserEntityTagAdd extends React.Component {
  state = {
    selectedTag: null
  }

  onTagAdd = (tag) => {
    const { store, createTag } = this.props;

    store.appends.pop();

    if (!(tag && tag.name)) return;

    createTag({
      variables: {
        createTagMutation: {
          organizationId: store.currentEntity.organization.id,
          ...tag
        }
      }
    }).then((data) => {
      const newTag = data.data.createTag.tag;

      this.onSelectionChange(null, { value: newTag.id });
      store.snackbar = { active: true, message: __('New tag created successfully'), success: true };
    });
  }

  onSelectionChange = (e, { value }) => {
    this.setState({ selectedTag: value });
  }

  postUserEntityTag = () => {
    const { entity_id, user, store, createUserEntityTag } = this.props;
    const tag_id = this.state.selectedTag;

    createUserEntityTag({
      variables: {
        createUserEntityTagMutation: {
          userId: user.id,
          entityId: entity_id,
          tagId: tag_id
        }
      }
    }).then(() => {
      store.appends.pop();
      store.snackbar = { active: true, message: __('Tag added successfully'), success: true };
    });
  }

  render() {
    const { data, store, user } = this.props;
    const { selectedTag } = this.state;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { tags } = data.node;

    let options = tags.nodes.map(tag => ({ text: tag.name, value: tag.id }));

    if (user && user.tags && user.tags.nodes) {
      const selectedTags = user.tags.nodes.map(tag => tag.id);
      options = options.filter(tag => selectedTags.indexOf(tag.value) === -1);
    }

    return (
      <Modal open onClose={() => store.appends.pop()}>
        <Modal.Header>{__('Add tag')}</Modal.Header>
        <Modal.Content>
          <Dropdown
            label={__('Tags')}
            placeholder={__('Search') + '...'}
            noResultsMessage={__('No results were found')}
            fluid search selection
            value={selectedTag}
            allowAdditions={store.currentUser.isMaster}
            onAddItem={(e, dt) => store.appends.push(
              <Modal
                onClose={() => store.appends.pop()}
                size="small"
                actions
                header={__('Create new tag')}
                content={
                  <TagForm
                    modal={{ closeOnRootNodeClick: false }}
                    values={{ name: dt.value }}
                    onSubmit={this.onTagAdd}
                    submitButton={__('Add')}
                  />
                }
              />
            )}
            options={options}
            onChange={this.onSelectionChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button data-action="cancel" floated="left" basic onClick={() => store.appends.pop()} content={__('Cancel')} />
          <Button data-action="submit" primary content={__('Add tag')} disabled={!selectedTag} onClick={this.postUserEntityTag} />
        </Modal.Actions>
      </Modal>
    );
  }
}
