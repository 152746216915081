import { useState } from 'react';

export function ViewModel() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasChannelDashboard, setHasChannelDashboard] = useState(true);

  return {
    isLoading,
    hasChannelDashboard,
    setIsLoading,
    setHasChannelDashboard
  };
}
