import * as ApolloDataSource from '../../../../Data/DataSource/ApolloDataSource';
import { removeIdsFromChannelUseCase } from '../RemoveIdsFromChannel';

describe('given removeIdsFromChannelUseCase use case', () => {
  const removedIds = [
    1, 2
  ];

  beforeAll(() => {
    jest.spyOn(ApolloDataSource, 'Mutation').mockImplementation(async () => ({ data: 'success', errors: null }));
  });

  it('should be able to call remove ids mutation', async () => {
    const result = await removeIdsFromChannelUseCase(1, removedIds);
    expect(result).toEqual({ data: ['success', 'success'], errors: [] });
  });
});
