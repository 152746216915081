import mobx from 'mobx';
import cookie from 'react-cookie';
import { browserHistory } from 'react-router';

const requireAuth = (store) => {
  const access_token = cookie.load('access_token');

  if (!access_token && store.access_token) {
    browserHistory.push('/auth/logout');
  }
};

// Required for client side title and description change, you can add more
//-----------
export default store => mobx.autorun(() => {
  const { app, currentOrganization, currentUser, currentEntity } = store;
  const notifications = currentUser && currentUser.notifications && currentUser.notifications.totalCount ?
    '(' + currentUser.notifications.totalCount + ') ' : '';
  document.lang = app.locale;
  document.title = (app.title) ? (notifications + app.title + ' · ' + app.name) : notifications + app.name;
  document.querySelector("meta[name='description']").content = app.description;

  if (currentOrganization && currentOrganization.color) {
    document.getElementById('theme').innerHTML = `.themed { background: #${currentOrganization.color} !important; color: white !important; }`;
  }

  requireAuth(store);
});
