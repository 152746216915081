// @ts-nocheck
/* eslint no-underscore-dangle: 0*/
/* eslint global-require: 0*/

import moment from 'moment';

const sprintf = require('sprintf-js').sprintf;

const i18n = {};

export const locale = moment.locale() || 'en';
i18n.locale = locale;

export const translations = {
  en: require('../../locales/en.json'),
  de: require('../../locales/de.json'),
  fr: require('../../locales/fr.json'),
  ru: require('../../locales/ru.json'),
  zh: require('../../locales/zh.json'),
  af: require('../../locales/af.json'),
  sq: require('../../locales/sq.json'),
  am: require('../../locales/am.json'),
  ar: require('../../locales/ar.json'),
  hy: require('../../locales/hy.json'),
  az: require('../../locales/az.json'),
  eu: require('../../locales/eu.json'),
  es: require('../../locales/es.json'),
  be: require('../../locales/be.json'),
  bn: require('../../locales/bn.json'),
  bs: require('../../locales/bs.json'),
  bg: require('../../locales/bg.json'),
  my: require('../../locales/my.json'),
  ca: require('../../locales/ca.json'),
  km: require('../../locales/km.json'),
  zn: require('../../locales/zn.json'),
  ny: require('../../locales/ny.json'),
  co: require('../../locales/co.json'),
  hr: require('../../locales/hr.json'),
  cs: require('../../locales/cs.json'),
  da: require('../../locales/da.json'),
  nl: require('../../locales/nl.json'),
  eo: require('../../locales/eo.json'),
  et: require('../../locales/et.json'),
  fi: require('../../locales/fi.json'),
  gd: require('../../locales/gd.json'),
  gl: require('../../locales/gl.json'),
  ka: require('../../locales/ka.json'),
  el: require('../../locales/el.json'),
  gu: require('../../locales/gu.json'),
  ht: require('../../locales/ht.json'),
  ha: require('../../locales/ha.json'),
  he: require('../../locales/he.json'),
  hi: require('../../locales/hi.json'),
  hu: require('../../locales/hu.json'),
  is: require('../../locales/is.json'),
  ig: require('../../locales/ig.json'),
  id: require('../../locales/id.json'),
  ga: require('../../locales/ga.json'),
  it: require('../../locales/it.json'),
  ja: require('../../locales/ja.json'),
  kn: require('../../locales/kn.json'),
  kk: require('../../locales/kk.json'),
  ky: require('../../locales/ky.json'),
  ko: require('../../locales/ko.json'),
  ku: require('../../locales/ku.json'),
  lo: require('../../locales/lo.json'),
  la: require('../../locales/la.json'),
  lv: require('../../locales/lv.json'),
  lt: require('../../locales/lt.json'),
  lb: require('../../locales/lb.json'),
  mk: require('../../locales/mk.json'),
  mg: require('../../locales/mg.json'),
  ms: require('../../locales/ms.json'),
  ml: require('../../locales/ml.json'),
  mt: require('../../locales/mt.json'),
  mi: require('../../locales/mi.json'),
  mr: require('../../locales/mr.json'),
  mn: require('../../locales/mn.json'),
  ne: require('../../locales/ne.json'),
  no: require('../../locales/no.json'),
  nb: require('../../locales/nb.json'),
  nn: require('../../locales/nn.json'),
  or: require('../../locales/or.json'),
  fa: require('../../locales/fa.json'),
  pl: require('../../locales/pl.json'),
  pa: require('../../locales/pa.json'),
  ps: require('../../locales/ps.json'),
  pt: require('../../locales/pt.json'),
  ro: require('../../locales/ro.json'),
  sm: require('../../locales/sm.json'),
  sr: require('../../locales/sr.json'),
  sn: require('../../locales/sn.json'),
  sd: require('../../locales/sd.json'),
  si: require('../../locales/si.json'),
  sk: require('../../locales/sk.json'),
  sl: require('../../locales/sl.json'),
  so: require('../../locales/so.json'),
  st: require('../../locales/st.json'),
  su: require('../../locales/su.json'),
  sw: require('../../locales/sw.json'),
  sv: require('../../locales/sv.json'),
  tl: require('../../locales/tl.json'),
  tg: require('../../locales/tg.json'),
  ta: require('../../locales/ta.json'),
  te: require('../../locales/te.json'),
  th: require('../../locales/th.json'),
  tr: require('../../locales/tr.json'),
  uk: require('../../locales/uk.json'),
  ur: require('../../locales/ur.json'),
  uz: require('../../locales/uz.json'),
  vi: require('../../locales/vi.json'),
  cy: require('../../locales/cy.json'),
  fy: require('../../locales/fy.json'),
  xh: require('../../locales/xh.json'),
  yi: require('../../locales/yi.json'),
  yo: require('../../locales/yo.json'),
  zu: require('../../locales/zu.json'),
};
i18n.translations = translations;

export const languages = [
  { text: 'Português', value: 'pt' },
  { text: 'English', value: 'en' },
  { text: 'Español', value: 'es' },
  { text: '日本語', value: 'ja' },
  { text: '简体中文', value: 'zh' }
  //{ text: 'Português [PT]', value: 'pt_PT' }
];
i18n.languages = languages;

export const __ = function (str, ...other) {
  const currentLocale = i18n.currentLocale();

  if (i18n.translations[currentLocale][str]) {
    return sprintf(i18n.translations[currentLocale][str], ...other);
  }
  return sprintf(str, ...other);
};
i18n.__ = __;

export const currentLocale = function () {
  let locale = i18n.locale;
  const locales = Object.keys(i18n.translations);
  if (!locale || typeof locale !== 'string') return locales[0];

  if (locale.length > 2) {
    locale = locale.substring(0, 2);
  }

  if (locales.indexOf(locale) === -1) {
    return locales[0];
  }

  return locale;
};
i18n.currentLocale = currentLocale;


export const toISO6392 = (l) => {
  const langs = {
    de: 'deu',
    fi: 'fin',
    fr: 'fra',
    hr: 'hrv',
    it: 'ita',
    ja: 'jpn',
    nl: 'nld',
    pt: 'por',
    ru: 'rus',
    es: 'spa'
  };
  return langs[l];
};
i18n.toISO6392 = toISO6392;

export default i18n;
