import React, { useEffect } from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';
// import Cookies from 'js-cookie';
import PubSub from 'pubsub-js';

import config from '../../../../../../../../config/server.config';
import { createUrlWithHash, asset } from '../../../../../../utils';
import Loading from '../../../../../../components/ui/Loading';

import { createStore } from '../../../../../../store';
import { __ } from '../../../../../../i18n';

import { Fallback } from '../../../../../../components/kit/Fallback';

export function ViewUI() {
  const store = createStore();
  const env = store?.app?.env;

  useEffect(() => {
    const token = PubSub.subscribe('MICROFRONTEND_READY', (_, mcfName) => {
      const organization = store?.currentOrganization;
      const entity = store?.entity;
      const user = store?.currentUser;

      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        user,
      });
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <RemoteMountedComponent
        rootRoute="/dashboard"
        url={createUrlWithHash(
          `${config.microfrontends.sokka[env]}/remoteEntry.js`,
        )}
        name="sokka"
        moduleName="./mountMethods"
        fallbackComponent={
          <Fallback
            title={__('Connection error')}
            subtitle={__('Try again later')}
            src={asset('/images/no-connection.png')}
            alt="No connection image"
          />
        }
        loadingComponent={<Loading width={30} height={30} />}
      />
    </div>
  );
}
