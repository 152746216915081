import { automaticMessagesAreEqual } from '../automaticMessagesAreEqual';

describe('given automaticMessagesAreEqual helper', () => {
  const mockedParams = {
    oldMessages: {
      FIRST_MESSAGE: {
        message: 'First automatic message',
        enabled: true,
      },
      OUT_WORKING_HOURS: {
        message: 'We are outside working hours',
        enabled: false,
      },
    },
    newMessages: {
      FIRST_MESSAGE: {
        message: 'First automatic message',
        enabled: true,
      },
      OUT_WORKING_HOURS: {
        message: 'We are outside working hours',
        enabled: false,
      },
    },
  };

  it('should be able to compare if the automatic messages are equal', () => {
    const result = automaticMessagesAreEqual(
      mockedParams.oldMessages,
      mockedParams.newMessages,
    );

    expect(result).toBeTruthy();
  });

  it('should be able to return false if the automatic messages are not equal', () => {
    const result = automaticMessagesAreEqual(mockedParams.oldMessages, {
      ...mockedParams.newMessages,
      FIRST_MESSAGE: {
        message: 'BABA',
        enabled: false,
      },
    });

    expect(result).toBeFalsy();
  });

  it('should be able to return false if the old messages are empty', () => {
    const result = automaticMessagesAreEqual({}, mockedParams.newMessages);

    expect(result).toBeFalsy();
  });
});
