import { canSeeAutomaticMessagesUseCase } from '../CanSeeAutomaticMessagesUseCase';
import { createStore } from '../../../../../store';

jest.mock('../../../../../store', () => ({
  createStore: jest.fn(),
}));

describe('canSeeAutomaticMessagesUseCase', () => {
  it('deve retornar true quando unleashStatus.web_conversations_automatic_messages for true', () => {
    createStore.mockReturnValue({
      currentOrganization: {
        unleashStatus: {
          web_conversations_automatic_messages: true,
        },
      },
    });

    const result = canSeeAutomaticMessagesUseCase();
    expect(result).toBe(true);
  });

  it('deve retornar false quando unleashStatus.web_conversations_automatic_messages for false', () => {
    createStore.mockReturnValue({
      currentOrganization: {
        unleashStatus: {
          web_conversations_automatic_messages: false,
        },
      },
    });

    const result = canSeeAutomaticMessagesUseCase();
    expect(result).toBe(false);
  });

  it('deve retornar false quando unleashStatus não estiver definido', () => {
    createStore.mockReturnValue({
      currentOrganization: {},
    });

    const result = canSeeAutomaticMessagesUseCase();
    expect(result).toBe(false);
  });

  it('deve retornar false quando currentOrganization não estiver definido', () => {
    createStore.mockReturnValue({});

    const result = canSeeAutomaticMessagesUseCase();
    expect(result).toBe(false);
  });
});
