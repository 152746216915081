/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  entityId: string | number,
 * }} TVariables
 */

/**
 * @typedef {{
 *    data: {
 *      node: {
 *        hasUnreadDirectMessages: boolean;
 *      }
 *    }
 * }} TResponse
 */

/**
 * @param {TVariables} variables
 * @returns {Promise<TResponse>}
 */
export function getEntityDirectMessageReadStatusRepository(variables) {
  const query = gql`
    query getEntityDirectMessageReadStatus($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          id: dbId
          hasUnreadDirectMessages
        }
      }
    }
  `;

  return Query(query, variables);
}
