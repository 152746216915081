import ApolloClient from 'apollo-client';
import { getApolloClient } from '../apollo';

jest.mock('apollo-client', () => (jest.fn()));
jest.mock('apollo-link-http', () => ({
  createHttpLink: jest.fn(),
}));
jest.mock('apollo-cache-inmemory', () => ({
  InMemoryCache: jest.fn().mockImplementation(() => ({
    restore: jest.fn(),
  }))
}));

describe('Apollo', () => {
  it('should be defined', () => {
    expect(getApolloClient).toBeDefined();
  });

  it('should be a instance of ApolloClient', () => {
    const client = getApolloClient();
    expect(client).toBeDefined();
    expect(client).toBeInstanceOf(ApolloClient);
  });
});
