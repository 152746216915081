/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
  * @typedef {{
  *  id?: number;
  *  name?: string;
  * }} TTag
**/

/**
  * @typedef {{
  *  id?: number;
  *  name?: string;
  *  type?: 'EXTERNAL'
  * }} TGroup
**/

/**
  * @typedef {{
  *  address: string;
  *  type: 'EMAIL' | 'PHONE';
  *  tags?: TTag[];
  *  invite?: boolean;
  * }} TAddress
**/

/**
  * @typedef {{
  *  organizationId: number;
  *  fullname: string;
  *  eid?: string;
  *  type: 'ADMIN' | 'STAFF' | 'STUDENT';
  *  description?: string;
  *  invisible?: boolean;
  *  seeAll?: boolean;
  *  ntfStartTime?: string;
  *  ntfEndTime?: string;
  *  addresses?: TAddress[];
  *  groups?: TGroup[];
  *  defaultFeatures?: ['MESSAGE'];
  *  scope?: string[];
  * }} TVariables
**/

/**
* @param {TVariables} variables
*/
export async function createChannelRepository(variables) {
  const mutation = gql`
    mutation createEntity($createEntityMutation: CreateEntityInput!) {
      createEntity(input: $createEntityMutation) {
        clientMutationId
        entity {
          id: dbId
        }
      }
    }
  `;

  const input = {
    createEntityMutation: {
      ...variables
    }
  };

  const { data, errors } = await Mutation(mutation, input);

  return {
    data,
    errors
  };
}
