import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';

describe('Communications - ViewModel', () => {
  it('should change loading initial state', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.isLoading).toBe(true);

    act(() => {
      result.current.setIsLoading(false);
    });

    expect(result.current.isLoading).toBe(false);
  });

  it('should change mfcReady initial state', async () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.mfcReady).toBe(null);

    act(() => {
      result.current.setMfcReady('SULLY');
    });

    expect(result.current.mfcReady).toBe('SULLY');
  });
});
