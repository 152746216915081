import React, { useEffect } from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';
import PubSub from 'pubsub-js';
import { Icon } from 'semantic-ui-react';
import config from '../../../../../config/server.config';
import { createUrlWithHash } from '../../../utils';
import Loading from '../../../components/ui/Loading';
import { Container, Header, Title, Content } from './ViewStyles';
import { __ } from '../../../i18n';

import { createStore } from '../../../store';

export default function ViewUI() {
  const store = createStore();
  const env = store?.app?.env;

  useEffect(() => {
    PubSub.subscribe('MICROFRONTEND_READY', (_, mcfName) => {
      const organization = store?.currentOrganization;
      const entity = store?.currentEntity;
      const user = store?.currentUser;

      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        organization,
        entity,
        user,
      });
    });
  }, []);

  return (
    <Container>
      <Header>
        <Title>{__('Files')}</Title>
        <a
          href="https://ajuda.classapp.com.br/hc/pt-br/articles/21385047671067-Administradores-Como-utilizar-o-menu-Arquivos"
          target="_blank"
          rel="noreferrer"
          role="link"
          aria-label="help"
        >
          <Icon
            name="question circle"
            color="grey"
            style={{
              opacity: '0.6',
              fontSize: '1.5rem',
              marginLeft: 5,
              marginTop: 8,
            }}
          />
        </a>
      </Header>
      <Content>
        <RemoteMountedComponent
          url={createUrlWithHash(
            `${config.microfrontends.mervyn[env]}/remoteEntry.js`,
          )}
          name="mervyn"
          moduleName="./mountMethods"
          fallbackComponent={
            <div style={{ display: 'flex' }}>
              <i className="icon warning red" style={{ fontWeight: 700 }} />
              <p>Erro ao carregar o componente</p>
            </div>
          }
          loadingComponent={<Loading width={30} height={30} />}
        />
      </Content>
    </Container>
  );
}
