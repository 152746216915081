import React from 'react';
import { Tabs } from '../../../../../components/kit/Tabs';
import { __ } from '../../../../../i18n';
import { OtherNotifications } from './components/OtherNotifications';
import { DirectNotifications } from './components/DirectNotifications';

/**
 * @param {{
 *  notifications: any[],
 *  renderRow: () => any,
 *  pagination: {
 *    graphql: boolean,
 *    hasNextPage: boolean,
 *    loading: boolean,
 *  },
 * showConversations: boolean
 * }} props
 */
export const EntityNotificationViewUI = ({
  notifications,
  renderRow,
  pagination,
  onLoadMore,
  showConversations = false,
  loadingDirectNotifications,
  directNotificationsPagination,
  onClose,
  directNotifications,
  locale,
  ...rest
}) => {
  return showConversations ? (
    <Tabs isFullSize value={'conversations_tab'} {...rest}>
      <Tabs.Tab
        style={{ paddingLeft: 0 }}
        value="conversations_tab"
        title={__('Conversations')}
      >
        <DirectNotifications
          notifications={directNotifications}
          pagination={directNotificationsPagination}
          onClose={onClose}
          locale={locale}
          isLoading={loadingDirectNotifications}
          onLoadMore={onLoadMore}
        />
      </Tabs.Tab>

      <Tabs.Tab
        style={{ paddingLeft: 0 }}
        value="others_tab"
        title={__('Others')}
      >
        <OtherNotifications
          notifications={notifications}
          renderRow={renderRow}
          pagination={pagination}
          onLoadMore={onLoadMore}
        />
      </Tabs.Tab>
    </Tabs>
  ) : (
    <OtherNotifications
      notifications={notifications}
      renderRow={renderRow}
      pagination={pagination}
      onLoadMore={onLoadMore}
      {...rest}
    />
  );
};

export default EntityNotificationViewUI;
