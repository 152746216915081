import styled from 'styled-components';

export const Container = styled.div``;

export const Subtitle = styled.span`
    font-weight: bold;
    font-size: 1.286rem;
    color: #000000;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const DiscardText = styled.span`
    font-size: 1.143rem;
    font-family: Circular,"Helvetica Neue",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 500;
`;

export const SaveText = styled.span`
    font-size: 1.143rem;
    font-family: Circular,"Helvetica Neue",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 500;
    marginLeft: 5px;
`;
