import React from 'react';
import { render, fireEvent, act, screen } from '../../../../../../newRender';
import ViewController from '../ViewController';

import * as ChannelUseCase from '../../../../../Domain/UseCases/Channel/GetChannel';
import { getChannelUseCaseMock } from '../../../../../Domain/UseCases/Channel/__mocks__/channelUseCase.mock';

import { __ } from '../../../../../../i18n';
import * as hasConversationProductFeature from '../../../../../../helpers/hasConversationProductFeature';
import * as createStore from '../../../../../../store';

describe('ViewController', () => {
  const props = {
    params: {
      entity_id: 1
    },
    store: {
      history: 'entities/12/messages'
    },
    router: {
      goBack: jest.fn()
    }
  };

  const data = {
    fullname: '',
    externalId: '',
    description: '',
    hours: '',
    permissions: '',
    picture: '',
    selectedUsers: '',
    visibility: '',
    type: '',
    status: '',
  };

  beforeAll(() => {
    jest.spyOn(hasConversationProductFeature, 'hasConversationProductFeature').mockImplementation(() => true);
    jest.spyOn(ChannelUseCase, 'getChannelUseCase').mockImplementation(async () => getChannelUseCaseMock);
    jest.spyOn(React, 'useCallback').mockImplementation(async () => data);
  });

  it('should be defined', () => {
    const { container } = render(<ViewController {...props} />);
    expect(container).toBeDefined();
  });

  it('should be able to go back when click in the router icon', async () => {
    const { getByTestId } = render(<ViewController {...props} />);
    const onBack = await getByTestId('onBack');
    fireEvent.click(onBack);

    expect(props.router.goBack).toHaveBeenCalled();
  });

  it('should be able to find name input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const channelName = await findByText(`${__('Channel`s name')} *`);
    expect(channelName).toBeInTheDocument();
  });

  it('should be able to find external id input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const externalId = await findByText(/External ID/);
    expect(externalId).toBeDefined();
  });

  it('should be able to find description input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const description = await findByText(/Description/);
    expect(description).toBeDefined();
  });

  it('should be able to find picture input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const picture = await findByText(/Add picture/);
    expect(picture).toBeDefined();
  });

  it('should be able to find hours input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const hours = await findByText(/Office Hours/);
    expect(hours).toBeDefined();
  });

  it('should be able to find responsible input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const responsible = await findByText(/Responsible/);
    expect(responsible).toBeDefined();
  });

  it('should be able to find visibility input component', async () => {
    const { findByText } = render(<ViewController {...props} />);
    const visibility = await findByText(/Visibility/);
    expect(visibility).toBeDefined();
  });

  it('should be not able to find manage permissions input component', async () => {
    const { queryByText } = render(<ViewController {...props} />);
    const managePermissions = await queryByText(__('Manage Permissions'));
    expect(managePermissions).toBeFalsy();
  });

  it('should be able to find Advanced settings input component', async () => {
    const { queryByText } = render(<ViewController {...props} />);
    const managePermissions = queryByText(__('Advanced settings'));
    expect(managePermissions).toBeDefined();
  });

  it('should be able to hide the channel type if conversation is not configured', async () => {
    jest.spyOn(createStore, 'createStore').mockReturnValue({
      currentOrganization: {
        unleashStatus: {
          web_menu_conversations: true
        }
      },
      history: ['entities/12/messages', 'entities/12/edit'],
    });

    await act(async () => {
      render(<ViewController {...props} />);
    });

    const managePermissions = screen.queryByText(__('Channel Type'));
    expect(managePermissions).toBeDefined();
  });
});
