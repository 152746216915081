import { convertAddressesToInputUseCase } from '../ConvertAddressesToInput';

describe('given convertAddressesToInput use case', () => {
  const mocks = {
    selectedUsers: [
      {
        address: '5519900000002',
        tags: [],
        type: 'PHONE',
        user: {
          id: 2,
          fullname: 'Mateus Andrade',
          phone: '5519900000002',
          email: 'i+admin@classapp.com.br',
          confirmPhone: true,
          confirmEmail: true,
          tags: {
            nodes: [],
            __typename: 'UserTagsConnection'
          },
          __typename: 'User'
        },
        original: true
      },
      {
        address: 'i+admin@classapp.com.br',
        tags: [],
        type: 'EMAIL',
        user: {
          id: 2,
          fullname: 'Mateus Andrade',
          phone: '5519900000002',
          email: 'i+admin@classapp.com.br',
          confirmPhone: true,
          confirmEmail: true,
          tags: {
            nodes: [],
            __typename: 'UserTagsConnection'
          },
          __typename: 'User'
        },
        original: true
      }
    ],
    originalUsers: [
      {
        address: '5519900000002',
        tags: [],
        type: 'PHONE',
        user: {
          id: 2,
          fullname: 'Mateus Andrade',
          phone: '5519900000002',
          email: 'i+admin@classapp.com.br',
          confirmPhone: true,
          confirmEmail: true,
          tags: {
            nodes: [],
            __typename: 'UserTagsConnection'
          },
          __typename: 'User'
        },
        original: true
      },
      {
        address: 'i+admin@classapp.com.br',
        tags: [],
        type: 'EMAIL',
        user: {
          id: 2,
          fullname: 'Mateus Andrade',
          phone: '5519900000002',
          email: 'i+admin@classapp.com.br',
          confirmPhone: true,
          confirmEmail: true,
          tags: {
            nodes: [],
            __typename: 'UserTagsConnection'
          },
          __typename: 'User'
        },
        original: true
      }
    ],
    originalAddresses: [
      {
        address: '5519900000002',
        type: 'PHONE',
        userId: 2,
        __typename: 'Address'
      },
      {
        address: 'i+admin@classapp.com.br',
        type: 'EMAIL',
        userId: 2,
        __typename: 'Address'
      }
    ],
    entity_id: null
  };

  it('should be able to return empty result when param is not provided', () => {
    const result = convertAddressesToInputUseCase(null);

    expect(result).toEqual({
      addresses: [],
      removedIds: []
    });
  });

  it('should be able to return the selected users formatted', () => {
    const variables = { ...mocks };
    const result = convertAddressesToInputUseCase(variables);

    expect(result).toEqual({
      addresses: [{
        address: 'i+admin@classapp.com.br', invite: false, tags: [], type: 'EMAIL'
      }, {
        address: '5519900000002', invite: false, tags: [], type: 'PHONE'
      }],
      removedIds: []
    });
  });

  it('should be able to testing pending addresses', () => {
    const variables = {
      ...mocks,
      originalAddresses: [
        {
          address: '5519900000002',
          type: 'PHONE'
        }
      ]
    };

    const result = convertAddressesToInputUseCase(variables);

    expect(result).toEqual({
      addresses: [{
        address: 'i+admin@classapp.com.br', invite: false, tags: [], type: 'EMAIL'
      }, {
        address: '5519900000002', invite: false, tags: [], type: 'PHONE'
      }],
      removedIds: []
    });
  });

  it('should be able to testing invite addresses', () => {
    const variables = {
      ...mocks,
      originalUsers: [
        {
          address: '5519900000002',
          tags: {
            nodes: [
              { id: 1, name: 'Tag1', __typename: 'Tag' }
            ]
          },
          type: 'PHONE',
          original: true
        },
        {
          address: 'i+admin@classapp.com.br',
          tags: [],
          type: 'EMAIL',
          original: true
        }
      ]
    };

    const result = convertAddressesToInputUseCase(variables);

    expect(result).toEqual({
      addresses: [{
        address: '5519900000002', invite: false, tags: [{ id: 1, name: 'Tag1' }], type: 'PHONE'
      }, {
        address: 'i+admin@classapp.com.br', invite: false, tags: [], type: 'EMAIL'
      }],
      removedIds: []
    });
  });

  it('should be able to return the removed users', () => {
    const variables = { ...mocks, selectedUsers: [] };
    const result = convertAddressesToInputUseCase(variables);

    expect(result).toEqual({
      addresses: [
        {
          address: 'i+admin@classapp.com.br',
          type: 'EMAIL',
          tags: [],
          invite: false
        },
        {
          address: '5519900000002',
          type: 'PHONE',
          tags: [],
          invite: false
        }
      ],
      removedIds: [2]
    });
  });
});
