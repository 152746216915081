import { DefaultAutomaticMessages } from '../../Presentation/pages/Channel/components/AutomaticMessages/constants/default-automatic-messages';
import { formatAutomaticMessages } from '../formatAutomaticMessages';

describe('given the formatAutomaticMessages helper', () => {
  const mockedParams = {
    messages: DefaultAutomaticMessages(),
    messagesRef: {
      current: [],
    },
    triggersRef: {
      current: [],
    },
  };

  it('should be able to format the automatic messages', () => {
    const result = formatAutomaticMessages(mockedParams);

    expect(result).toHaveLength(DefaultAutomaticMessages().length);
  });

  it('should be able to format the automatic messages with empty messages', () => {
    const result = formatAutomaticMessages({ ...mockedParams, messages: [] });

    expect(result).toHaveLength(0);
  });

  it('should be able to have columns', () => {
    const result = formatAutomaticMessages(mockedParams);

    expect(result[0].columns).toHaveLength(2);
  });
});
