import React from 'react';

import { render } from '../../../../../../newRender';
import ViewUI from '../ViewUI';

describe('Chats ViewUI', () => {
  let mockedProps = {};

  beforeEach(() => {
    jest.clearAllMocks();
    mockedProps = {
      isLoading: true,
      env: 'development',
    };
  });

  it('should render screen', () => {
    const { container } = render(<ViewUI {...mockedProps} />);
    expect(container).toBeInTheDocument();
  });
});
