import { updateChannelRepository } from '../../../Data/Repository/Channel/UpdateChannel';

/**
* @param {import("../../../Data/Repository/Channel/UpdateChannel").TVariables} variables
*/
export async function updateChannelUseCase(variables) {
  const { data, errors } = await updateChannelRepository(variables);

  return {
    data,
    errors
  };
}
