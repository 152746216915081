import gql from 'graphql-tag';
import { massUpdateEntityRepository } from '../massUpdateEntityRepository';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('mutation'));

describe('given the "massUpdateEntityRepository" function', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => ({
      data: {
        massUpdateEntity: {
          updateSuccess: true,
        }
      },
    }));
  });

  const mutation = [`
    mutation MassUpdate($input: MassUpdateEntityInput!) {
      massUpdateEntity(input: $input) {
          updateSuccess
      }
    }
  `];

  const variables = [{
    id: 10,
    confAttendanceEnabled: true,
  }];

  it('should call the "Mutation" function with the correct params', async () => {
    const formatedVariables = {
      input: {
        entities: variables,
      },
    };

    await massUpdateEntityRepository(variables);

    expect(DataSource.Mutation).toHaveBeenCalledWith('mutation', formatedVariables);
  });

  it('should return Mutation response', async () => {
    const response = await massUpdateEntityRepository(variables);

    expect(response).toEqual({ data: { massUpdateEntity: { updateSuccess: true } } });
  });

  it('should call the "gql" function with the correct mutation', async () => {
    await massUpdateEntityRepository(variables);

    expect(gql).toHaveBeenCalledWith(mutation);
  });
});
