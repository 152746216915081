import { renderHook, act } from '@testing-library/react-hooks/dom';
import { ViewModel as useViewModel } from '../ViewModel';
import * as useCase from '../../../../../Domain/UseCases/Channel/GetEntityDirectMessageReadStatus';

describe('Chats ViewModel', () => {
  const store = {
    currentEntity: {
      id: '1',
      hasUnreadDirectMessages: true,
    },
  };

  beforeEach(() => {
    jest
      .spyOn(useCase, 'getEntityDirectMessageReadStatusUseCase')
      .mockResolvedValue({
        data: {
          node: {
            hasUnreadDirectMessages: false,
          },
        },
      });
  });

  it('should not execute the use case if currentEntity is not defined', async () => {
    const emptyStore = {};
    const { result } = renderHook(() => useViewModel());

    await act(async () => {
      await result.current.handleUpdateChatsReadStatus(emptyStore);
    });

    expect(
      useCase.getEntityDirectMessageReadStatusUseCase,
    ).not.toHaveBeenCalled();
  });

  it('should execute the use case if currentEntity is defined', async () => {
    const { result } = renderHook(() => useViewModel());

    await act(async () => {
      await result.current.handleUpdateChatsReadStatus(store);
    });

    expect(
      useCase.getEntityDirectMessageReadStatusUseCase,
    ).toHaveBeenCalledWith({
      entityId: store.currentEntity.id,
    });
  });

  it('should update the currentEntity hasUnreadDirectMessages property', async () => {
    const { result } = renderHook(() => useViewModel());

    await act(async () => {
      await result.current.handleUpdateChatsReadStatus(store);
    });

    expect(store.currentEntity.hasUnreadDirectMessages).toBe(false);
  });

  it('should not update the currentEntity hasUnreadDirectMessages if the use case fails', async () => {
    store.currentEntity.hasUnreadDirectMessages = true;
    const error = new Error('Error');
    jest
      .spyOn(useCase, 'getEntityDirectMessageReadStatusUseCase')
      .mockResolvedValue({
        error,
      });
    const { result } = renderHook(() => useViewModel());

    await act(async () => {
      await result.current.handleUpdateChatsReadStatus(store);
    });

    expect(store.currentEntity.hasUnreadDirectMessages).toBe(true);
  });

  it('should be able to set the mfcReady state', () => {
    const { result } = renderHook(() => useViewModel());

    act(() => {
      result.current.setMfcReady('KATARA');
    });

    expect(result.current.mfcReady).toBe('KATARA');
  });

  it('should be able to see the mfcReady as null by default', () => {
    const { result } = renderHook(() => useViewModel());

    expect(result.current.mfcReady).toBe(null);
  });
});
