import gql from 'graphql-tag';
import { getChannelListRepository } from '../getChannelListRepository';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('query'));

describe('given the "getChannelListRepository" function', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: { nodes: [{ id: 1 }] },
    }));
  });

  const variables = {
    organizationId: 10,
    limit: 0,
    offset: 2,
    search: 'Channel Name',
  };

  it('should call the "Query" function with the correct params', async () => {
    await getChannelListRepository(variables);

    expect(DataSource.Query).toHaveBeenCalledWith('query', variables);
  });

  it('should return Query response', async () => {
    const response = await getChannelListRepository(variables);

    expect(response).toEqual({ data: { nodes: [{ id: 1 }] } });
  });

  it('should call the "gql" function', async () => {
    await getChannelListRepository(variables);

    expect(gql).toHaveBeenCalled();
  });
});
