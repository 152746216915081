import React from 'react';
import { render } from '../../../../../../newRender';
import * as store from '../../../../../../store';

import ViewController from '../ViewController';

import * as viewModel from '../ViewModel';
import * as viewUI from '../ViewUI';

/// import { __ } from '../../../../../../i18n';

describe('ViewController', () => {
  const props = {
    setIsLoading: jest.fn(),
    setHasChannelDashboard: jest.fn(),
  };

  beforeEach(() => {
    jest.clearAllMocks();

    jest.spyOn(viewModel, 'ViewModel').mockReturnValue({
      isLoading: false,
      hasChannelDashboard: true,
      setIsLoading: props.setIsLoading,
      setHasChannelDashboard: props.setHasChannelDashboard,
    });

    jest.spyOn(viewUI, 'ViewUI').mockImplementation(() => <div>ViewUI</div>);

    jest.spyOn(store, 'createStore').mockReturnValue({
      unleash: {
        isReady: true,
        getStatus: jest.fn(() => 'menu_conversations_organization_dashboard'),
      },
    });
  });

  it('should be defined', () => {
    const { container } = render(<ViewController />);
    expect(container).toBeInTheDocument();
  });

  it('should call view model functions when useEffect is called', () => {
    render(<ViewController />);

    expect(props.setIsLoading).toHaveBeenCalledTimes(1);
    expect(props.setIsLoading).toHaveBeenCalledWith(false);
    expect(props.setHasChannelDashboard).toHaveBeenCalledTimes(1);
    expect(props.setHasChannelDashboard).toHaveBeenCalledWith(
      'menu_conversations_organization_dashboard',
    );
  });
});
