import { Image } from 'semantic-ui-react';
import * as utils from '../../utils';
import React from 'react';

import BetaModal from "../../components/ui/Modal";
import { __ } from "../../i18n";
import Button from "../../components/ui/Button";

/**
 * @param {{
 *  organizationName: string,
 *  organizationId: number,
 *  onClose?: () => void,
 * }} props
 */
export default function AddAccountModal({
  organizationName,
  organizationId,
  onClose,
}) {
  return (
    <BetaModal
      id="AddAccountModal"
      heightSize={800}
      style={{ width: 730 }}
      header={__("Bank account registration")}
      className="beta-modal"
      onClose={onClose}
      content={(
        <div style={{ textAlign: 'center', padding: '16px 24px 39px 24px' }}>
          <Image height="360" src={utils.asset('/images/create-new-bank-account.png')} centered />
          <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: '2rem' }}>{__('More security in the account creation process')}</div>
          <div>
            <div>{__('To comply with Central Bank regulations, we have included additional steps, such as the Proof of Life. Registration will be carried out on an external form, allowing our team to verify your information and contact you with guidance on the next steps. The process can take 5 to 7 working days')}</div>
            <div style={{ marginTop: '1.5rem' }}>
              {__('For more details on the account creation process')}
              ,&nbsp;
              <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/26599374651803-Primeiros-passos-para-ut[…]y-Cadastrar-o-banco-da-institui%C3%A7%C3%A3o-Prova-de-vida" target="_blank" rel="noreferrer">{__('click here.')}</a>
            </div>
          </div>
          <Button
            round
            full
            text={__('Fill the form')}
            frontIcon={{
              name: 'external link',
              color: '#000000',
              style: { marginBottom: 8 },
            }}
            onClick={() => {
              window.open(
                `https://share.hsforms.com/1Q_QJkOlgRIGNTuRc0XJ_ww3jwc6?subject=${organizationName}&id_classapp=${organizationId}`,
                '_blank',
                'noopener noreferrer',
              );
              onClose();
            }}
            style={{ marginTop: '2.5rem' }}
          />
        </div>
      )}
    />
  );
}
