import cookie from 'react-cookie';
import { getApolloClient } from '../../services/apollo';
/**
 * @typedef {{
 *  result?: any,
 *  error?: any
 * }} ReturnValue
 */

/**
 * @param {import("graphql").DocumentNode} query
 * @param {object} variables
 */
export function Query(query, variables = null) {
  try {
    const access_token = cookie.load('access_token');
    return getApolloClient(null, {
      Authorization: `Bearer ${access_token}`,
    }).query({
      query,
      variables,
    });
  } catch (err) {
    return Promise.resolve({ error: err.message, data: null, loading: false });
  }
}

/**
 * @param {import("graphql").DocumentNode} mutation
 * @param {object} variables
 * @returns {Promise<ReturnValue>}
 */
export function Mutation(mutation, variables) {
  try {
    const access_token = cookie.load('access_token');
    const client = getApolloClient(null, {
      Authorization: `Bearer ${access_token}`,
    });

    return client.mutate({
      mutation,
      variables,
    });
  } catch (err) {
    return Promise.resolve({ error: err.message, data: null, loading: false });
  }
}
