import { createOrUpdateChannelAutomaticMessagesUseCase } from '../createOrUpdateChannelAutomaticMessagesUseCase';
import { createOrUpdateChannelAutomaticMessagesRepository } from '../../../../Data/Repository/AutomaticMessage/CreateOrUpdateChannelAutomaticMessages';
import * as AutomaticMessageUseCase from '../../AutomaticMessage/CanSeeAutomaticMessagesUseCase';

jest.mock(
  '../../../../Data/Repository/AutomaticMessage/CreateOrUpdateChannelAutomaticMessages',
  () => ({
    createOrUpdateChannelAutomaticMessagesRepository: jest.fn(),
  }),
);

jest.mock('../../AutomaticMessage/CanSeeAutomaticMessagesUseCase', () => ({
  canSeeAutomaticMessagesUseCase: jest.fn(),
}));

describe('given the createOrUpdateChannelAutomaticMessagesUseCase function', () => {
  const variables = {
    channelId: 123,
    automaticMessages: {
      WELCOME: {
        message: 'Bem-vindo!',
        enabled: true,
      },
      FIRST_MESSAGE: {
        message: 'Primeira mensagem automática',
        enabled: true,
      },
      OUT_WORKING_HOURS: {
        message: 'Estamos fora do horário de atendimento',
        enabled: false,
      },
    },
  };

  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should call createOrUpdateChannelAutomaticMessagesRepository with the correct variables', async () => {
    AutomaticMessageUseCase.canSeeAutomaticMessagesUseCase.mockResolvedValueOnce(
      true,
    );

    const mockResponse = { data: { success: true }, errors: null };
    createOrUpdateChannelAutomaticMessagesRepository.mockResolvedValueOnce(
      mockResponse,
    );

    const result =
      await createOrUpdateChannelAutomaticMessagesUseCase(variables);

    expect(
      createOrUpdateChannelAutomaticMessagesRepository,
    ).toHaveBeenCalledTimes(1);
    expect(
      createOrUpdateChannelAutomaticMessagesRepository,
    ).toHaveBeenCalledWith(variables);
    expect(result).toEqual(mockResponse);
  });

  it('should handle errors from the repository', async () => {
    AutomaticMessageUseCase.canSeeAutomaticMessagesUseCase.mockResolvedValueOnce(
      true,
    );

    const mockError = { data: null, errors: ['Erro na mutation'] };
    createOrUpdateChannelAutomaticMessagesRepository.mockResolvedValueOnce(
      mockError,
    );

    const result =
      await createOrUpdateChannelAutomaticMessagesUseCase(variables);

    expect(result).toEqual(mockError);
  });

  it('should not call the repository if the Unleash feature is disabled', async () => {
    AutomaticMessageUseCase.canSeeAutomaticMessagesUseCase.mockResolvedValueOnce(
      false,
    );

    const result =
      await createOrUpdateChannelAutomaticMessagesUseCase(variables);

    expect(
      createOrUpdateChannelAutomaticMessagesRepository,
    ).not.toHaveBeenCalled();
    expect(result).toEqual({ data: null, errors: ['Feature not enabled'] });
  });
});
