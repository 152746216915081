import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'mobx-react';
import { Router, RouterContext, browserHistory } from 'react-router';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider } from '@classapp-tech/edna';
import { themes } from '@classapp-tech/edna-styles';
import { createStore } from './store';
import createRoutes from './routes';
import autorun from './autorun';
import * as utils from './utils';
import { createAPI, client_id } from './api';
import possibleTypes from './possibleTypes.json';
import Unleash from './lib/unleash';
import { initializeGoogleAnalytics } from './lib/analytics';

// Inject server-side store into front-end
const store = createStore(window.__STORE);
store.unleash = new Unleash(store.app.env);
const currentTimezone = -(new Date().getTimezoneOffset());
const unleashConnectionTime = 0;
const maxWaitingTime = 5 * 1000;
const timeoutDelay = 100;

// Import our styles
require('./assets/css/styles.scss');
// Import our fonts
require('./assets/css/fonts.css');

// Sentry
if (store.app.env && store.app.env !== 'local') {
  // Init sentry
  Sentry.init({
    dsn: 'https://8c008c8a7b73e0004bab972390023c0a@sentry.classapp.com.br/18',
    integrations: [new BrowserTracing()],
    environment: store.app.env || 'local',
    tracesSampleRate: 1 / 100, // 1%
  });
} else {
  console.info('Skipping sentry initialization!');
}

// Apollo cache
const cache = new InMemoryCache({ possibleTypes });

// Apollo http link
const httpLink = createHttpLink({
  uri: `${store.app.url}/graphql?client_id=${client_id}&tz_offset=${currentTimezone}&locale=${store.app.locale}`
});

// Apollo format request link
const middlewareLink = setContext(() => ({
  headers: {
    authorization: store.access_token ? `Bearer ${store.access_token}` : null

  }
}));

// Apollo error link
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.error(graphQLErrors[0]);
  }
});

const link = ApolloLink.from([
  errorLink,
  middlewareLink,
  httpLink,
]);

const client = new ApolloClient({
  link,
  cache: cache.restore(JSON.parse(store.apollo.initialState))
});

const api = createAPI({
  access_token: store.access_token,
  url: store.app.url,
  uploadUrl: store.app.uploadUrl,
});

// Setup autorun ( for document title change )
autorun(store);

// Wrap RouterContext with Provider for store transfer
function createElement(props) {
  return (
    <Provider store={store} api={api} client={client}>
      <RouterContext {...props} />
    </Provider>
  );
}

function onRouterUpdate() {
  const { params, location, components } = this.state;

  // Get difference in components from previous routes to only fetch necessary data
  const diff = !isEqual(params, store.previous.params) ? components
    : difference(components, store.previous.components);
  store.previous.components = components;
  store.previous.params = params;
  store.previous.location = location;
  store.history.push(location.pathname);
  store.currentLocation = location;

  if (store.firstLoad) {
    store.firstLoad = false;
    return;
  }

  if (diff.length === 0) {
    const last = components[components.length - 1];
    diff.push(last);
  }

  const fetchDataMethods = diff.filter(c => c.fetchData).map(c => c.fetchData);

  if (fetchDataMethods.length) {
    store.pageLoading = true;
  }

  utils.mapSeries(fetchDataMethods, (method => method({
    store, params, location, api, client
  })))
    .then(() => (store.pageLoading = false));
}

initializeGoogleAnalytics({ store });

// Render HTML on the browser
function renderRouter() {
  hydrate(
    <ApolloProvider client={client}>
      <ThemeProvider theme={themes.classapp.classappLight}>
        <Router
          history={browserHistory}
          render={createElement}
          routes={createRoutes(store, client)}
          onUpdate={onRouterUpdate}
        />
      </ThemeProvider>
    </ApolloProvider>,
    document.getElementById('root')
  );
}

renderRouter();
