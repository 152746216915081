/**
  * @typedef {{
*  entityType?: 'ADMIN' | 'STAFF' | 'STUDENT';
* }} TVariables
**/

/**
* @param {TVariables} variables
*/
export function convertGroupsToInputUseCase(selectedGroups) {
  if (!selectedGroups) return [];
  return selectedGroups.map(group => ({ id: group.id, name: group.name, type: null }));
}
