import { useMemo } from 'react';
import { createStore } from '../../../../../store';
import PubSub from 'pubsub-js';

export function ViewModel() {
  const store = createStore();
  const locale = store?.app?.locale;

  const shouldSeeConversations = useMemo(() => {
    const someOrganizationHasFeature =
      store.currentUser?.organizations?.nodes?.some(
        (organization) =>
          organization?.featureToggles
            ?.web_menu_conversations_notification_bell,
      );

    const currentOrganizationHasFeature =
      store.currentOrganization?.featureToggles
        ?.web_menu_conversations_notification_bell;

    return someOrganizationHasFeature || currentOrganizationHasFeature;
  }, [store.currentOrganization, store.currentUser]);

  function registerNotificationsPubSub() {
    PubSub.subscribe('WALLE_SET_NOTIFICATIONS', (_, totalCount) => {
      store.currentUser.notifications.totalCount = totalCount;
    });

    PubSub.subscribe('WALLE_INCREASE_NOTIFICATIONS', () => {
      store.currentUser.notifications.totalCount += 1;
    });

    PubSub.subscribe('WALLE_DECREASE_NOTIFICATIONS', () => {
      if (store.currentUser.notifications.totalCount > 0) {
        store.currentUser.notifications.totalCount -= 1;
      }
    });
  }

  return {
    shouldSeeConversations,
    locale,
    registerNotificationsPubSub,
  };
}
