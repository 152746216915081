import React, { useEffect } from 'react';

import { createStore } from '../../../../../store';

import { ViewModel as useViewModel } from './ViewModel';
import { ViewUI } from './ViewUI';

export default function ViewController(props) {
  const {
    isLoading,
    setIsLoading,
    setHasOrganizationDashboard,
    hasOrganizationDashboard,
    hasConversationFeature,
    getOrganizationUnleashFeature,
  } = useViewModel();
  const store = createStore();

  useEffect(() => {
    (async () => {
      const response = await getOrganizationUnleashFeature(
        store?.organization?.id ?? store.currentOrganization?.id,
      );

      const unleash = response?.data?.node?.unleashStatus;

      const __hasConversationFeature = hasConversationFeature(
        unleash.web_menu_conversations,
        store.currentOrganization,
      );

      const hasDashboardUnleash =
        unleash.menu_conversations_organization_dashboard;

      setHasOrganizationDashboard(
        hasDashboardUnleash && __hasConversationFeature,
      );
      setIsLoading(false);
    })();
  }, []);

  return (
    <ViewUI
      {...props}
      isLoading={isLoading}
      hasOrganizationDashboard={hasOrganizationDashboard}
    />
  );
}
