/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';
/**
  * @typedef {{
  *   id: number | string,
  *   confAttendanceEnabled?: boolean,
  * }} TVariables
  **/

/**
 * @typedef {Promise<{
 *  data: {
 *      massUpdateEntity: {
 *        updateSuccess: true;
 *      }
 *    } | null,
 *  error?: string
 * }>} TResponse
 */

/**
 * @param {TVariables[]} variables
 * @returns {TResponse}
 */
export async function massUpdateEntityRepository(variables) {
  const mutation = gql`
    mutation MassUpdate($input: MassUpdateEntityInput!) {
      massUpdateEntity(input: $input) {
          updateSuccess
      }
    }
  `;

  const input = {
    input: {
      entities: variables
    }
  };

  const { data, error } = await Mutation(mutation, input);
  return { data, error };
}
