import imageToBase64 from 'image-to-base64/browser';
import { b64toBlob, asset } from '../../../../utils';

/**
* @param {string} iconName The
*/
export async function convertImageToBlobUseCase(iconName) {
  if (!iconName) return null;

  try {
    const base64 = await imageToBase64(asset(`/icons/${iconName}.png`));
    const blob = b64toBlob(base64, 'image/png');
    return blob;
  } catch (error) {
    return null;
  }
}
