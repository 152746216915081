/* eslint-disable sonarjs/no-duplicate-string */
/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import {
  Header,
  Icon,
  Popup,
  Container,
  Dropdown,
  Statistic,
  Loader,
  Image,
} from 'semantic-ui-react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  Cell,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import qs from 'qs';

import DashEntityCard from '../../../../../../components/DashEntityCard';
import CustomLabel from '../../../../../../components/CustomLabel';

import { __ } from '../../../../../../i18n';
import * as utils from '../../../../../../utils';

const limitOptions = () => [
  { text: __('Last %s days', 30), value: 30 },
  { text: __('Last %s days', 15), value: 15 },
  { text: __('Last %s days', 7), value: 7 },
];

@inject('store')
@observer
@graphql(
  gql`
    query EntityDashboardQuery($entity_id: ID!, $limit: Int) {
      entityData: node(id: $entity_id)
        @connection(key: "Entity", filter: ["entity_id"]) {
        ... on Entity {
          id: dbId
          dailyMessagesMetrics(offset: 0, limit: 1, period: $limit) {
            directReplyTime
            directReplyMessages
            forwardedReplyTime
            forwardedReplyMessages
            sentMessages
            receivedMessages
            sentReplies
          }
          organization {
            id: dbId
            dailyMessagesMetrics(offset: 0, limit: 1, period: $limit) {
              directReplyTime
              directReplyMessages
              forwardedReplyTime
              forwardedReplyMessages
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      variables: {
        entity_id: ownProps.params.entity_id,
        limit: parseInt(ownProps.location.query.limit || 30, 10),
      },
    }),
  },
)
export default class MessageDashboard extends React.Component {
  state = {
    parameters: {},
  };

  // Runs after fetchData
  componentDidMount() {
    let { location } = this.props;

    this.setState({ parameters: { ...location.query } });
  }

  onParameterChange = (name, value) => {
    const parameters = { ...this.state.parameters };

    if (!value || value === 'all') {
      delete parameters[name];
    } else {
      parameters[name] = value;
    }

    this.setState({ parameters }, () => {
      this.eventReload();
    });
  };

  eventReload = () => {
    const parameters = this.state.parameters;
    const params = qs.stringify(parameters);
    const pathname = this.props.location.pathname;

    if (params.length > 0) {
      browserHistory.push(pathname + '?' + params);
    } else {
      browserHistory.push(pathname);
    }
  };

  renderCustomizedLabel = ({ viewBox: { width, y } }, title, description) => (
    <text x={width + 30} y={y} dy={6} fill="#ccc">
      <tspan fontWeight="bold">{title}</tspan>
      <tspan x={width + 30} dy="1.2em">
        {description}
      </tspan>
    </text>
  );

  renderCustomizedAxisTick = ({ x, y, payload }) => (
    <text
      x={x}
      y={y}
      fill="#666"
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor="middle"
      fontSize="12"
      fontWeight="bold"
    >
      <tspan x={x} dy={20}>
        {payload.value}
      </tspan>
    </text>
  );

  render() {
    const { parameters } = this.state;
    const { store, data } = this.props;
    const { entityData } = data;
    const lang = store.app.locale
      ? store.app.locale === 'pt'
        ? 'pt-BR'
        : store.app.locale
      : 'en';
    const date = new Date();
    const now = utils.simpleDate(date, true, 'LL', lang);
    const then = utils.simpleDate(
      date.setDate(date.getDate() - (parameters.limit - 1 || 30)),
      true,
      'LL',
      lang,
    );
    let charts = [];

    const entityMessagesMetrics =
      (entityData &&
        entityData.dailyMessagesMetrics.length &&
        entityData.dailyMessagesMetrics[0]) ||
      {};
    const organizationMessagesMetrics =
      (entityData &&
        entityData.organization.dailyMessagesMetrics.length &&
        entityData.organization.dailyMessagesMetrics[0]) ||
      {};

    entityMessagesMetrics.replyTime = utils.calculateReplyTime(
      entityMessagesMetrics,
    );
    organizationMessagesMetrics.replyTime = utils.calculateReplyTime(
      organizationMessagesMetrics,
    );

    const { currentOrganization } = store;
    const isSchool = currentOrganization
      ? currentOrganization.type === 'SCHOOL'
      : true;

    if (entityData) {
      const eT = entityMessagesMetrics.replyTime;
      const eTDisplay = utils.handleDirectReplyTime(eT, true);
      const oT = organizationMessagesMetrics.replyTime;
      const oTDisplay = utils.handleDirectReplyTime(oT, true);

      const _23h30 = 23.5 * 60;
      const _5h30 = 5.5 * 60;

      if (eT === null) {
        charts.push({
          name: __('My channel').toUpperCase(),
          a: 'channel',
          default: 1,
          value: '--',
        });

        if (oT / 60 < _5h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 15,
            value: oTDisplay,
          });
        } else if (oT / 60 >= _23h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 45,
            value: oTDisplay,
          });
        } else {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 35,
            value: oTDisplay,
          });
        }
      } else if (eT > oT && eTDisplay !== oTDisplay) {
        if (eT / 60 < _5h30) {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 15,
            value: eTDisplay,
          });
        } else if (eT / 60 >= _23h30) {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 45,
            value: eTDisplay,
          });
        } else {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 35,
            value: eTDisplay,
          });
        }

        if (oT / 60 < _5h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 10,
            value: oTDisplay,
          });
        } else if (oT / 60 >= _23h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 42,
            value: oTDisplay,
          });
        } else {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 30,
            value: oTDisplay,
          });
        }
      } else if (eT < oT && eTDisplay !== oTDisplay) {
        if (eT / 60 < _5h30) {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 10,
            value: eTDisplay,
          });
        } else if (eT / 60 >= _23h30) {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 42,
            value: eTDisplay,
          });
        } else {
          charts.push({
            name: __('My channel').toUpperCase(),
            a: 'channel',
            default: 30,
            value: eTDisplay,
          });
        }

        if (oT / 60 < _5h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 15,
            value: oTDisplay,
          });
        } else if (oT / 60 >= _23h30) {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 45,
            value: oTDisplay,
          });
        } else {
          charts.push({
            name: __('General average').toUpperCase(),
            a: 'general',
            default: 35,
            value: oTDisplay,
          });
        }
      } else if (eT / 60 < _5h30) {
        charts.push({
          name: __('My channel').toUpperCase(),
          a: 'channel',
          default: 15,
          value: eTDisplay,
        });
        charts.push({
          name: __('General average').toUpperCase(),
          a: 'general',
          default: 15,
          value: oTDisplay,
        });
      } else if (eT / 60 >= _23h30) {
        charts.push({
          name: __('My channel').toUpperCase(),
          a: 'channel',
          default: 45,
          value: eTDisplay,
        });
        charts.push({
          name: __('General average').toUpperCase(),
          a: 'general',
          default: 45,
          value: oTDisplay,
        });
      } else {
        charts.push({
          name: __('My channel').toUpperCase(),
          a: 'channel',
          default: 35,
          value: eTDisplay,
        });
        charts.push({
          name: __('General average').toUpperCase(),
          a: 'general',
          default: 35,
          value: oTDisplay,
        });
      }
    }

    return (
      <div id="EntityDashboard">
        <Container
          key={0}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <Icon name="filter" />
            <strong>{__('Filter by:')}</strong>
            <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
              <Dropdown
                inline
                options={limitOptions()}
                value={parameters.limit || 30}
                onChange={(e, { value }) =>
                  this.onParameterChange('limit', value)
                }
              />
            </span>
            <div style={{ opacity: '0.5', marginTop: '5px' }}>
              {__('Period from %s to %s', then, now)}
            </div>
          </div>
        </Container>
        {data.loading ? (
          <Loader active inline="centered" />
        ) : (
          [
            <DashEntityCard
              key="1"
              title={__('Average reply time')}
              info={__('Average time taken to answer messages')}
              padded
              content={
                organizationMessagesMetrics.replyTime ? (
                  <ResponsiveContainer width={'100%'} height={220}>
                    <BarChart
                      data={charts}
                      barSize={120}
                      margin={{ top: 5, right: 60, left: 20, bottom: 5 }}
                    >
                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        fontSize={12}
                        fontWeight="bold"
                        tick={this.renderCustomizedAxisTick}
                      />
                      <ReferenceLine
                        y={60}
                        label={(prop) =>
                          this.renderCustomizedLabel(
                            prop,
                            __('Critic'),
                            __('> 24h'),
                          )
                        }
                        alwaysShow
                      />
                      <ReferenceLine
                        y={40}
                        label={(prop) =>
                          this.renderCustomizedLabel(
                            prop,
                            __('Attention'),
                            __('6h - 24h'),
                          )
                        }
                        alwaysShow
                      />
                      <ReferenceLine
                        y={20}
                        label={(prop) =>
                          this.renderCustomizedLabel(
                            prop,
                            __('Ideal'),
                            __('Until 6h'),
                          )
                        }
                        alwaysShow
                      />
                      <Bar dataKey="default">
                        <LabelList
                          dataKey="value"
                          position="top"
                          content={<CustomLabel />}
                        />
                        {charts.map((entry, index) => (
                          <Cell
                            fill={entry.a === 'channel' ? '#4a90e2' : '#999999'}
                            key={`cell-${index}`}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div>
                    <Image
                      src={utils.asset('/images/astronaut.png')}
                      style={{ maxHeight: 260, marginTop: 30 }}
                      centered
                    />
                    <p
                      style={{
                        color: '#1D1D1D',
                        fontSize: 20,
                        margin: '20px 0px 8px',
                      }}
                    >
                      {__('No data found')}
                    </p>
                    <p style={{ color: '#00000099' }}>
                      {__(
                        'Keep interacting with your messages to have your reply time calculated.',
                      )}
                    </p>
                    <a
                      href="https://ajuda.classapp.com.br/hc/pt-br/articles/4404932610843-Principais-d%C3%BAvidas-e-melhores-pr%C3%A1ticas-dos-Canais-de-atendimento"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <p>{__('See how it works')}</p>
                    </a>
                  </div>
                )
              }
            />,
            <DashEntityCard
              key="2"
              title={__('My messages')}
              padded
              content={[
                <Statistic.Group size="tiny">
                  <Statistic>
                    <Header
                      as="h5"
                      style={{ textAlign: 'left', fontSize: '13px' }}
                    >
                      <Header.Content>
                        {__('Sent').toUpperCase()}
                        <Popup
                          trigger={
                            <Icon
                              style={{ opacity: '.6', marginLeft: '5px' }}
                              name="info circle"
                              color="grey"
                            />
                          }
                          content={
                            <span>
                              {isSchool
                                ? __(
                                  'Messages sent by the channel to students and parents',
                                )
                                : __('Messages sent to clients via channel')}
                            </span>
                          }
                          hideOnScroll
                          basic
                        />
                      </Header.Content>
                    </Header>
                    <Statistic.Value
                      className="bold force-30px"
                      style={{ textAlign: 'left' }}
                    >
                      {entityMessagesMetrics.sentMessages || 0}
                    </Statistic.Value>
                  </Statistic>
                  <Statistic>
                    <Header
                      as="h5"
                      style={{ textAlign: 'left', fontSize: '13px' }}
                    >
                      <Header.Content>
                        {__('Calls').toUpperCase()}
                        <Popup
                          trigger={
                            <Icon
                              style={{ opacity: '.6', marginLeft: '5px' }}
                              name="info circle"
                              color="grey"
                            />
                          }
                          content={
                            <span>
                              {isSchool
                                ? __(
                                  'Messages received by the channel from students and parents',
                                )
                                : __(
                                  'Messages from clients received by the channel',
                                )}
                            </span>
                          }
                          hideOnScroll
                          basic
                        />
                      </Header.Content>
                    </Header>
                    <Statistic.Value
                      className="bold force-30px"
                      style={{ textAlign: 'left' }}
                    >
                      {entityMessagesMetrics.receivedMessages || 0}
                    </Statistic.Value>
                  </Statistic>
                  <Statistic>
                    <Header
                      as="h5"
                      style={{ textAlign: 'left', fontSize: '13px' }}
                    >
                      <Header.Content>
                        {__('Comments').toUpperCase()}
                        <Popup
                          trigger={
                            <Icon
                              style={{ opacity: '.6', marginLeft: '5px' }}
                              name="info circle"
                              color="grey"
                            />
                          }
                          content={
                            <span>
                              {__('All the replies within channel messages')}
                            </span>
                          }
                          hideOnScroll
                          basic
                        />
                      </Header.Content>
                    </Header>
                    <Statistic.Value
                      className="bold force-30px"
                      style={{ textAlign: 'left' }}
                    >
                      {entityMessagesMetrics.sentReplies || 0}
                    </Statistic.Value>
                  </Statistic>
                </Statistic.Group>,
              ]}
            />,
          ]
        )}
      </div>
    );
  }
}
