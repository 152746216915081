import * as pictureUploadDataSource from '../../../DataSource/PictureUploadDataSource';
import { uploadChannelPictureRepository } from '../UploadChannelPicture';

describe('given the uploadChannelPictureRepository function', () => {
  const variables = {
    entityId: 1
  };

  const mutation = `
    mutation createPicture {
      createPicture(input: { entityId: ${variables.entityId} }) {
        clientMutationId
        picture {
          uri
        }
      }
    }
  `;
  it('should call Upload with the correct params', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(pictureUploadDataSource, 'Upload').mockImplementation(() => mutationMock);

    await uploadChannelPictureRepository('file', variables);

    expect(pictureUploadDataSource.Upload).toHaveBeenCalledWith(mutation, 'file');
  });
});
