/* eslint-disable new-cap */
import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
* @param {number} entityId
* @param {number[]} removedIds
*/
export async function removeIdsFromChannelRepository(entityId, removedIds = []) {
  const errors = [];
  const data = [];

  const mutation = gql`
    mutation deleteUserEntity($deleteUserEntityInput: DeleteUserEntityInput!) {
      deleteUserEntity(input: $deleteUserEntityInput) {
        clientMutationId
        __typename
      }
    }
  `;

  removedIds.forEach(async userId => {
    const input = {
      deleteUserEntityInput: {
        entityId,
        userId
      }
    };

    const result = await Mutation(mutation, input);

    if (result?.error) {
      errors.push(result?.error);
    }

    if (result?.data) {
      data.push(result?.data);
    }
  });

  return { data, errors };
}
