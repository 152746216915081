/**
 * @param {{
 *    unleashStatus: {
 *      communication_status_feedback: boolean
 *    }
 * }} organization
 */

export function hasCommunicationStatusFeedback(organization) {
  return organization?.unleashStatus?.communication_status_feedback;
}
