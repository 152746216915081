/* eslint-disable sonarjs/no-duplicate-string */
import * as ApolloDataSource from '../../../../../Data/DataSource/ApolloDataSource';
import * as CreateOrUpdateChannelAutomaticMessagesUseCase from '../../../../../Domain/UseCases/AutomaticMessage/createOrUpdateChannelAutomaticMessagesUseCase';
import * as uploadChannelPictureUseCase from '../../../../../Domain/UseCases/Channel/UploadChannelPicture';

import { createChannelUseCaseMock } from '../../../../../Domain/UseCases/Channel/__mocks__/channelUseCase.mock';
import { ViewModel as useViewModel } from '../ViewModel';

describe('<ViewModel />', () => {
  it('should be able to create channel entity', async () => {
    jest
      .spyOn(ApolloDataSource, 'Mutation')
      .mockImplementation(async () => createChannelUseCaseMock);

    const { createChannel } = useViewModel();
    const variables = {
      organizationId: '1780',
      fullname: 'Canal de Teste',
      eid: '1123',
      type: 'STAFF',
      description: 'Descrição do Canal',
      invisible: false,
      seeAll: false,
      ntfStartTime: '07:30:00',
      ntfEndTime: '08:00:00',
      addresses: [
        {
          address: '5541988538810',
          type: 'PHONE',
          tags: [],
          invite: true,
        },
        {
          address: 'matheus.kindrazki@arcotech.io',
          type: 'EMAIL',
          tags: [],
          invite: true,
        },
      ],
      groups: [
        {
          id: 29852,
          name: '3º Ano A',
          type: null,
        },
        {
          id: 29853,
          name: '6º Ano A',
          type: null,
        },
        {
          id: 29851,
          name: '5º Ano A',
          type: null,
        },
      ],
      defaultFeatures: ['MESSAGE'],
      updateIfExists: false,
      scope: [],
    };
    const response = await createChannel(variables);
    expect(response.data).toStrictEqual({
      node: {
        description: null,
        disabled: false,
        eid: null,
        fullname: 'Secretaria',
        id: 398,
        ntfEndTime: null,
        ntfStartTime: null,
        picture: null,
        policy: null,
        scope: null,
        seeAll: true,
        type: 'STAFF',
      },
    });
    expect(response.errors).toStrictEqual(undefined);
  });

  it('should be able to test hasMessageApprove function', () => {
    const { hasMessageApprove } = useViewModel();
    const variables = {
      unleashStatus: {
        approve_message: true,
      },
      plan: 'PREMIUM',
    };
    const response = hasMessageApprove(variables);
    expect(response).toStrictEqual(true);
  });

  it('should be able to test convertAddressesToInput function', () => {
    const { convertAddressesToInput } = useViewModel();
    const variables = {
      selectedUsers: [
        {
          address: '5519900000002',
          tags: [],
          type: 'PHONE',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection',
            },
            __typename: 'User',
          },
          original: true,
        },
        {
          address: 'i+admin@classapp.com.br',
          tags: [],
          type: 'EMAIL',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection',
            },
            __typename: 'User',
          },
          original: true,
        },
      ],
      originalUsers: [
        {
          address: '5519900000002',
          tags: [],
          type: 'PHONE',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection',
            },
            __typename: 'User',
          },
          original: true,
        },
        {
          address: 'i+admin@classapp.com.br',
          tags: [],
          type: 'EMAIL',
          user: {
            id: 2,
            fullname: 'Mateus Andrade',
            phone: '5519900000002',
            email: 'i+admin@classapp.com.br',
            confirmPhone: true,
            confirmEmail: true,
            tags: {
              nodes: [],
              __typename: 'UserTagsConnection',
            },
            __typename: 'User',
          },
          original: true,
        },
      ],
      originalAddresses: [
        {
          address: '5519900000002',
          type: 'PHONE',
          userId: 2,
          __typename: 'Address',
        },
        {
          address: 'i+admin@classapp.com.br',
          type: 'EMAIL',
          userId: 2,
          __typename: 'Address',
        },
      ],
      entity_id: null,
    };
    const response = convertAddressesToInput(variables);
    expect(response).toStrictEqual({
      addresses: [
        {
          address: 'i+admin@classapp.com.br',
          invite: false,
          tags: [],
          type: 'EMAIL',
        },
        {
          address: '5519900000002',
          invite: false,
          tags: [],
          type: 'PHONE',
        },
      ],
      removedIds: [],
    });
  });

  it('should be able to test convertGroupsToInput function', () => {
    const { convertGroupsToInput } = useViewModel();
    const variables = {
      selectedGroups: [
        {
          id: 1,
          name: 'Group A',
        },
        {
          id: 2,
          name: 'Group B',
        },
        {
          id: 3,
          name: 'Group C',
        },
      ],
    };
    const response = convertGroupsToInput(variables.selectedGroups);
    expect(response).toStrictEqual([
      { id: 1, name: 'Group A', type: null },
      { id: 2, name: 'Group B', type: null },
      { id: 3, name: 'Group C', type: null },
    ]);
  });

  it('should be able to render upload picture function ', () => {
    jest
      .spyOn(uploadChannelPictureUseCase, 'uploadChannelPictureUseCase')
      .mockReturnValue(
        Promise.resolve({
          createPicture: {
            clientMutationId: '2',
          },
        }),
      );
    const { uploadChannelPicture } = useViewModel();

    uploadChannelPicture();

    expect(uploadChannelPicture).toBeTruthy();
  });

  describe('automaticMessages flow', () => {
    beforeEach(() => {
      jest.clearAllMocks();
      jest
        .spyOn(
          CreateOrUpdateChannelAutomaticMessagesUseCase,
          'createOrUpdateChannelAutomaticMessagesUseCase',
        )
        .mockReturnValue({
          data: {
            createOrUpdateAutomaticMessage: {
              success: true,
            },
          },
        });
    });

    const variables = {
      automaticMessages: {
        OUT_WORKING_HOURS: {
          enabled: true,
          message: 'Mensagem de teste',
        },
      },
      entity: {
        id: 1,
        automaticMessages: {
          nodes: [
            {
              type: 'OUT_WORKING_HOURS',
              enabled: true,
              message: 'Mensagem de teste 2',
            },
          ],
        },
      },
    };

    it('should be able to save the automatic messages if the entity does not have automatic messages', async () => {
      const { createOrUpdateAutomaticMessages } = useViewModel();

      await createOrUpdateAutomaticMessages({
        automaticMessages: variables.automaticMessages,
        entity: { id: 1 },
      });

      expect(
        CreateOrUpdateChannelAutomaticMessagesUseCase.createOrUpdateChannelAutomaticMessagesUseCase,
      ).toHaveBeenCalled();
    });

    it('should be able to execute createOrUpdateAutomaticMessages function', async () => {
      const { createOrUpdateAutomaticMessages } = useViewModel();

      await createOrUpdateAutomaticMessages(variables);
      expect(
        CreateOrUpdateChannelAutomaticMessagesUseCase.createOrUpdateChannelAutomaticMessagesUseCase,
      ).toHaveBeenCalled();
    });

    it('should NOT be able to execute the createOrUpdateAutomaticMessages use case if messages are the same', async () => {
      variables.automaticMessages.OUT_WORKING_HOURS.message =
        'Mensagem de teste 2';
      const { createOrUpdateAutomaticMessages } = useViewModel();

      await createOrUpdateAutomaticMessages(variables);

      expect(
        CreateOrUpdateChannelAutomaticMessagesUseCase.createOrUpdateChannelAutomaticMessagesUseCase,
      ).not.toHaveBeenCalled();
    });

    it('should not be able to execute the createOrUpdateAutomaticMessages use case if there is no entity', async () => {
      variables.entity = undefined;
      const { createOrUpdateAutomaticMessages } = useViewModel();

      await createOrUpdateAutomaticMessages(variables);

      expect(
        CreateOrUpdateChannelAutomaticMessagesUseCase.createOrUpdateChannelAutomaticMessagesUseCase,
      ).not.toHaveBeenCalled();
    });

    it('should not be able to execute the createOrUpdateAutomaticMessages use case if there is no automaticMessages', async () => {
      variables.automaticMessages = undefined;
      const { createOrUpdateAutomaticMessages } = useViewModel();

      await createOrUpdateAutomaticMessages(variables);

      expect(
        CreateOrUpdateChannelAutomaticMessagesUseCase.createOrUpdateChannelAutomaticMessagesUseCase,
      ).not.toHaveBeenCalled();
    });
  });
});
