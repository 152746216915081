import { act, renderHook } from '@testing-library/react-hooks/dom';
import { useRedirect } from '../useRedirect';

describe('given useRedirect hook', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      router: {
        push: jest.fn(),
      },
      store: {
        currentOrganization: {
          network: null,
          id: 123,
        },
        currentEntity: {
          id: '456',
        },
      },
    };
  });

  it('should redirect when organization store is not provided', () => {
    props.store.currentOrganization = null;
    const { result } = renderHook(() => useRedirect());

    act(() => {
      result.current.navigateWhenIsNotNetwork({ ...props });
    });

    expect(props.router.push).toBeCalled();
  });

  it('should be able to redirect when organization has not network unleash', () => {
    props.currentOrganization = {
      network: {
        id: '123',
      },
      id: 1,
      unleashStatus: {
        network_communication: false,
      },
    };
    const { result } = renderHook(() => useRedirect());

    act(() => {
      result.current.navigateWhenIsNotNetwork({ ...props });
    });

    expect(props.router.push).toBeCalledWith('/entities/456/messages');
  });

  it('should be able to redirect when organization is not a network', () => {
    props.currentOrganization = {
      network: null,
      id: 123,
      unleashStatus: {
        network_communication: true,
      },
    };
    const { result } = renderHook(() => useRedirect());

    act(() => {
      result.current.navigateWhenIsNotNetwork({ ...props });
    });

    expect(props.router.push).toBeCalledWith('/entities/456/messages');
  });

  it('should not redirect when is a network and has nettwork unleash', () => {
    props.store = {
      currentOrganization: {
        network: {
          id: '123',
        },
        id: 123,
        unleashStatus: {
          network_communication: true,
        },
      },
    };

    const { result } = renderHook(() => useRedirect());

    act(() => {
      result.current.navigateWhenIsNotNetwork({ ...props });
    });

    expect(props.router.push).not.toBeCalled();
  });
});
