import gql from 'graphql-tag';
import { getEntityDirectMessageReadStatusRepository } from '../GetEntityDirectMessageReadStatus';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => jest.fn().mockReturnValue('query'));

describe('given the "getEntityDirectMessageReadStatusRepository" function', () => {
  beforeEach(() => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: {
        node: {
          hasUnreadDirectMessages: true,
        }
      },
    }));
  });

  const query = [`
    query getEntityDirectMessageReadStatus($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          id: dbId
          hasUnreadDirectMessages
        }
      }
    }
  `];

  const variables = {
    entityId: 10,
  };

  it('should call the "Query" function with the correct params', async () => {
    await getEntityDirectMessageReadStatusRepository(variables);

    expect(DataSource.Query).toHaveBeenCalledWith('query', variables);
  });

  it('should return Query response', async () => {
    const response = await getEntityDirectMessageReadStatusRepository(variables);

    expect(response).toEqual({ data: { node: { hasUnreadDirectMessages: true } } });
  });

  it('should call the "gql" function with the correct mutation', async () => {
    await getEntityDirectMessageReadStatusRepository(variables);

    expect(gql).toHaveBeenCalledWith(query);
  });
});
