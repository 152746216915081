import { createChannelUseCase } from '../../../../Domain/UseCases/Channel/CreateChannel';
import { uploadChannelPictureUseCase } from '../../../../Domain/UseCases/Channel/UploadChannelPicture';
import { hasMessageApproveUseCase } from '../../../../Domain/UseCases/Channel/HasMessageApprove';
import { convertAddressesToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertAddressesToInput';
import { convertGroupsToInputUseCase } from '../../../../Domain/UseCases/Channel/ConvertGroupsToInput';
import { convertImageToBlobUseCase } from '../../../../Domain/UseCases/Channel/ConvertImageToBlob';
import { automaticMessagesAreEqual } from '../../../../helpers/automaticMessagesAreEqual';
import { createOrUpdateChannelAutomaticMessagesUseCase } from '../../../../Domain/UseCases/AutomaticMessage/createOrUpdateChannelAutomaticMessagesUseCase';

export function ViewModel() {
  /**
   * @param {import("../../../Data/Repository/Channel/UpdateChannel").TVariables} variables
   */
  async function createChannel(variables) {
    return await createChannelUseCase({ ...variables });
  }

  /**
   * @param {import("../../../Data/Repository/Channel/UploadChannelPicture").TVariables} variables
   */
  async function uploadChannelPicture(file, variables) {
    return await uploadChannelPictureUseCase(file, { ...variables });
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/HasMessageApprove").TVariables} variables
   */
  function hasMessageApprove(variables) {
    return hasMessageApproveUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
   */
  function convertAddressesToInput(variables) {
    return convertAddressesToInputUseCase(variables);
  }

  /**
   * @param {import("../../../Domain/UseCases/Channel/IsDisabled").TVariables} variables
   */
  function convertGroupsToInput(variables) {
    return convertGroupsToInputUseCase(variables);
  }

  /**
   * @param {string} iconName
   */
  async function convertImageToBlob(iconName) {
    return await convertImageToBlobUseCase(iconName);
  }

  /**
   * @param {Object} params
   * @param {Object.<string, { enabled: boolean, message: string }>} params.automaticMessages
   * @param {any} [params.entity]
   */
  async function createOrUpdateAutomaticMessages({
    automaticMessages,
    entity,
  }) {
    if (!entity || !automaticMessages) return;

    let entityAutomaticMessages = {};

    if (entity?.automaticMessages) {
      for (const message of entity.automaticMessages.nodes) {
        entityAutomaticMessages[message.type] = {
          enabled: message.enabled,
          message: message.message,
        };
      }
    }

    const isAutomaticMessagesTheSame = automaticMessagesAreEqual(
      entityAutomaticMessages,
      automaticMessages,
    );

    if (!isAutomaticMessagesTheSame) {
      await createOrUpdateChannelAutomaticMessagesUseCase({
        automaticMessages,
        entityId: entity.id,
      });
    }
  }

  return {
    createChannel,
    hasMessageApprove,
    convertAddressesToInput,
    convertGroupsToInput,
    uploadChannelPicture,
    convertImageToBlob,
    createOrUpdateAutomaticMessages,
  };
}
