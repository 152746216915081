import React from 'react';
import { fireEvent, render } from '../../../../../../newRender';
import { EntityNotificationsViewController } from '../ViewController';
import { otherNotificationsMock } from '../components/OtherNotifications/__mocks__/other-notifications.mock';
import { directNotificationsMock } from '../components/DirectNotifications/__mocks__/direct-notifications.mock';
import * as ViewModel from '../ViewModel';

jest.mock('react-router', () => ({
  Link: 'a',
}));

describe('given the ViewController component', () => {
  const mockedProps = {
    notifications: otherNotificationsMock,
    directNotifications: directNotificationsMock,
    renderRow: jest.fn(),
    pagination: {
      graphql: false,
      hasNextPage: false,
      loading: false,
    },
    directNotificationsPagination: {
      graphql: false,
      hasNextPage: false,
      loading: false,
    },
    onLoadMore: jest.fn(),
    onClose: jest.fn(),
  };

  beforeEach(() => {
    jest.spyOn(ViewModel, 'ViewModel').mockReturnValue({
      hasUnleashConversationsFeature: true,
      userHasChannels: true,
      shouldSeeConversations: true,
      registerNotificationsPubSub: jest.fn(),
    });
  });

  it('should be able to render the component', () => {
    const { getByText } = render(
      <EntityNotificationsViewController {...mockedProps} />,
    );

    expect(getByText('Conversations')).toBeTruthy();
  });

  it('should be able to open the others tab', async () => {
    const { getByText, findByTestId } = render(
      <EntityNotificationsViewController {...mockedProps} />,
    );

    fireEvent.click(getByText('Others'));
    const list = await findByTestId('list');

    expect(list).toBeTruthy();
  });

  it('should be able to see the empty state if tab is empty', async () => {
    const { getByText } = render(
      <EntityNotificationsViewController {...mockedProps} notifications={[]} />,
    );

    fireEvent.click(getByText('Others'));

    expect(getByText('No notifications yet')).toBeVisible();
  });

  it('should not be able to see the tabs if user has no channels', () => {
    jest.spyOn(ViewModel, 'ViewModel').mockReturnValue({
      hasUnleashConversationsFeature: true,
      userHasChannels: false,
      shouldSeeConversations: false,
      registerNotificationsPubSub: jest.fn(),
    });

    const { queryByText } = render(
      <EntityNotificationsViewController {...mockedProps} />,
    );

    expect(queryByText('Conversations')).toBeNull();
  });

  it('should be able to press the notification', () => {
    const { getByText } = render(
      <EntityNotificationsViewController {...mockedProps} />,
    );

    fireEvent.click(getByText('Conversations'));
    fireEvent.click(getByText(directNotificationsMock[0].fromEntity.fullname));

    expect(mockedProps.onClose).toHaveBeenCalled();
  });
});
