import React, { useState } from 'react';
// import { Button } from '@classapp-tech/edna';

import BetaModal from '../../../components/ui/Modal';
import { __ } from '../../../i18n';
import Alert from '../../../components/Alert';
import Input from '../../../components/ui/Input';
import { validateEmail } from '../../../utils';
import { CancelButton } from './ExportModal.styles';
import Button from '../../../components/ui/Button';

/**
 * @param {{
 *  onConfirm: (email: string) => void,
 *  onClose?: () => void,
 *  defaultEmail?: string
 * }} props
 */
export default function ExportModal({
  onConfirm, onClose, defaultEmail = ''
}) {
  const [email, setEmail] = useState(defaultEmail);
  const [error, setError] = useState();

  const onClick = () => {
    if (validateEmail(email)) {
      onConfirm(email);
      return;
    }
    setError(__('Required field'));
  };

  const onCancel = () => {
    setEmail('');
    setError(null);
    if (onClose) onClose();
  };

  return (
    <BetaModal
      id="ExportModal"
      onClose={onClose}
      size="tiny"
      header={__('Export statement')}
      className="beta-modal"
      spaceFooter
      content={(
        <div>
          <Alert
            text={__('To export the report, enter your email address. This may take a few minutes.')}
            type="info"
          />
          <Input
            label="Email"
            placeholder="email@email.com"
            icon={{ before: { name: 'at' } }}
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            error={error}
            style={{ marginTop: '1rem' }}
            role="textbox"
            aria-label="export-modal-email-input"
          />
        </div>
      )}
      actions={[
        <CancelButton onClick={onCancel}>{__('Cancel')}</CancelButton>,
        // The Button component from edna is breaking unit tests, once fixed we can use it again
        // <Button
        //   onClick={onClick}
        //   variation="primary"
        // >
        //   <span>{__('Confirm')}</span>
        // </Button>
        <Button
          text={__('Confirm')}
          onClick={onClick}
        />
      ]}
    />
  );
}
